import { call, put, select, takeEvery } from "redux-saga/effects";

import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { statementsActions } from "../actions/statements";
import * as statementsApi from "../api/statements";
import {
    StatementsActionConstants,
    FeatureStatementsState,
    StatementsInitializerType,
    T_VpStatementV2,
} from "../types/statements";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* watchFetchStatements() {
    yield takeEvery(StatementsActionConstants.FETCH_STATEMENTS_TRIGGER, handleFetchStatements);
}

export function* handleFetchStatements() {
    try {
        const { token, mockApiCalls, gwUrl, accountId } = (yield select(
            (state: FeatureStatementsState) => state.statements.config
        )) as StatementsInitializerType;

        if (!token) {
            logger.log("got no token, back to front page");
            return (window.location.href = "/");
        }

        logger.log("got token, fetch statements");

        const statements: Array<T_VpStatementV2> = yield call(statementsApi.fetchStatements, {
            token,
            accountId,
            mockApiCalls,
            gwUrl,
        });

        logger.log("got statements", statements);

        yield put(statementsActions.fetchStatementsSuccess(statements));
    } catch (e) {
        logger.log("fetch statements trigger failed", e);
    }
}
