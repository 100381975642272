import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { T_LoginSessionReducerState } from "../types";
import { LoginSessionAction, loginSessionActions } from "../actions";

const initialState: T_LoginSessionReducerState = {
    token: "",
    authenticated: false,
    role: "customer",
    ssn: "",
    name: "",
    config: {
        cid: "",
        authUrl: "",
        lang: "",
        verifyUrl: "",
        expiredUrl: "",
        errorUrl: "",
    },
};

export const loginSessionReducer = createReducer<T_LoginSessionReducerState, LoginSessionAction>(
    initialState
)
    .handleAction(loginSessionActions.loginSessionTokenSuccess, (state, action) => {
        return produce(state, (draftState) => {
            draftState.token = action.payload.token;
            draftState.authenticated = action.payload.authenticated;
            draftState.role = action.payload.role;
            draftState.ssn = action.payload.ssn;
            draftState.name = action.payload.name;
        });
    })
    .handleAction(loginSessionActions.loginSessionInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config = action.payload;
        });
    })
    .handleAction(loginSessionActions.loginSessionEnd, (state, action) => {
        return produce(state, (draftState) => {
            draftState.token = "";
            draftState.authenticated = false;
            draftState.role = "customer";
            draftState.ssn = "";
        });
    });
