import * as VP from "@opr-finance/api-definitions";
import { DefaultInitializerType } from "@opr-finance/utils";

export enum E_SmeTransactionsActionConstants {
    FETCH_TRANSACTIONS_INITIALIZER = "TRANSACTIONS/INITIALIZER",
    FETCH_TRANSACTIONS_TRIGGER = "TRANSACTIONS/FETCH_TRANSACTIONS_TRIGGER",
    FETCH_TRANSACTIONS_SUCCESS = "TRANSACTIONS/FETCH_TRANSACTIONS_SUCCESS",
    FETCH_TRANSACTIONS_ERROR = "TRANSACTIONS/FETCH_TRANSACTIONS_ERROR",
}

export enum E_SmeTransactionsItemConf {
    DEFAULT_FORMAT_DATE = "dd'.'MM'.'yyyy",
    FINLAND = "dd'.'MM'.'yyyy",
    NETHERLANDS = "dd'.'MM'.'yyyy",
    SWEDEN = "yyyy'-'MM'-'dd",
}

export enum E_SmeTransactionStatuses {
    VIRTUAL_PAYMENT_TRANSACTION = "VirtualPaymentTransaction",
    MANUAL_PLACING_PAYMENT_TRANSACTION = "ManualPlacingPaymentTransaction",
    LOAN_ADJUSTMENT_INTEREST_TRANSACTION = "LoanAdjustmentInterestTransaction",
    LOAN_TRANCHE_DISBURSEMENT_TRANSACTION = "LoanTrancheDisbursementTransaction",
    OVER_PAYMENT_AFTER_LAST_STATEMENT_TRANSACTION = "OverPaymentAfterLastStatementTransaction",
    PAYBACK_TO_CUSTOMER_FROM_ACCOUNT_TRANSACTION = "PaybackToCustomerFromAccountTransaction",
    PAYMENT_TRANSACTION = "PaymentTransaction",
    STATEMENT_AMORTIZATION_TRANSACTION = "StatementAmortizationTransaction",
    STATEMENT_FEE_TRANSACTION = "StatementFeeTransaction",
    STATEMENT_INTEREST_TRANSACTION = "StatementInterestTransaction",
    STATEMENT_LATE_PAYMENT_INTEREST_TRANSACTION = "StatementLatePaymentInterestTransaction",
    STATEMENT_REMINDER_FEE_TRANSACTION = "StatementReminderFeeTransaction",
    STATEMENT_ROUNDING_TRANSACTION = "StatementRoundingTransaction",
    STATEMENT_STARTUP_FEE_TRANSACTION = "StatementStartupFeeTransaction",
    STATEMENT_TRANCHE_STARTUP_FEE_TRANSACTION = "StatementTrancheStartupFeeTransaction",
    STATEMENT_DIRECT_DEBIT_FEE_TRANSACTION = "StatementDirectDebitFeeTransaction",
    AGGREGATED_INTEREST_TRANSACTION = "summarizedInterest",
}

export type T_SmeMessageData = {
    contentfulKey: string;
    messageId: string;
};

export type T_SmeGeneratedMessages = {
    [key: string]: {
        id: string;
    };
};

export interface I_SmeFormattedTransactions {
    formattedDate?: string;
    amount?: number;
    formattedTitle?: string;
    transactionType?: VP.components["schemas"]["TransactionType"] | string;
    formattedAmount?: string;
    transactionDate?: string;
}

export type T_YearlyOverview = {
    paidAmortisation: number;
    paidInterest: number;
    paidLatePaymentInterest: number;
    paidStartupFee: number;
    paidLatePaymentFee?: number;
};

export type T_SmeTransactionsInitializerType = DefaultInitializerType & {
    size?: number;
    shownInStatement?: boolean;
    excludeTransactionTypes?: string;
    accountId: string | undefined;
    country: string;
    endDate?: string;
    startDate?: string;
};

export type T_SmeTransactionTriggerPayload = {
    startDate?: string;
    endDate?: string | Date;
};

export type T_SmeTransactionsSuccessAction = {
    statementTransactions: Array<I_SmeFormattedTransactions> | [];
};

export type T_SmeTransactionReducerState = T_SmeTransactionsSuccessAction & {
    config: T_SmeTransactionsInitializerType;
};

/** REFACTOR: This fixes type issues in Flex Online AppState as it needs to use feature-luvittaja-login,
 *  type T is defined as the LoginState being used, by default it is FeatureLoginState from feature-lfp-login
 *  todo: check if login state can be completely removed from this type
 */
export type T_SmeFeatureTransactionsState = {
    transactions: T_SmeTransactionReducerState;
};

export type T_SmeTransactionsResponse = {
    statementTransactions: Array<VP.components["schemas"]["Transaction"]>;
};
