import { contentfulClient } from "./contentfulClient";
import { ContentfulParams, TranslationStaticContentConfig } from "../types/contentful";
import { IStaticContent } from "../types/generated";

export const getTranslationStaticContent = async (
    data: ContentfulParams<TranslationStaticContentConfig>
): Promise<IStaticContent[]> =>
    (await contentfulClient(data.clientParams)
        .getEntries<IStaticContent>(data.contentParams)
        .then((response) => response.items)) as IStaticContent[];
