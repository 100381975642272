import { httpFaker, WithAuthToken, WithMockApiCalls, WithGwUrl } from "@opr-finance/utils";
import { T_VpRevolvingAccount, T_VpUserInformation } from "../types/general";

export type FetchCustomerRequest = {} & WithAuthToken & WithMockApiCalls & WithGwUrl;

export async function fetchCustomer(data: FetchCustomerRequest): Promise<T_VpUserInformation> {
    const url = `${data.gwUrl}/api/customer/v4/privateCustomers/me`;
    const method = "GET";

    if (data.mockApiCalls) {
        if (true) {
            return httpFaker<null, T_VpUserInformation>(url, method, null, {
                customerBasicInfo: {
                    customerId: "50213",
                    century: "19",
                    ssn: "9012199353",
                    givenName: "Test",
                    surName: "Test",
                    email: undefined,
                    phoneCode: "+46",
                    phoneNumber: "702877777",
                    mobilePhoneCode: "+46",
                    mobilePhoneNumber: "702877777",
                    preferredContactChannel: undefined,
                    selfAssessedPep: "NULL",
                    selfAssessedPepDescription: undefined,
                    nationality: "SE",
                    hasGuardian: false,
                },
                address: {
                    streetAddress: "Testsongatan",
                    zipCode: "17582",
                    city: "Test",
                    country: "SE",
                    coAddress: "",
                    manuallyMaintained: false,
                    region: "",
                },
                accountList: [
                    {
                        id: "9800",
                        accountNumber: "7101975",
                        nickName: undefined,
                        type: "REGULAR_LOAN",
                        extraInfos: { remainingPrincipal: "1000.00" },
                    },
                    {
                        id: "24200",
                        accountNumber: "7104870",
                        nickName: undefined,
                        type: "REGULAR_LOAN",
                        extraInfos: {
                            remainingPrincipal: "0.00",
                        },
                    },
                    {
                        id: "69200",
                        accountNumber: "7113988",
                        nickName: undefined,
                        type: "REGULAR_LOAN",
                        extraInfos: {
                            remainingPrincipal: "0.00",
                        },
                    },
                    {
                        id: "6113297",
                        accountNumber: "9999999",
                        nickName: undefined,
                        type: "CREDIT_LOAN",
                        extraInfos: {
                            remainingPrincipal: "0.00",
                            creditLimit: "10000.00",
                        },
                    },
                    {
                        id: "6100630",
                        accountNumber: "8100075",
                        nickName: undefined,
                        type: "CREDIT_LOAN",
                        extraInfos: { remainingPrincipal: "7680.00", creditLimit: "8000.00" },
                    },
                    {
                        id: "6122165",
                        accountNumber: "8131765",
                        nickName: undefined,
                        type: "CREDIT_LOAN",
                        extraInfos: {
                            remainingPrincipal: "0.00",
                            creditLimit: "10000.00",
                        },
                    },
                ],
            });
        }
        return httpFaker<null, T_VpUserInformation>(url, method, null, {
            customerBasicInfo: {
                customerId: "50001",
                century: "19",
                ssn: "9012199353",
                givenName: "Johansen",
                surName: "Testperson",
                email: "test.customer@opr-finance.fi",
                phoneCode: undefined,
                phoneNumber: undefined,
                mobilePhoneCode: "+46",
                mobilePhoneNumber: "555555555",
                preferredContactChannel: undefined,
                selfAssessedPep: "NOT_PEP",
                selfAssessedPepDescription: undefined,
                nationality: undefined,
                hasGuardian: false,
            },
            address: {
                streetAddress: "Danderydsgatan 2",
                zipCode: "18260",
                city: "Djursholm",
                country: "SE",
                coAddress: "Uthyrarea, B",
                manuallyMaintained: false,
                region: undefined,
            },
            accountList: [
                {
                    accountNumber: "7100563",
                    extraInfos: {
                        remainingPrincipal: "2000.00",
                    },
                    id: "2750",
                    nickName: undefined,
                    type: "REGULAR_LOAN", // e.g. Fixza
                },
                {
                    accountNumber: "8142440",
                    extraInfos: {
                        creditLimit: "10000.00",
                        remainingPrincipal: "9597.00",
                    },
                    id: "6113668",
                    nickName: undefined,
                    type: "CREDIT_LOAN", // e.g. ED+
                },
            ],
        });
    }

    const result: Response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": data.token as string,
        },
    });

    return result.json();
}

export type FetchRevolvingAccountRequest = {} & WithAuthToken & WithMockApiCalls & WithGwUrl;

export async function fetchRevolvingAccounts(
    data: FetchRevolvingAccountRequest
): Promise<Array<T_VpRevolvingAccount>> {
    const url = `${data.gwUrl}/api/revolving/v2/accounts`;
    const method = "GET";

    if (data.mockApiCalls) {
        return httpFaker<null, Array<T_VpRevolvingAccount>>(url, method, null, [
            {
                id: "1",
                promissoryNoteDocumentId: "1",
                accountNumber: "1",
                createDate: "2020-04-01",
                nextDueDate: "2020-05-01",
                creditLimit: 4000,
                availableCreditLimit: 4000,
            },
        ]);
    }

    const result: Response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": data.token as string,
        },
    });

    return result.json();
}
