import { StatementsMessageData, StatementsGeneratedMessages } from "../types/statements";

function msg(id) {
    return { id };
}

export function generateStatementsMessageObject(
    data: StatementsMessageData[]
): StatementsGeneratedMessages {
    let generatedMessages = {};

    data.forEach((message: StatementsMessageData) => {
        generatedMessages[message.messageId] = msg(message.contentfulKey);
    });
    return generatedMessages;
}
