import { createReducer } from "typesafe-actions";
import { produce } from "immer";

import { SmeLoanAccountAction, smeLoanAccountActions } from "../actions";
import { T_SmeLoan_AccountReducerState } from "../types";

export const initialState: T_SmeLoan_AccountReducerState = {
    account: null,
    activeAccountId: null,
    accountState: undefined,
    config: {
        mockApiCalls: false,
        gwUrl: "",
        token: "",
        errorUrl: "",
        noAuth: "",
        noLoanUrl: "",
    },
};

export const smeLoanAccountReducer = createReducer<
    T_SmeLoan_AccountReducerState,
    SmeLoanAccountAction
>(initialState)
    .handleAction(smeLoanAccountActions.accountInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config = action.payload;
        });
    })
    .handleAction(smeLoanAccountActions.accountSetAccountId, (state, action) => {
        return produce(state, (draftState) => {
            draftState.activeAccountId = action.payload.activeAccountId;
        });
    })
    .handleAction(smeLoanAccountActions.fetchAccountSuccess, (state, action) => {
        return produce(state, (draftState) => {
            draftState.account = action.payload;
            draftState.accountState = action.payload.state;
        });
    })
    .handleAction(smeLoanAccountActions.updateAccountSuccess, (state, action) => {
        return produce(state, (draftState: any) => {
            let updatedAccount = { ...draftState.account };
            updatedAccount.disbursementAccount.externalAccountNumber = action.payload.bankAccount;
            draftState.account = updatedAccount;
        });
    });
