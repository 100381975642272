import React from "react";
export { TextField } from "../TextInput/TextInput.styled";
import { Font } from "@opr-finance/component-fonts";
import { add, remove, generateKey, pickOff } from "@opr-finance/utils";
import { SystemStyleObject } from "@styled-system/css";
import { isTouched, getFieldErrors, FormError } from "../index";

export type GenericErrorProps<T> = {
    errors: FormError[];
    touched: string[];
    field: keyof T;
    styleConfig?: {
        root?: SystemStyleObject;
    };
};

export function GenericError<T>(props: GenericErrorProps<T>) {
    const errors = getFieldErrors(props.errors, props.field as string);
    const handled: string[] = [];
    return (
        <>
            {errors.length > 0 && isTouched(props.touched, props.field as string)
                ? errors
                      .filter((error) => {
                          // no duplicate errors with same message
                          if (handled.includes(error)) return false;
                          handled.push(error);
                          return true;
                      })
                      .map((error, index) => {
                          return (
                              <Font
                                  key={`error-${String(props.field)}-${index}`}
                                  styleConfig={{ root: props.styleConfig?.root }}
                              >
                                  {error}
                              </Font>
                          );
                      })
                : ""}
        </>
    );
}
