import { I_SmeFormattedTransactions } from "@opr-finance/feature-transactions";

const filterByTransactionType = (array: I_SmeFormattedTransactions[], filter: string[]) => {
    const filteredArray = array.filter((item) => {
        if (typeof item.transactionType !== "undefined")
            return filter.includes(item.transactionType);
    });
    return filteredArray;
};

const sumByAmount = (array: I_SmeFormattedTransactions[]) => {
    return array.reduce((acc, curr) => {
        return acc + (curr.amount || 0);
    }, 0);
};

// sum transactions by their type for yearly overview data
export const sumByPropertyAndType = (
    data: I_SmeFormattedTransactions[],
    filter: string[]
): number => {
    const transactions = [...data];
    const filteredArray = filterByTransactionType(transactions, filter);
    return sumByAmount(filteredArray);
};
