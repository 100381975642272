import { subYears, addDays, lastDayOfYear } from "date-fns";

export function extractYear(date: string): string {
    return new Date(date).getFullYear().toString();
}

export function substractYear(date: string): Date {
    const dateYearLater = subYears(new Date(date), 1);
    const newDate = addDays(dateYearLater, 1);
    return newDate;
}

export const lastDayOfGivenYear = (year: string): Date => lastDayOfYear(new Date(year));
