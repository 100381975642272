export enum UserLoginActionConstants {
    USER_LOGIN_INITIALIZE = "USER_LOGIN/USER_LOGIN_INITIALIZE",
    USER_LOGIN_CHECK_STATUS = "USER_LOGIN/CHECK_STATUS",
    USER_LOGIN_CHECK_STATUS_SUCCESS = "USER_LOGIN/CHECK_STATUS_SUCCESS",
    USER_LOGIN_START = "USER_LOGIN/START",
    USER_LOGIN_START_WITH_BANK = "USER_BANK_LOGIN/START",
    USER_LOGIN_START_SUCCESS = "USER_LOGIN/START_SUCCESS",
    USER_LOGIN_COMPLETE = "USER_LOGIN/COMPLETE",
    USER_LOGIN_COMPLETE_SUCCESS = "USER_LOGIN/COMPLETE_SUCCESS",
    USER_LOGOUT = "USER_LOGIN/LOGOUT",
    USER_VALIDATE_TOKEN = "USER_LOGIN/VALIDATE_TOKEN",
    USER_UPDATE_TOKEN = "USER_LOGIN/UPDATE_TOKEN",
    USER_UPDATE_IS_VALIDATING_TOKEN = "USER_LOGIN/UPDATE_IS_VALIDATING_TOKEN",
    USER_UPDATE_IS_VALID_TOKEN = "USER_LOGIN/UPDATE_IS_VALID_TOKEN",
    USER_UPDATE_IS_INITIALIZED = "USER_LOGIN/UPDATE_IS_INITIALIZED",
    USER_UPDATE_TOKEN_DATA = "USER_LOGIN/UPDATE_TOKEN_DATA",
}

export interface UserReducerState {
    token: string | null;
    tokenData: UserTokenData | null;
    isInitialized: boolean;
    isValidating: boolean;
    isValidToken: boolean;
    loginUrl: string;
    verifyUrl: string;
    verifyApiRequestMethod: "POST" | "GET";
    errorUrl: string;
    successUrl: string;
    logoutUrl: string;
    mockLogin: boolean;
    appBaseUrl: string;
}

export type FeatureUserLoginState = {
    user: UserReducerState;
};

export type UserTokenData = {
    exp: number;
    iss: string;
    jti: string;
    nbf: number;
    sub: string;
    aud: string | undefined;
    iat: number | undefined;
    sid: string | undefined;
    attrs: {
        firstname: string;
        lastname: string;
        fullname: string;
        birthdate: string;
        ssn: string;
    };
};
