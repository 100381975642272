import { T_InvoicesMessageData, T_InvoicesGeneratedMessages } from "../types/index.types";

function msg(id) {
    return { id };
}

export function generateInvoicesMessageObject(
    data: T_InvoicesMessageData[]
): T_InvoicesGeneratedMessages {
    let generatedMessages = {};

    data.forEach((message: T_InvoicesMessageData) => {
        generatedMessages[message.messageId] = msg(message.contentfulKey);
    });
    return generatedMessages;
}
