import React, { ReactNode } from "react";
import {
    BaseButton,
    BaseButtonProps,
    StyleBaseButton,
    StyleBaseButtonProps,
} from "./Button.styled";
import { InputStyleProps } from "@opr-finance/utils";

export type ButtonProps = {
    type?: string;
    children: ReactNode;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    variant?: "primary" | "secondary" | "large" | "logout" | "text" | string;
    disabled?: string | boolean;
} & BaseButtonProps;

export const Button = (props: ButtonProps) => {
    return (
        <BaseButton
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                if (props.onClick) {
                    props.onClick(event);
                }
            }}
            variant={props.variant}
            disabled={props.disabled}
            {...props}>
            {props.children}
        </BaseButton>
    );
};

export const StyledButton = (props: StyleBaseButtonProps) => {
    return (
        <StyleBaseButton {...props} styleConfig={props.styleConfig.root}>
            {props.children}
        </StyleBaseButton>
    );
};
