import React, { ReactNode, useState, useEffect } from "react";
import { Text } from "@opr-finance/component-fonts";
import {
    ButtonLeft,
    ButtonRight,
    Container,
    Current,
    Option,
    Options,
    PaginatorContainer,
    Select,
    Selected,
    SelectedIcon,
    SelectedLabel,
} from "./StyledPaginator.styled";
import { PaginationComponentProps } from "react-data-table-component";

export type PaginatorListOption = {
    value: string;
    label: string;
};

export type PaginatorProps = PaginationComponentProps & {
    labelPrefix?: string;
    paginationIconPrevious?: ReactNode;
    paginationIconNext?: ReactNode;
    pagesIcon?: ReactNode;
    divider?: number;
    pages?: number;
};

export const getNumberOfPages = (rowCount: number, rowsPerPage: number): number =>
    Math.ceil(rowCount / rowsPerPage);

export function StyledPaginator(props: PaginatorProps) {
    const {
        rowsPerPage,
        rowCount,
        onChangePage,
        onChangeRowsPerPage,
        currentPage,
        labelPrefix,
        paginationIconPrevious,
        paginationIconNext,
        pagesIcon,
        divider = 1,
        pages = 1,
    } = props;

    const numPages = getNumberOfPages(rowCount, rowsPerPage);
    const options: PaginatorListOption[] = [];

    for (let i = 0; i < pages; i++) {
        let value = (i + 1) * divider;
        options.push({
            value: value.toString(),
            label: `${labelPrefix} ${value}`,
        });
    }

    const [selected, setSelected] = useState(options[0]);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        onChangeRowsPerPage(parseInt(selected.value), currentPage);
    }, [selected]);

    return (
        <Container>
            <PaginatorContainer>
                <ButtonLeft
                    onClick={() => {
                        if (currentPage - 1 <= 0) return;
                        onChangePage(currentPage - 1, rowCount);
                    }}>
                    {paginationIconPrevious}
                </ButtonLeft>
                <Current>
                    <Text variant="body">
                        {currentPage}/{numPages}
                    </Text>
                </Current>
                <ButtonRight
                    onClick={() => {
                        if (currentPage + 1 > numPages) return;
                        onChangePage(currentPage + 1, rowCount);
                    }}>
                    {paginationIconNext}
                </ButtonRight>
                <Select>
                    <Selected
                        onClick={() => {
                            setIsVisible(!isVisible);
                        }}>
                        <SelectedLabel>
                            <Text variant="smaller">{selected.label}</Text>
                        </SelectedLabel>
                        <SelectedIcon>{pagesIcon}</SelectedIcon>
                    </Selected>
                    {isVisible && (
                        <Options>
                            {options.map((option, index) => {
                                return (
                                    <Option
                                        key={`option-${index + 1}`}
                                        onClick={() => {
                                            setSelected(option);
                                            setIsVisible(false);
                                        }}>
                                        <Text variant="smaller">{option.label}</Text>
                                    </Option>
                                );
                            })}
                        </Options>
                    )}
                </Select>
            </PaginatorContainer>
        </Container>
    );
}
