import { ActionType, createAction } from "typesafe-actions";

import {
    E_DocumentActionConstants,
    T_DocumentProps,
    T_PromissoryNoteIdInitializer,
    T_FetchDocumentRequest,
} from "../types";

export const smeDocumentActions = {
    smeFetchPromissoryNoteIdInitializer: createAction(
        E_DocumentActionConstants.PROMISSORY_NOTE_ID_INITIALIZER
    )<T_PromissoryNoteIdInitializer>(),
    smeFetchDocumentTrigger: createAction(
        E_DocumentActionConstants.FETCH_DOCUMENT_TRIGGER
    )<T_FetchDocumentRequest>(),
    smeFetchDocumentSuccess: createAction(
        E_DocumentActionConstants.FETCH_DOCUMENT_SUCCESS
    )<T_DocumentProps>(),
    smeFetchDocumentError: createAction(
        E_DocumentActionConstants.FETCH_DOCUMENT_ERROR
    )<T_DocumentProps>(),
};

export type SmeDocumentAction = ActionType<typeof smeDocumentActions>;
