import styled from "styled-components";
import { flexbox, layout, space, variant } from "styled-system";

import { AppTheme, AppThemeProps } from "@opr-finance/themes";

import { BasicSectionBoxProps } from "./types";

const variants = (theme: AppTheme) => ({
    basic: {
        display: "flex",
        margin: "0",
        padding: "0",
    },
    basicCentered: {
        display: "flex",
        flexDirection: "column",
        margin: 0,
        padding: 0,
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
    },
    paddingSizePrimary: {
        display: "flex",
        flexDirection: "column",
        margin: "0",
        paddingLeft: theme.sizes.primary,
        paddingRight: theme.sizes.primary,
        paddingTop: theme.sizes.medium,
        paddingBottom: theme.sizes.medium,
    },
    contactPage: {
        display: "flex",
        flexDirection: "column",
        margin: "12px 0",
        padding: "0",
        width: "100%",
        height: "100%",
    },
});

export const BasicSectionBox = styled.div<BasicSectionBoxProps>`
    ${(props: AppThemeProps) =>
        variant({
            variants: variants(props.theme),
        })}
    ${layout}
    ${space}
    ${flexbox}
`;
