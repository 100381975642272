import { SagaIterator } from "redux-saga";
import { takeEvery, put, call, select } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";

import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { smeLoanAccountActions } from "../actions";
import { smeLoanFetchAccount, updateAccountDetails } from "../api";
import { filterDocuments } from "../payload";

import {
    E_SmeLoan_AccountActionConstants,
    T_SmeLoan_FeatureAccountState,
    T_SmeLoan_AccountInitializerType,
    T_VpRegularLoanAccountForCreationResponse,
} from "../types";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* watchSmeLoanFetchAccountTrigger(): SagaIterator {
    yield takeEvery(
        E_SmeLoan_AccountActionConstants.FETCH_ACCOUNT_TRIGGER,
        handleFetchAccountTrigger
    );
}

export function* watchUpdateAccountTrigger() {
    yield takeEvery(
        E_SmeLoan_AccountActionConstants.UPDATE_ACCOUNT_TRIGGER,
        handleUpdateAccountTrigger
    );
}

export function* handleFetchAccountTrigger(
    action: ActionType<typeof smeLoanAccountActions.fetchAccountTrigger>
) {
    try {
        const { token, gwUrl, mockApiCalls, noAuth } = (yield select(
            (state: T_SmeLoan_FeatureAccountState) => {
                return state.account.config;
            }
        )) as T_SmeLoan_AccountInitializerType;

        if (!token) {
            logger.log("got no token, back to front page");
            return (window.location.href = noAuth);
        }

        const account: T_VpRegularLoanAccountForCreationResponse = yield call(smeLoanFetchAccount, {
            accountId: action.payload.activeAccountId,
            token,
            mockApiCalls,
            gwUrl,
        });

        const payload = filterDocuments(account);

        yield put(smeLoanAccountActions.fetchAccountSuccess(payload));
    } catch (e) {
        // console.log(`HELP__`, e);
        // yield put(smeLoanAccountActions.fetchAccountError(e.message));
        logger.log("fetch customer trigger failed", e);
    }
}

export function* handleUpdateAccountTrigger(
    action: ActionType<typeof smeLoanAccountActions.updateAccountTrigger>
): Generator {
    try {
        const { token, gwUrl, mockApiCalls, noAuth } = (yield select(
            (state: T_SmeLoan_FeatureAccountState) => {
                return state.account.config;
            }
        )) as T_SmeLoan_AccountInitializerType;

        const { accountId, bankAccount, country } = action.payload;
        const updateAccountPayload = {
            token,
            gwUrl,
            accountId,
            bankAccount,
            country,
        };

        const result = yield call(updateAccountDetails, { ...updateAccountPayload } as any);

        if (result) {
            // TODO: CHECK RESULT SUCCESS
            yield put(smeLoanAccountActions.updateAccountSuccess(action.payload));
        }
    } catch (e) {
        throw new Error("Failed to update company info: " + e);
    }
}
