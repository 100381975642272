import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import {
    translationStatiContentActions,
    TranslationStaticContentAction,
} from "../actions//translationStaticContent";
import { TranslationStaticContentReducerState } from "../types/contentful";

export const initialState = {
    translation: null,
    messages: undefined,
};

export const translationStaticContentReducer = createReducer<
    TranslationStaticContentReducerState,
    TranslationStaticContentAction
>(initialState).handleAction(
    translationStatiContentActions.fetchTranslationStaticContentSuccess,
    (state, action) => {
        return produce(state, (draftState) => {
            draftState.translation = action.payload;
            draftState.messages = action.payload;
        });
    }
);
