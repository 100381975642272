import { MessageData, GeneratedMessages } from "../types/transactions";

function msg(id) {
    return { id };
}

export function generateMessageObject(data: MessageData[]): GeneratedMessages {
    let generatedMessages = {};

    data.forEach((message: MessageData) => {
        generatedMessages[message.messageId] = msg(message.contentfulKey);
    });
    return generatedMessages;
}
