import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { format, parseISO } from "date-fns";
import { recentNewsActions, RecentNewsAction } from "../actions/recentNews";
import { RecentNewsReducerState, RecentNewsItemConf } from "../types/contentful";

export const initialState = {
    news: null,
};

export const recentNewsReducer = createReducer<RecentNewsReducerState, RecentNewsAction>(
    initialState
).handleAction(recentNewsActions.fetchRecentNewsSuccess, (state, action) => {
    return produce(state, (draftState) => {
        const news = action.payload.map((item) => {
            const createdDate = format(
                parseISO(item.sys.createdAt),
                RecentNewsItemConf.DEFAULT_FORMAT_DATE
            );
            const ingress = item.fields.content.slice(0, RecentNewsItemConf.INGRESS_MAX_LENGTH);

            if (ingress.endsWith(" ")) {
                item.fields.ingress = `${ingress}...`;
            } else {
                const lastSpace = ingress.lastIndexOf(" ");
                const lastSpaceIngress = ingress.slice(0, lastSpace + 1);
                item.fields.ingress = `${lastSpaceIngress}...`;
            }

            return {
                ...item.fields,
                createdDate,
            };
        });

        draftState.news = news;
    });
});
