import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { invoicesActions, InvoicesAction } from "../actions/index.actions";
import { T_InvoicesReducerState } from "../types/index.types";

export const initalState: T_InvoicesReducerState = {
    original: [],
    formatted: [],
    config: {
        mockApiCalls: false,
        gwUrl: "",
        token: "",
        activeAccountId: undefined,
        currencyCountry: "",
    },
};

export const invoicesReducer = createReducer<T_InvoicesReducerState, InvoicesAction>(initalState)
    .handleAction(invoicesActions.fetchInvoicesInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config.mockApiCalls = action.payload.mockApiCalls;
            draftState.config.gwUrl = action.payload.gwUrl;
            draftState.config.token = action.payload.token;
            draftState.config.activeAccountId = action.payload.activeAccountId;
            draftState.config.currencyCountry = action.payload.currencyCountry;
        });
    })
    .handleAction(invoicesActions.fetchInvoicesSuccess, (state, action) => {
        return produce(state, (draftState) => {
            if (action.payload.original !== undefined) {
                draftState.original = action.payload.original;
            }
            if (action.payload.formatted !== undefined) {
                draftState.formatted = action.payload.formatted;
            }
        });
    });
