import React, { FunctionComponent } from "react";
import { useRouteMatch } from "react-router";
import { StyledNavigationLink } from "./NavigationLink.styled";
import { NavigationLinkProps } from "./types";

export const NavigationLink: FunctionComponent<NavigationLinkProps> = (props) => {
    const match = useRouteMatch({
        path: props.to,
    });

    return (
        <StyledNavigationLink
            fontSize={["16px", "12px", "16px"]}
            active={match && props.active === "true" ? "true" : "false"}
            {...props}
        />
    );
};
