import React from "react";
import DataTable from "react-data-table-component";
import css from "@styled-system/css";

import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { PaginatorProps } from "../Paginator/Paginator";
import { StyledPaginator } from "../StyledPaginator/StyledPaginator";
import { Container } from "./StyledTable.styled";
import { TableProps } from "./types";

export function StyledTable<T>(props: TableProps<T>) {
    const logger = new ConsoleLogger({ level: LOG_LEVEL });
    const pages = Math.ceil(props.data.length / props.itemsPerPage);
    const paginationOptions = {
        rowsPerPageText: "Rows per page:",
        rangeSeparatorText: "of",
        noRowsPerPage: false,
        selectAllRowsItem: false,
        selectAllRowsItemText: "All",
        divider: "TEST",
    };

    return (
        <Container>
            <DataTable
                dense={true}
                noTableHead={props.noTableHead ?? false}
                noDataComponent={props.translation.noDataText}
                columns={props.columns}
                data={props.data}
                pagination={props.showAll !== undefined ? !props.showAll : true}
                paginationPerPage={props.itemsPerPage}
                paginationComponentOptions={paginationOptions}
                paginationComponent={(paginationProps: PaginatorProps) => {
                    return (
                        <StyledPaginator
                            {...paginationProps}
                            paginationIconPrevious={props.leftIcon}
                            paginationIconNext={props.rightIcon}
                            pagesIcon={props.pagesIcon}
                            labelPrefix={props.translation.labelPrefix}
                            divider={props.itemsPerPage}
                            pages={pages}
                        />
                    );
                }}
                customStyles={{
                    table: {
                        style: props.styleConfig.table,
                    },
                    headCells: {
                        style: props.styleConfig.headCells,
                    },
                    rows: {
                        style: props.styleConfig.rows,
                    },
                    cells: {
                        style: props.styleConfig.cells,
                    },
                    pagination: {
                        style: props.styleConfig.pagination.style,
                        pageButtonsStyle: props.styleConfig.pagination.pageButtonsStyle,
                    },
                }}
                onRowClicked={(row: T) => {
                    if (props.onRowClicked) {
                        props.onRowClicked(row);
                    }
                }}
            />
        </Container>
    );
}
