import { ActionType, createAction } from "typesafe-actions";
import {
    ContentfulActionConstants,
    TranslationV2Type,
    ContentfulParams,
    TranslationContentfulConfig,
} from "../types/contentful";

export const translationActions = {
    fetchTranslationTrigger: createAction(ContentfulActionConstants.FETCH_TRANSLATION_TRIGGER)<
        ContentfulParams<TranslationContentfulConfig>
    >(),
    fetchTranslationSuccess: createAction(ContentfulActionConstants.FETCH_TRANSLATION_SUCCESS)<
        TranslationV2Type[]
    >(),
    fetchTranslationError: createAction(ContentfulActionConstants.FETCH_TRANSLATION_ERROR)(),
};

export type TranslationAction = ActionType<typeof translationActions>;
