import { takeEvery, put, call } from "redux-saga/effects";

import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { recentNewsActions } from "../actions/recentNews";
import * as recentNewsApi from "../api/recentNews";
import { ContentfulActionConstants, RecentNewsType } from "../types/contentful";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* handleFetchNewsTrigger(
    action: ReturnType<typeof recentNewsActions.fetchRecentNewsTrigger>
) {
    try {
        const news: RecentNewsType[] = yield call(recentNewsApi.getRecentNews, {
            contentParams: action.payload.contentParams,
            clientParams: action.payload.clientParams,
        });

        yield put(recentNewsActions.fetchRecentNewsSuccess(news));
    } catch (e) {
        logger.log("Fetch recent news failed");
        logger.log(e);
    }
}

export function* watchFetchNewsTrigger() {
    yield takeEvery(ContentfulActionConstants.FETCH_NEWS_TRIGGER, handleFetchNewsTrigger);
}
