import { ActionType, createAction } from "typesafe-actions";
import {
    E_InvoicesActionConstants,
    T_InvoicesInitializerType,
    T_ApiResponse,
} from "../types/index.types";

export const invoicesActions = {
    fetchInvoicesInitializer: createAction(
        E_InvoicesActionConstants.FETCH_INVOICES_INITIALIZER
    )<T_InvoicesInitializerType>(),
    fetchInvoicesTrigger: createAction(E_InvoicesActionConstants.FETCH_INVOICES_TRIGGER)(),
    fetchInvoicesSuccess: createAction(
        E_InvoicesActionConstants.FETCH_INVOICES_SUCCESS
    )<T_ApiResponse>(),
    fetchInvoicesError: createAction(E_InvoicesActionConstants.FETCH_INVOICES_ERROR)(),
};

export type InvoicesAction = ActionType<typeof invoicesActions>;
