import { createSelector } from "reselect";
import * as LFP from "@opr-finance/api-definitions";
import { T_InvoicesReducerState, T_FormattedInvoice } from "@opr-finance/feature-statements";

import { AppState } from "../types/general";

export const selectNotPaidStatements = createSelector(
    (state: AppState) => state.invoices.formatted,
    (statements: Array<T_FormattedInvoice>) => {
        return statements?.filter((statement) => statement.status === "OVERDUE");
    }
);
