import { createGlobalStyle } from "styled-components";

import { GlobalStylesProps } from "../types";

export const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
    html {
        box-sizing: border-box;
    }
    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }
    html,
    body,
    #root {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
    }
    body {
        ${(props: GlobalStylesProps) =>
            props.bodyBackgroundImage && `background-image: url(${props.bodyBackgroundImage})`};
        ${(props: GlobalStylesProps) =>
            props.bodyBacgroundColor && `background-color: ${props.bodyBacgroundColor}`};
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-attachment: fixed;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;        
    }
    textarea,
    select,
    input,
    button {
        outline: 0;
    }
`;
