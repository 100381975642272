import {
    E_SmeTransactionStatuses,
    GeneratedMessages,
    generateMessageObject,
    MessageData,
} from "@opr-finance/feature-transactions-v2";
import { defineMessages } from "react-intl";

const transactionStatusesdata: MessageData[] = [
    {
        contentfulKey: "flex-online-fi-transactions-table-title-loan-adjustement-interest",
        messageId: E_SmeTransactionStatuses.LOAN_ADJUSTMENT_INTEREST_TRANSACTION,
    },
    {
        contentfulKey: "flex-online-fi-transactions-table-title-disbursement",
        messageId: E_SmeTransactionStatuses.LOAN_TRANCHE_DISBURSEMENT_TRANSACTION,
    },
    {
        contentfulKey: "flex-online-fi-transactions-table-title-payment",
        messageId: E_SmeTransactionStatuses.PAYMENT_TRANSACTION,
    },
    {
        contentfulKey: "flex-online-fi-transaction-table-title-statement-amortization",
        messageId: E_SmeTransactionStatuses.STATEMENT_AMORTIZATION_TRANSACTION,
    },
    {
        contentfulKey: "flex-online-fi-transactions-table-title-statement-late-payment-interest",
        messageId: E_SmeTransactionStatuses.STATEMENT_LATE_PAYMENT_INTEREST_TRANSACTION,
    },
    {
        contentfulKey: "flex-online-fi-transactions-table-title-statement-interest",
        messageId: E_SmeTransactionStatuses.STATEMENT_INTEREST_TRANSACTION,
    },
    {
        contentfulKey: "flex-online-fi-transactions-table-title-statement-rounding-transaction",
        messageId: E_SmeTransactionStatuses.STATEMENT_ROUNDING_TRANSACTION,
    },
    {
        contentfulKey: "flex-online-fi-transactions-table-title-virtual-payment-transaction",
        messageId: E_SmeTransactionStatuses.VIRTUAL_PAYMENT_TRANSACTION,
    },
    {
        contentfulKey: "flex-online-fi-transactions-table-title-manual-placing-payment-transaction",
        messageId: E_SmeTransactionStatuses.MANUAL_PLACING_PAYMENT_TRANSACTION,
    },
    {
        contentfulKey: "flex-online-fi-transactions-table-title-statement-tranche-startup-fee",
        messageId: E_SmeTransactionStatuses.STATEMENT_TRANCHE_STARTUP_FEE_TRANSACTION,
    },
    {
        contentfulKey: "flex-online-fi-transactions-table-title-statement-summarised-interest",
        messageId: E_SmeTransactionStatuses.AGGREGATED_INTEREST_TRANSACTION,
    },
];

const generatedTransactionsMessages: GeneratedMessages =
    generateMessageObject(transactionStatusesdata);

export const messages = defineMessages({
    pageTitle: {
        id: "flex-online-fi-loan-page-title",
    },
    heading1: {
        id: "flex-online-fi-loan-page-heading-1",
    },
    textContentLeft1: {
        id: "flex-online-fi-loan-page-text-content-left-1",
    },
    textContentLeft2: {
        id: "flex-online-fi-loan-page-text-content-left-2",
    },
    textContentRight1: {
        id: "flex-online-fi-loan-page-text-content-right-1",
    },
    textContentRight2: {
        id: "flex-online-fi-loan-page-text-content-right-2",
    },
    textPhone: {
        id: "flex-online-fi-text-phone",
    },
    textPhoneNumber: {
        id: "flex-online-fi-phone-number",
    },
    textEmail: {
        id: "flex-online-fi-text-email",
    },
    textEmailLink: {
        id: "flex-online-fi-email-customerService-link",
    },
    textEmailText: {
        id: "flex-online-fi-email-customerService-text",
    },
    loanDocumentsButtonText: {
        id: "flex-online-fi-loan-page-button-text-loan-documents",
    },
    modalTitle: {
        id: "flex-online-fi-loan-page-modal-title",
    },
    modalContent: {
        id: "flex-online-fi-loan-page-modal-content",
    },
    modalButtonText: {
        id: "flex-online-fi-loan-page-modal-button-text",
    },
    TableHeadingTransactions: {
        id: "flex-online-fi-loan-page-table-heading-Transactions",
    },
    TableHeadingInvoices: {
        id: "flex-online-fi-loan-page-table-heading-invoices",
    },
    TableDate: {
        id: "flex-online-fi-loan-page-table-date",
    },
    TableTransactionsType: {
        id: "flex-online-fi-loan-page-table-transactions-type",
    },
    pdfDownloadInstructions: {
        id: "flex-online-fi-loan-page-pdf-download-instructions",
    },
    TableAmount: {
        id: "flex-online-fi-loan-page-table-amount",
    },
    TableTextButton: {
        id: "flex-online-fi-loan-page-table-text-button",
    },
    documentsError: {
        id: "flex-online-fi-loan-page-document-error",
    },
    invoiceNumber: {
        id: "flex-online-fi-loan-page-invoice-number",
    },
    invoiceDefinition: {
        id: "flex-online-fi-loan-page-invoice-definition",
    },
    invoiceStatus: {
        id: "flex-online-fi-loan-page-invoice-status",
    },
    invoiceDueDate: {
        id: "flex-online-fi-loan-page-invoice-due-date",
    },
    invoiceAmount: {
        id: "flex-online-fi-loan-page-invoice-amount",
    },
    invoiceOverdue: {
        id: "flex-online-fi-loan-page-invoice-overdue",
    },
    invoicePaid: {
        id: "flex-online-fi-loan-page-invoice-paid",
    },
    invoiceOpen: {
        id: "flex-online-fi-loan-page-invoice-open",
    },
    fromDate: {
        id: "flex-online-fi-loan-page-table-from",
    },
    clearDates: {
        id: "flex-online-fi-loan-page-table-clear",
    },
    showAll: {
        id: "flex-online-fi-loan-page-table-show-all",
    },
    downloadTransactions: {
        id: "flex-online-fi-loan-page-download-transactions",
    },
    downloadOverview: {
        id: "flex-online-fi-loan-page-download-overview",
    },
    //reporting block messages
    reportingBlockHeading: {
        id: "flex-online-fi-loan-page-reporting-heading",
    },
    reportingBlockNoAccountDataMessage: {
        id: "flex-online-fi-loan-page-reporting-no-account-data-message",
    },
    reportingBlockInstructionsText: {
        id: "flex-online-fi-loan-page-reporting-reports-overview-instructions-text",
    },
    reportingBlockNoReportsMessage: {
        id: "flex-online-fi-loan-page-reporting-no-reports-message",
    },
    reportingBlockDateInputPlaceholder: {
        id: "flex-online-fi-loan-page-reporting-date-input-placeholder",
    },
    reportingBlockButtonText: {
        id: "flex-online-fi-loan-page-reporting-button-text",
    },
    reportingBlockLoadingText: {
        id: "flex-online-fi-loan-page-reporting-loading-text",
    },
    reportingBlockErrorText: {
        id: "flex-online-fi-loan-page-reporting-error-text",
    },
    ...generatedTransactionsMessages,
});
