import { httpFaker, WithAuthToken, WithMockApiCalls, WithGwUrl } from "@opr-finance/utils";
import { T_VpAuthenticationAccessToken } from "../types/general";

export type VerifyTokenRequest = {} & WithAuthToken & WithMockApiCalls & WithGwUrl;

export async function loginVerifyToken(data: VerifyTokenRequest): Promise<boolean> {
    const url = `${data.gwUrl}/api/auth/v1/tokens/verify`;
    const method = "POST";

    if (data.mockApiCalls) {
        return httpFaker<VerifyTokenRequest, boolean>(url, method, data, true);
    }

    const result: Response = await fetch(url, {
        method,
        headers: {
            "x-auth-token": data.token as string,
        },
    });

    return result.status === 200;
}

export type GetTokenRequest = {
    authorizationCode: string;
} & WithMockApiCalls &
    WithGwUrl;

export async function getToken(
    data: GetTokenRequest
): Promise<T_VpAuthenticationAccessToken | null> {
    const url = `${data.gwUrl.replace("/lfp", "")}/login`;
    const method = "POST";

    if (data.mockApiCalls) {
        return httpFaker<GetTokenRequest, T_VpAuthenticationAccessToken>(url, method, data, {
            accessToken:
                "SzgW6H1USnp3NGX+XUzcm/32td/EfoUNGv1+qFA9Z8u3fuHt78KHU4+snek8PmtR8bv3z5WR93BDHzVIiyVjAutQwcilXtxJZBHQT1WKFidr0Ku8lvLDZ1EqvnmpQfVLFIbECfRob2mXgraGYMpck7ZOaRzCpLGyl+yad72iC9a2zbQSJbxPOD4dlBzHcIcZOaU0BAsNW+zjiU/47b4t1CifKWhie3rQzx8eU+oOmylwKonZu8HG91vfqJZS0ArYGT/oYTSO6LYV8c+p2AsBnPw23P3bfSukqlxcxUaE9HN75tq4qX/p7ElGWvSkpaNvYZ1ExNwujvpn6nnY9Esa2kofMiS8vt5RMu2p/a2wHYCbY1yToVvPSzEQ5xe/6bIJ",
        });
    }

    const response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify({
            authorizationCode: data.authorizationCode,
        }),
    });

    if (response.status === 403 || response.status === 204) {
        return null;
    }

    return await response.json();
}
