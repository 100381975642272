import React, { ReactNode, useState, useEffect } from "react";

import { ContentContainer, MainContainer } from "./MobileNavigation.styled";
import { MorePage } from "./MorePage";

export type MobileNavigationProps = {
    isVisible: boolean;
    isMorePageVisible: boolean;
    onCloseClick: () => void;
    morePageAfter?: ReactNode;
    morePageHeader?: ReactNode;
    morePageLinks?: ReactNode[];
    morePageTitle?: string;
    morePageLinksTitle?: string;
    icons: ReactNode[];
};

export function MobileNavigation(props: MobileNavigationProps) {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        setIsVisible(props.isVisible);
    }, [props.isVisible]);

    if (!isVisible) {
        return null;
    }

    return (
        <MainContainer>
            <MorePage
                onCloseClick={props.onCloseClick}
                isVisible={props.isMorePageVisible}
                header={props.morePageHeader}
                title={props.morePageTitle ? props.morePageTitle : "no-title"}
                linksTitle={props.morePageLinksTitle ? props.morePageLinksTitle : "no-link.-title"}
                links={props.morePageLinks}
                after={props.morePageAfter}
            />
            <ContentContainer>{props.icons}</ContentContainer>
        </MainContainer>
    );
}
