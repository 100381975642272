import styled from "styled-components";
import { FunctionComponent } from "react";
import { Link, LinkProps } from "react-router-dom";
import {
    border,
    color,
    layout,
    typography,
    BorderProps,
    ColorProps,
    LayoutProps,
    TypographyProps,
} from "styled-system";
import { AppThemeProps } from "@opr-finance/themes";
import { E_Colors, E_Fonts } from "@opr-finance/theme-flex-online";

export type IsActiveProp = {
    active: string;
};

export type NavigationLinkProps = BorderProps &
    ColorProps &
    LayoutProps &
    TypographyProps &
    LinkProps &
    IsActiveProp;
export type NavigationLinkPropsWithTheme = NavigationLinkProps & AppThemeProps;

export const StyledNavigationLink: FunctionComponent<NavigationLinkProps> = styled(
    Link
)<NavigationLinkProps>`
    color: ${E_Colors.PRIMARY};
    font-family: ${E_Fonts.FONT_FAMILY};
    font-size: ${E_Fonts.BASIC_FONT_SIZE};
    text-decoration: none;
    transition: 0.3s ease-in-out;
    ${border}
    ${color} 
    ${layout} 
    ${typography}
    &:hover {
        > span {
            border-bottom: 1px solid ${E_Colors.BORDER};
            font-weight: 600;
        }
    }
    ${(props) =>
        props.active === "true" &&
        `> span {
        border-bottom: 1px solid ${E_Colors.BORDER};
        font-weight: bold;
    }`};
`;
