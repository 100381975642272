import { defineMessages } from "react-intl";

export const messages = defineMessages({
    frontPage: {
        id: "flex-online-fi-header-frontpage",
    },
    loanPage: {
        id: "flex-online-fi-header-loanInfo",
    },
    topUpPage: {
        id: "flex-online-fi-header-TopUp",
    },
    userInfo: {
        id: "flex-online-fi-header-userInfo",
    },
    contactInfo: {
        id: "flex-online-fi-header-contactInfo",
    },
    logout: {
        id: "flex-online-fi-header-button-logout",
    },
    logIn: {
        id: "flex-online-fi-header-button-login",
    },
    mobileTopUp: {
        id: "flex-online-fi-header-mobile-topUp",
    },
    mobileMore: {
        id: "flex-online-fi-header-mobile-more",
    },
    mobileLoanInfo: {
        id: "flex-online-fi-header-mobile-loanInfo",
    },
    mobileNavContact: {
        id: "flex-online-fi-header-mobileNav-contacts",
    },
    mobileNavCustomerService: {
        id: "flex-online-fi-header-mobileNav-customer-service",
    },
});

export const footerMessages = defineMessages({
    headingLinks: {
        id: "flex-online-fi-footer-heading-links",
    },
    headingCustomerService: {
        id: "flex-online-fi-footer-heading-customerService",
    },
    headingContactAddress: {
        id: "flex-online-fi-footer-heading-address",
    },
    registrationDocumentLinkText: {
        id: "flex-online-fi-footer-link-registrationDocument-text",
    },
    cookiesLinkText: {
        id: "flex-online-fi-footer-link-cookies-text",
    },
    openingHours: {
        id: "flex-online-fi-footer-openingHours",
    },
    phoneNumber: {
        id: "flex-online-fi-phone-number",
    },
    emailTextLink: {
        id: "flex-online-fi-email-customerService-link",
    },
    emailText: {
        id: "flex-online-fi-email-customerService-text",
    },
    FAQLinkText: {
        id: "flex-online-fi-link-FAQ-text",
    },
    companyName: {
        id: "flex-online-fi-footer-company-name",
    },
    companyAddress: {
        id: "flex-online-fi-footer-company-address",
    },
    companyZip: {
        id: "flex-online-fi-footer-company-zip",
    },
    businessID: {
        id: "flex-online-fi-footer-businessID",
    },
});
