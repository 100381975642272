import { takeEvery, take, put, call, select, takeLatest, race, all } from "redux-saga/effects";

import { recentNewsActions } from "@opr-finance/feature-contentful";
import { errorActions } from "@opr-finance/feature-error";
import { smeLoanAccountActions } from "@opr-finance/feature-account";
import { smeWithdrawActions } from "@opr-finance/feature-withdraw";
import { companyActions, engagementActions } from "@opr-finance/feature-sme-customer";
import { history } from "@opr-finance/utils";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { AppState, E_Routes } from "../types/general";
import {
    checkSession,
    getAccountData,
    getInvoiceData,
    getTransactionsData,
    setInvoiceConfig,
    setTransactionsConfig,
} from "./common.saga";
import { AppActionConstants, appActions } from "../actions/actions";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* watchFrontPageTrigger() {
    yield takeLatest(AppActionConstants.FRONT_PAGE_TRIGGER, handleFrontPageTrigger);
}

export function* handleFrontPageTrigger() {
    const mock = (process.env.REACT_APP_MOCK as string) === "1" ? true : false;
    const gwUrl = process.env.REACT_APP_API_URL as string;
    const gwUrlV2 = process.env.REACT_APP_API_URL_V2 as string;

    try {
        const state: AppState = yield select((state: AppState) => state);
        const { account, customer, session } = state;
        const accountConfigUrl = account.config.gwUrl;
        const companyConfigUrl = customer.companyInfo.config.gwUrl;
        const token = session.token;
        if (token) {
            yield call(checkSession);
            yield put(engagementActions.engagementTrigger());

            const { engagementSuccess, engagementError } = yield race({
                engagementSuccess: take(engagementActions.engagementSuccess),
                engagementError: take(engagementActions.engagementError),
            });

            if (engagementError) {
                logger.log("error getting engagements");
                window.location.href = "/error";
            }
            if (!accountConfigUrl) {
                yield put(
                    smeLoanAccountActions.accountInitializer({
                        mockApiCalls: mock,
                        gwUrl,
                        token,
                        errorUrl: E_Routes.ERROR,
                        noAuth: E_Routes.EXPIRED,
                        noLoanUrl: E_Routes.NO_LOAN,
                    })
                );
            }
            if (!companyConfigUrl) {
                yield put(
                    companyActions.companyDataInitializer({
                        token,
                        gwUrl,
                        mock: mock,
                    })
                );
            }

            yield put(
                recentNewsActions.fetchRecentNewsTrigger({
                    clientParams: {
                        space: process.env.REACT_APP_CONTENTFUL_SPACE as string,
                        accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN as string,
                    },
                    contentParams: {
                        select: "fields,sys.createdAt",
                        content_type: "recentNews",
                        "fields.language[in]": "fi",
                        "fields.application[in]": "Flex Online Finland",
                    },
                })
            );
            yield take(recentNewsActions.fetchRecentNewsSuccess);
            yield call(getAccountData);

            yield call(setInvoiceConfig);
            yield call(setTransactionsConfig);

            yield put(
                smeWithdrawActions.withdrawInitializer({
                    mockApiCalls: mock,
                    gwUrl,
                    token,
                })
            );

            yield all([call(getInvoiceData), call(getTransactionsData)]);

            yield put(appActions.frontPageSuccess());
        }
    } catch (e) {
        history.push(E_Routes.ERROR);
        yield put(
            errorActions.errorTrigger({ message: "frontpage load failed" + e, url: "/error" })
        );
    }
}
