import { takeEvery, put, select, delay } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import {
    E_LoginSessionActionConstants,
    T_FeatureLoginSessionState,
    T_LoginRoles,
    T_LoginSessionReducerState,
} from "../types";
import { loginSessionActions } from "../actions";
import { ActionType } from "typesafe-actions";
import { verifyToken } from "..";
import { sessionLogout } from "../api/sessionEnd";
import { getSessionInfo } from "../api/getSessionInfo";
import { E_CID, T_GetSessionInfoPayload, T_SessionInfoAttrs } from "../types/types";
const call = Effects.call;

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* watchLoginSessionTrigger() {
    yield takeEvery(E_LoginSessionActionConstants.LOGIN_SESSION_TRIGGER, handleLoginSessionTrigger);
}

export function* handleLoginSessionTrigger(
    action: ActionType<typeof loginSessionActions.loginSessionTrigger>
) {
    try {
        const {
            config: { cid, lang, authUrl },
        } = (yield select(
            (state: T_FeatureLoginSessionState) => state.session
        )) as T_LoginSessionReducerState;

        const redirectUrl = action.payload.bank
            ? `${authUrl}?cid=${cid}&lang=${lang}${
                  action.payload.bank !== undefined && `&bank=${action.payload.bank}`
              }`
            : `${authUrl}?cid=${cid}&lang=${lang}`;

        window.location.href = redirectUrl;
    } catch (e) {
        logger.log("action trigger failed", e);
    }
}

export function* watchloginSessionComplete(): Generator {
    yield takeEvery(
        E_LoginSessionActionConstants.LOGIN_SESSION_COMPLETE,
        handleLoginSessionComplete
    );
}

export function* handleLoginSessionComplete(): Generator {
    try {
        const { config } = (yield select(
            (state: T_FeatureLoginSessionState) => state.session
        )) as T_LoginSessionReducerState;
        const query = new URLSearchParams(window.location.search);
        const id = localStorage.getItem("token");
        const role = localStorage.getItem("role") as T_LoginRoles;

        if (id) {
            if (role === "act-as-customer") {
                const reference = localStorage.getItem("reference") as string;
                yield put(
                    loginSessionActions.loginSessionTokenSuccess({
                        token: id,
                        authenticated: true,
                        role,
                        ssn: reference,
                        name: "",
                    })
                );
            }
            const sessionInfo = yield call<
                (props: T_GetSessionInfoPayload) => Promise<T_SessionInfoAttrs>
            >(getSessionInfo, {
                url: config.getSessionInfoUrl ?? "",
                id: id ?? "",
            });
            const { ssn, reference, fullname } = sessionInfo as T_SessionInfoAttrs;
            const referenceOrSsn = ssn || reference;
            const authenticated = true;
            yield put(
                loginSessionActions.loginSessionTokenSuccess({
                    token: id,
                    authenticated,
                    role,
                    ssn: referenceOrSsn,
                    name: fullname,
                })
            );

            if (config.cid === E_CID.YRITYSLUOTTO_FI) {
                window.location.href = config.successUrl || "/application";
            }
        } else {
            // This logic is to entreconnected so need to be refactored!!
            if (query.has("id")) {
                const id = decodeURIComponent(query.get("id") as string);
                const reference = decodeURIComponent(query.get("reference") as string);
                const role = decodeURIComponent(query.get("role") as string);

                if (reference && role === "act-as-customer") {
                    localStorage.setItem("token", id);
                    localStorage.setItem("role", role);
                    localStorage.setItem("reference", reference);
                    yield put(
                        loginSessionActions.loginSessionTokenSuccess({
                            token: id,
                            authenticated: true,
                            role: "act-as-customer",
                            ssn: reference,
                            name: "",
                        })
                    );
                } else {
                    const sessionInfo: any = yield call<
                        (props: T_GetSessionInfoPayload) => Promise<T_SessionInfoAttrs>
                    >(getSessionInfo, {
                        url: config.getSessionInfoUrl ?? "",
                        id: id ?? "",
                    });
                    if (sessionInfo) {
                        const { ssn, reference, fullname } = sessionInfo as T_SessionInfoAttrs;
                        const authenticated = true;
                        const role = "customer";
                        localStorage.setItem("token", id);
                        localStorage.setItem("role", role);
                        const referenceOrSsn = ssn || reference;
                        yield put(
                            loginSessionActions.loginSessionTokenSuccess({
                                token: id,
                                authenticated,
                                role,
                                ssn: referenceOrSsn,
                                name: fullname,
                            })
                        );
                        if (config.cid === E_CID.YRITYSLUOTTO_FI) {
                            window.location.href = config.successUrl || "/application";
                        }
                    } else {
                        logger.log("Could not get session info");
                        window.location.href = config.errorUrl || "/error";
                    }
                }
            } else {
                logger.log("No ID or REFERENCE available in query");
                window.location.href = config.errorUrl || "/error";
            }
        }
    } catch (e) {
        logger.log("login failed!", e);
    }
}

export function* watchLoginSessionVerify() {
    yield takeEvery(
        E_LoginSessionActionConstants.LOGIN_SESSION_TOKEN_VERIFY,
        handleLoginSessionVerify
    );
}

export function* handleLoginSessionVerify() {
    try {
        const sessionData = (yield select(
            (state: T_FeatureLoginSessionState) => state.session
        )) as T_LoginSessionReducerState;
        if (sessionData.token) {
            const result = yield call(verifyToken, sessionData);
            logger.log("Session verify result:", result);

            if (result === 403) {
                window.location.href = sessionData.config.expiredUrl;
            }
            if (result === 500) {
                window.location.href = sessionData.config.errorUrl;
            }

            yield delay(60000);

            yield put(loginSessionActions.loginSessionVerify());
        }
    } catch (e) {
        logger.log("action verify failed", e);
    }
}

export function* watchLoginSessionEnd() {
    yield takeEvery(E_LoginSessionActionConstants.LOGIN_SESSION_END, handleLoginSessionEnd);
}

export function* handleLoginSessionEnd() {
    try {
        const { endSessionApiUrl, logoutUrl, errorUrl } = yield select(
            (state: T_FeatureLoginSessionState) => state.session.config
        );

        const token = localStorage.getItem("token") as string;

        const payload = { url: endSessionApiUrl, token: token };

        const result = yield call(sessionLogout, payload);

        if (result.status !== 200) {
            // window.location.href = errorUrl;
        }

        localStorage.removeItem("token");
        sessionStorage.removeItem("smeId");
        yield put(
            loginSessionActions.loginSessionTokenSuccess({
                token: "",
                authenticated: false,
                role: "customer",
                ssn: "",
                name: "",
            })
        );
    } catch (e) {
        logger.log("action verify failed", e);
    }
}
