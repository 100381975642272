import { takeEvery, put, select } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";
import {
    E_ReportingActionConstants,
    T_FeatureReportingState,
    T_ReportingInitializerType,
    T_YearlyOverviewDataNl,
    T_YearlyOverviewDataFi,
    T_YearlyOverviewDataSe,
} from "../types";
import { reportingActions } from "../actions/reporting";
import * as reportingApi from "../api";

import { ActionType } from "typesafe-actions";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

const logger = new ConsoleLogger({ level: LOG_LEVEL });
const call: any = Effects.call;

export function* handleFetchReport(action: ActionType<typeof reportingActions.reportingTrigger>) {
    try {
        const { token, mockApiCalls, gwUrl, accountNumber, cid } = (yield select(
            (state: T_FeatureReportingState) => state.reporting.config
        )) as T_ReportingInitializerType;

        if (!token) {
            logger.log("got no token, back to front page");
            return (window.location.href = "/");
        }

        const reportingEndDate = action.payload.reportingEndDate;
        const report: T_YearlyOverviewDataNl | T_YearlyOverviewDataFi | T_YearlyOverviewDataSe =
            yield call(reportingApi.fetchReport, {
                token,
                gwUrl,
                accountNumber,
                mockApiCalls,
                reportingEndDate,
                cid,
            });
        yield put(
            reportingActions.reportingSuccess({
                status: "SUCCESS",
                yearlyOverview: report,
            })
        );
    } catch (e) {
        yield put(
            reportingActions.reportingError({
                status: "FAIL",
                yearlyOverview: {
                    loanAccountNumber: "",
                    companyName: "",
                    organizationNumber: "",
                    email: "",
                    city: "",
                    zipcode: "",
                    streetAddress: "",
                    status: "",
                    remainingPrincipalSumEnd: "",
                    reportingStartDate: "",
                    reportingEndDate: "",
                },
            })
        );

        logger.log("action trigger failed", e);
    }
}

export function* watchFetchReport() {
    yield takeEvery(E_ReportingActionConstants.REPORTING_TRIGGER, handleFetchReport);
}
