import { takeEvery, put, call, select, fork, delay, all } from "redux-saga/effects";

import * as Leandev from "@opr-finance/api-definitions";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { activeAccountActions } from "../actions/activeAccount";
import * as accountApi from "../api/activeAccount";
import {
    ActiveAccountConstants,
    FeatureLoginState,
    LoginReducerState,
    T_VpAccount,
} from "../types/general";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* watchAccountSelection() {
    yield takeEvery(ActiveAccountConstants.ACTIVE_ACCOUNT_NUMBER_SELECT, handleAccountSelection);
}

export function* handleAccountSelection(
    action: ReturnType<typeof activeAccountActions.selectFromAccounts>
) {
    try {
        const config = (yield select((state: FeatureLoginState) => {
            return state.login;
        })) as LoginReducerState;

        const token = localStorage.getItem("token") as string | null;

        if (!token) {
            logger.log("got no token, back to front page");
            return (window.location.href = "/");
        }
        logger.log("GOT ACCOUNTS", action.payload.accountList);

        const accounts: Array<T_VpAccount> = yield all(
            action.payload.accountList.map((account) =>
                call(accountApi.fetchAccount, {
                    accountId: account.id,
                    token: config.token,
                    mockApiCalls: config.mockApiCalls,
                    gwUrl: config.gwUrl,
                    productType: action.payload.productType,
                })
            )
        );

        let account = accounts.filter(
            (account) =>
                account.state === "OPEN" ||
                account.state === "PENDING" ||
                account.state === "COLLECTION"
        );

        if (account.length > 1) {
            account = account.filter((acc) => acc.state === "OPEN" || acc.state === "COLLECTION");
        }

        // No accounts
        if (account.length < 1) {
            logger.warn("No accounts", account.length);
            yield put(activeAccountActions.selectFromAccountsError());
        } else {
            yield put(
                activeAccountActions.selectFromAccountsSuccess({
                    activeAccount: {
                        accountId: account[0].id,
                        accountNumber: account[0].accountNumber,
                    },
                    account: account[0],
                })
            );
        }
    } catch (e) {
        logger.log("Fetching account failed", e);
    }
}
