import React, { FunctionComponent, ReactNode } from "react";

import styled from "styled-components";
import { Wrapper } from "@opr-finance/component-wrapper";
import { GridContainer, Grid, GridContent, StyledGrid } from "@opr-finance/component-grid";
import { BoxContainer, Box } from "@opr-finance/component-box-container";
import { FooterStyles } from "@opr-finance/theme-flex-online";

import { FooterProps, FooterColumn, FooterComponentProps } from "../types";

export const StyledFooter: FunctionComponent<FooterProps> = (props: FooterProps) => {
    const content: ReactNode[] = [];

    const itemContainer = (item: FooterColumn, length: number, key: number = 1) => (
        <Grid
            width={1 / length}
            key={`item${key}`}
            order={item.order ? item.order : 0}
            marginBottom={item.marginBottom && item.marginBottom}
        >
            <GridContent width="auto">{item.content()}</GridContent>
        </Grid>
    );

    if (props.columns) {
        const columnCount = props.columns.length;
        props.columns.map((column, index) => {
            content.push(itemContainer(column, columnCount, index));
        });
    }

    props.children &&
        !props.columns &&
        content.push(itemContainer({ content: () => props.children }, 1));

    return (
        <StyledGrid styleConfig={{ root: FooterStyles.footer() }}>
            {/* {props.before && <Grid maxWidth={"100%"}>{props.before}</Grid>} */}
            <GridContainer>{content}</GridContainer>
            {/* {props.after && <Grid maxWidth={"100%"}>{props.after}</Grid>} */}
        </StyledGrid>
    );
};
