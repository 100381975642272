export function showDocument(documentUrl) {
    const link: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
    link.href = documentUrl;
    link.target = "_blank";
    link.click();
}

export function downloadDocument(documentUrl) {
    const link: HTMLAnchorElement = document.createElement("a") as HTMLAnchorElement;
    link.href = documentUrl;
    link.download = "document.pdf";
    link.click();
}
