import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { SmeTransactionsAction, smeTransactionsActions } from "../actions/smeTransactions";
import { T_SmeTransactionReducerState } from "../types/smeTransactions";

export const initialState: T_SmeTransactionReducerState = {
    statementTransactions: [],
    config: {
        mockApiCalls: false,
        gwUrl: "",
        gwUrlV2: "",
        token: "",
        size: 0,
        shownInStatement: false,
        accountId: undefined,
        country: "",
        endDate: "",
        startDate: "",
    },
};

export const smeTransactionsReducer = createReducer<
    T_SmeTransactionReducerState,
    SmeTransactionsAction
>(initialState)
    .handleAction(smeTransactionsActions.smeFetchTransactionsInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config = action.payload;
        });
    })
    .handleAction(smeTransactionsActions.smeFetchTransactionsSuccess, (state, action) => {
        return produce(state, (draftState) => {
            draftState.statementTransactions = action.payload.statementTransactions;
        });
    });
