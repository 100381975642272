import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export async function httpFaker<A, B>(
    url: string,
    method: string,
    data: A,
    response: B
): Promise<B> {
    return new Promise((resolve) => {
        setTimeout(() => {
            logger.log("MOCK: " + url, method, data, response);
            resolve(response);
        }, 100);
    });
}
