import React from "react";
import { StyledGrid } from "@opr-finance/component-grid";
import { Font } from "@opr-finance/component-fonts";

import { StyledPageTitleProps } from "./types";

export const StyledPageTitle = (props: StyledPageTitleProps) => {
    return (
        <StyledGrid styleConfig={{ root: props.styleConfig.pageTitleContainer }}>
            <Font styleConfig={{ root: props.styleConfig.pageTitleText }} as="p">
                {props.title}
            </Font>
        </StyledGrid>
    );
};
