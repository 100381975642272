import { translationMap } from "../types/contentful";
import {
    IStaticAssetTypeText,
    IStaticAssetTypeLink,
    IStaticContent,
    IStaticAssetTypeLongText,
} from "../types/generated";

function instanceOfText(item): item is IStaticAssetTypeText {
    return item.value !== undefined;
}
function instanceOfLongText(item): item is IStaticAssetTypeLongText {
    return item.longTextValue !== undefined;
}

export const getTranslations = (payload: IStaticContent[]): translationMap<string> => {
    let translations: translationMap<string> = {};

    payload.forEach((element) => {
        // console.log(element.fields.staticText);
        element.fields.staticText?.forEach((item) => {
            if (instanceOfText(item.fields)) {
                const el: IStaticAssetTypeText = item as IStaticAssetTypeText;
                translations = {
                    ...translations,
                    [el.fields.slug]: el.fields.value || "",
                };
            } else if (instanceOfLongText(item.fields)) {
                const el: IStaticAssetTypeLongText = item as IStaticAssetTypeLongText;
                translations = {
                    ...translations,
                    [el.fields.slug]: el.fields.longTextValue || "",
                };
            } else {
                const el: IStaticAssetTypeLink = item as IStaticAssetTypeLink;
                const linkText: string = el.fields.linkText || "";
                const link: string = el.fields.link || "";
                translations = {
                    ...translations,
                    [`${el.fields.slug}-link`]: link,
                    [`${el.fields.slug}-text`]: linkText,
                };
            }
        });
    });

    return translations;
};
