import { T_VpRegularLoanAccountForCreationResponse } from "../types";

export const filterDocuments = (
    accountPayload: T_VpRegularLoanAccountForCreationResponse
): T_VpRegularLoanAccountForCreationResponse => {
    let { documents } = accountPayload;

    documents =
        documents &&
        documents
            .filter((document) => document.documentType === "PROMISSORY_NOTE")
            .filter((document) => document.signStatus === "DIGITAL_SIGNED")
            .sort((a, b) => {
                if (a?.createTime && b?.createTime) return a.createTime < b.createTime ? 1 : -1;
                return 0;
            });

    const payload = {
        ...accountPayload,
        documents,
    };

    return payload;
};
