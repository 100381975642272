import { ActionType, createAction } from "typesafe-actions";
import {
    StatementsActionConstants,
    StatementsInitializerType,
    T_VpStatementV2,
} from "../types/statements";

export const statementsActions = {
    fetchStatementsInitializer: createAction(
        StatementsActionConstants.FETCH_STATEMENTS_INITIALIZER
    )<StatementsInitializerType>(),
    fetchStatementsTrigger: createAction(StatementsActionConstants.FETCH_STATEMENTS_TRIGGER)(),
    fetchStatementsSuccess: createAction(StatementsActionConstants.FETCH_STATEMENTS_SUCCESS)<
        Array<T_VpStatementV2>
    >(),
    fetchStatementsError: createAction(StatementsActionConstants.FETCH_STATEMENTS_ERROR)(),
};

export type StatementsAction = ActionType<typeof statementsActions>;
