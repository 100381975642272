import React from "react";
import { Container } from "./IconContainer.styled";
import { Icon } from "@opr-finance/component-icon";
import { Font, Text } from "@opr-finance/component-fonts";
import { E_Colors, HeaderStyles } from "@opr-finance/theme-flex-online";

export type IconContainerProps = {
    icon: string[];
    size: string;
    text: string;
    isActive: () => boolean;
    onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
};

export function IconContainer(props: IconContainerProps) {
    return (
        <Container
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                if (props.onClick) {
                    props.onClick(event);
                }
            }}>
            <div>
                <Icon
                    icon={props.icon}
                    size={props.size}
                    color={props.isActive() ? E_Colors.PRIMARY : "#6C7A80"}
                />
            </div>
            <div>
                <Font
                    styleConfig={{
                        root: HeaderStyles.mobileNavText({
                            color: props.isActive() ? E_Colors.PRIMARY : "#6C7A80",
                        }),
                    }}>
                    {props.text}
                </Font>
            </div>
        </Container>
    );
}
