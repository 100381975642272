import { FeatureLoginState } from "@opr-finance/feature-lfp-login";
import { DefaultInitializerType } from "@opr-finance/utils";

export enum DocumentActionConstants {
    FETCH_DOCUMENT_INITIALIZER = "DOCUMENT/INITIALIZER",
    FETCH_DOCUMENT_TRIGGER = "DOCUMENT/FETCH_DOCUMENT_TRIGGER",
    FETCH_DOCUMENT_SUCCESS = "DOCUMENT/FETCH_DOCUMENT_SUCCESS",
    FETCH_DOCUMENT_ERROR = "DOCUMENT/FETCH_DOCUMENT_ERROR",
    PROMISSORY_NOTE_ID_INITIALIZER = "DOCUMENT/PROMISSORY_NOTE_ID_INITIALIZER",
    PROMISSORY_NOTE_ID_INITIALIZED = "DOCUMENT/PROMISSORY_NOTE_ID_INITIALIZED",
}

export enum DocumentType {
    PROMISSORY_NOTE = "PROMISSORY_NOTE",
    INVOICE = "invoice",
    POA = "poa",
}

export enum DocumentDownloadStatus {
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    ERROR = "ERROR",
}

export type DocumentTypes = typeof DocumentType[keyof typeof DocumentType];

export type PromissoryNoteIdInitializer = {
    accountNumber: string | undefined;
    defaultPromissoryNoteId: string | undefined;
} & DefaultInitializerType;

export type PromissoryNoteIdInitialized = {
    documentId: string | undefined;
};

export type DocumentInitializerType = {
    generatorUrlBase: string;
    source: string;
    language?: string;
} & DefaultInitializerType;

export type FetchDocumentParams = {
    documentType: DocumentTypes;
    documentId?: string | undefined;
    data?: IPdfBodyRequest;
};

export type DocumentProps = {
    documentId: string | undefined;
    downloadStatus: typeof DocumentDownloadStatus[keyof typeof DocumentDownloadStatus];
    data?: Blob;
    documentUrl?: string;
};

export type DocumentReducerState = {
    document: {
        [key: string]: DocumentProps;
    } | null;
    documentId: string | undefined;
    config: DocumentInitializerType;
};

/** REFACTOR: This fixes type issues in Flex Online AppState as it needs to use feature-luvittaja-login,
 *  type T is defined as the LoginState being used, by default it is FeatureLoginState from feature-lfp-login
 *  todo: check if login state can be completely removed from this type
 */
export type FeatureDocumentState<T = FeatureLoginState> = {
    document: DocumentReducerState;
} & T;

export interface IPdfPayloadParams {
    translationType: string;
    language?: string;
}

export interface IPdfBodyRequest {
    name: string;
    ssn: string;
    address: string;
    postCode: string;
    city: string;
    phoneNumber: string;
    params: IPdfPayloadParams;
}

export interface IPdfRequestParams {
    documentType: DocumentTypes;
    source: string;
}
export interface IGeneratePdfRequest {
    params: IPdfRequestParams & DocumentInitializerType;
    payload: IPdfBodyRequest | undefined;
}
