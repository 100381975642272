import { WithAuthToken, WithMockApiCalls, WithGwUrl } from "@opr-finance/utils";

import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import {
    E_BankNumberCountry,
    T_AccountUpdateRequest,
    T_VpDisbursementAccount,
    T_VpRegularLoanAccountForCreationResponse,
} from "../types";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export type T_SmeLoan_FetchAccountRequest = {
    accountId: string;
} & WithAuthToken &
    WithMockApiCalls &
    WithGwUrl;

export async function smeLoanFetchAccount(
    data: T_SmeLoan_FetchAccountRequest
): Promise<T_VpRegularLoanAccountForCreationResponse | undefined> {
    try {
        const url = `${data.gwUrl}/api/loan/v8/accounts/${data.accountId}`;
        const method = "GET";

        const result: Response = await fetch(url, {
            method,
            headers: {
                "content-type": "application/json",
                authorization: data.token as string,
            },
        });
        const response = await result.json();

        if (data.mockApiCalls) {
            logger.log("MOCK DATA - COMPANY ACCOUNT DATA");
            logger.log(response);
        }

        return response;
    } catch (error) {
        logger.log("Failed to fetch account", error);
    }
}

export async function updateAccountDetails(
    data: T_AccountUpdateRequest
): Promise<T_VpDisbursementAccount> {
    const url = `${data.gwUrl}/upadte/bankaccount/api/loan/v8/accounts/${data.accountId}/disbursementAccount`;
    const method = "PUT";
    const updateData: T_VpDisbursementAccount = {
        type: E_BankNumberCountry[data.country],
        number: data.bankAccount,
    };

    const result = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            authorization: data.token as string,
        },
        body: JSON.stringify({ updateData }),
    });

    return result.json();
}
