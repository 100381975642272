import React, { Children, ReactNode, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Loader } from "@opr-finance/component-loader";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { initializerActions } from "../actions/initializer";
import { Root } from "./AppInitializer.styled";
import { equals } from "../utils/equals";
import { FeatureInitializerState, InitializerReducerState } from "../types/initializer";

export type AppInitializerProps<T> = {
    actionsRequired?: string[];
    initializerAction?: string;
    actionPayload?: T;
    children: ReactNode;
    loader?: ReactNode;
};

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function AppInitializer<T = void>(props: AppInitializerProps<T>) {
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.actionsRequired && props.actionsRequired.length > 0) {
            dispatch(
                initializerActions.initApplication({
                    initializers: props.actionsRequired,
                })
            );
        } else {
            dispatch(
                initializerActions.initApplication({
                    initializers: [],
                })
            );
        }
        if (props.initializerAction) {
            dispatch({
                type: props.initializerAction,
                payload: props?.actionPayload && props?.actionPayload,
            });
        }
    }, [window.location.href]);

    const initializer = useSelector<FeatureInitializerState, InitializerReducerState>((state) => {
        return state.initializer;
    });

    if (initializer.initializers.length === initializer.initialized.length) {
        logger.log("initializers matching", initializer.initializers, initializer.initialized);
        return <>{props.children}</>;
    }

    logger.log(
        "initializers do not match, can not render page yet",
        initializer.initializers,
        initializer.initialized
    );

    return <Root>{props.loader ? props.loader : <Loader isLoading={true} />}</Root>;
}
