import { put, call, select, takeLatest, race, take } from "redux-saga/effects";

import { errorActions } from "@opr-finance/feature-error";
import { companyActions, engagementActions } from "@opr-finance/feature-sme-customer";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { AppActionConstants, appActions } from "../actions/actions";
import { getAccountData, getCompanyData, setAccountConfig } from "./common.saga";
import { AppState } from "../types/general";
import { ActionType } from "typesafe-actions";

type T_UpdateBankAccountPayload = {
    number: string | undefined;
    accountId: string | undefined;
    type: "IBAN";
};

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* watchUserPageTrigger() {
    yield takeLatest(AppActionConstants.USER_PAGE_TRIGGER, handleUserPageTrigger);
}
export function* watchUserInfoUpdateTrigger() {
    yield takeLatest(AppActionConstants.USER_INFO_UPDATE_TRIGGER, handleUserInfoUpdateTrigger);
}

export function* handleUserPageTrigger() {
    try {
        yield put(engagementActions.engagementTrigger());
        const { engagementSuccess, engagementError } = yield race({
            engagementSuccess: take(engagementActions.engagementSuccess),
            engagementError: take(engagementActions.engagementError),
        });

        if (engagementError) {
            logger.log("error getting engagements");
            window.location.href = "/error";
        }
        yield call(setAccountConfig);
        yield call(getAccountData);
        const companyInfo = yield select((state: AppState) => state.customer.companyInfo);
        const smeId = yield select((state: AppState) => state.customer.engagement.activeSmeId);

        if (!companyInfo.id) {
            yield call(getCompanyData, smeId);
        }

        yield put(appActions.userPageSuccess());
    } catch (e) {
        yield put(
            errorActions.errorTrigger({ message: "contact page load failed" + e, url: "/error" })
        );
    }
}

export function* handleUserInfoUpdateTrigger(
    action: ActionType<typeof appActions.userInfoUpdateTrigger>
) {
    try {
        const country: string = process.env.REACT_APP_COUNTRY as string;
        const { account, customer } = yield select((state: AppState) => state);
        const { smeId, phone, email, streetAddress, zipCode, city, bankAccount } = action.payload;
        const companyInfoUpdatePayload = {
            smeId,
            phone,
            email,
            streetAddress,
            zipCode,
            city,
        };

        const accountInfoUpdatePayload: T_UpdateBankAccountPayload = {
            accountId: account.account.id,
            number: bankAccount,
            type: "IBAN",
        };

        const oldCompanyData = {
            phone: customer.companyInfo.info.phone,
            email: customer.companyInfo.info.email,
            streetAddress: customer.companyInfo.info.officialAddress.streetAddress,
            zipCode: customer.companyInfo.info.officialAddress.zipCode,
            city: customer.companyInfo.info.officialAddress.city,
        };
        const oldBankAccount =
            customer.companyInfo.accounts.accounts[0].disbursementAccount?.externalAccountNumber;
        const newBankAccount = bankAccount;
        const newCompanyData = { phone, email, streetAddress, zipCode, city };

        if (JSON.stringify(newCompanyData) !== JSON.stringify(oldCompanyData)) {
            yield put(companyActions.updateCompanyInfoTrigger(companyInfoUpdatePayload));
        }
        if (oldBankAccount !== newBankAccount) {
            yield put(companyActions.updateBankAccountNumberTrigger(accountInfoUpdatePayload));
        }
    } catch (e) {
        throw new Error("User info update failed: " + e);
    }
}
