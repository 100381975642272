import { createReducer } from "typesafe-actions";
import { produce } from "immer";

import { CustomerReducerState } from "../types/general";
import { CustomerAction, customerActions } from "../actions/customer";

export const initialState: CustomerReducerState = {
    user: null,
    account: null,
    revolvingAccount: null,
    productType: null,
};

export const customerReducer = createReducer<CustomerReducerState, CustomerAction>(initialState)
    .handleAction(customerActions.customerInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.productType = action.payload.productType;
        });
    })
    .handleAction(customerActions.fetchCustomerSuccess, (state, action) => {
        return produce(state, (draftState) => {
            draftState.user = action.payload;
        });
    })
    .handleAction(customerActions.setSelectedAccount, (state, action) => {
        return produce(state, (draftState) => {
            draftState.account = action.payload;
        });
    })
    .handleAction(customerActions.setRevolvingAccount, (state, action) => {
        return produce(state, (draftState) => {
            draftState.revolvingAccount = action.payload;
        });
    });
