import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { Font } from "@opr-finance/component-fonts";
import { currency, CurrencyFormat, CurrencyLocale } from "@opr-finance/component-currency";
import { StyledGrid } from "@opr-finance/component-grid";
import { DateFormat, DateFormatter } from "@opr-finance/component-date";

import {
    T_SmeLoan_AccountContentProps,
    T_SmeLoan_AccountTranslationProps,
    T_SmeLoan_AccountStyleProps,
    T_SmeLoan_CurrencyCountry,
} from "./types";
import { T_SmeLoan_FeatureAccountState } from "../types";

export const AccountContent = styled.div<T_SmeLoan_AccountContentProps>`
    height: 14px;
    width: ${(props) => props.percentage}%;
    margin: 0;
    padding: 0;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    background-color: #66aadf;
    border-radius: 8px;
    overflow: hidden;
`;

export function Account(
    props: T_SmeLoan_AccountTranslationProps &
        T_SmeLoan_AccountStyleProps &
        T_SmeLoan_CurrencyCountry
) {
    const account = useSelector((state: T_SmeLoan_FeatureAccountState) => {
        return state.account.account;
    });

    const formatCurrency = (value) => {
        return currency({
            value,
            locale: CurrencyLocale[props.country],
            currency: CurrencyFormat[props.country],
            style: "currency",
        });
    };

    let percentage = 0;

    if (account) {
        const { creditLimit, availableCreditLimit } = account;

        if (typeof creditLimit !== "undefined" && typeof availableCreditLimit !== "undefined") {
            percentage = ((creditLimit - availableCreditLimit) / creditLimit) * 100;
        }
    }

    return (
        <StyledGrid
            styleConfig={{
                root: props.styleConfig.balanceContainer,
            }}>
            <Font
                as="p"
                styleConfig={{
                    root: props.styleConfig.title,
                }}>
                {props.messages.title}{" "}
                <DateFormatter date={new Date().toISOString()} format={DateFormat[props.country]} />
            </Font>
            <StyledGrid
                styleConfig={{
                    root: props.styleConfig.remainingCreditWrapper,
                }}>
                <Font styleConfig={{ root: props.styleConfig.remainingCreditText }}>
                    {props.messages.availableText}
                </Font>
                <Font as="p" styleConfig={{ root: props.styleConfig.remainingCreditAmount }}>
                    {formatCurrency(account?.availableCreditLimit)}
                </Font>{" "}
            </StyledGrid>
            <StyledGrid styleConfig={{ root: props.styleConfig.percentageContainer }}>
                <AccountContent percentage={percentage} />
            </StyledGrid>
            <StyledGrid styleConfig={{ root: props.styleConfig.creditWrapper }}>
                <StyledGrid styleConfig={{ root: props.styleConfig.creditUsed }}>
                    <Font styleConfig={{ root: props.styleConfig.creditText }} as="p">
                        {props.messages.withdrawnText}
                    </Font>
                    <Font styleConfig={{ root: props.styleConfig.creditText }} as="p">
                        {formatCurrency(account?.remainingPrincipal)}
                    </Font>
                </StyledGrid>
                <StyledGrid styleConfig={{ root: props.styleConfig.creditTotal }}>
                    <Font styleConfig={{ root: props.styleConfig.creditText }} as="p">
                        {props.messages.creditLimitText}
                    </Font>
                    <Font styleConfig={{ root: props.styleConfig.creditText }} as="p">
                        {formatCurrency(account?.creditLimit)}
                    </Font>
                </StyledGrid>
            </StyledGrid>
            <Font as="p" styleConfig={{ root: props.styleConfig.balanceInfo }}>
                {props.messages.infoText}
            </Font>
        </StyledGrid>
    );
}
