export enum CurrencyLocale {
    Sweden = "sv-SE",
    Finland = "fi-FI",
    Netherlands = "nl-NL",
}

export enum CurrencyFormat {
    Sweden = "SEK",
    Finland = "EUR",
    Netherlands = "EUR",
}

export enum E_CurrencyCountry {
    Sweden = "Sweden",
    Finland = "Finland",
    Netherlands = "Netherlands",
}
