import { FeatureLoginState } from "@opr-finance/feature-lfp-login";
import { EntryFields, CreateClientParams } from "contentful";
import { FeatureLoaderState } from "@opr-finance/feature-loader";

/** Contentful */
export type ContentfulParams<T> = {
    clientParams: CreateClientParams;
    contentParams: T;
};

export enum ContentfulActionConstants {
    FETCH_NEWS_TRIGGER = "NEWS/FETCH_TRIGGER",
    FETCH_NEWS_SUCCESS = "NEWS/FETCH_SUCCESS",
    FETCH_NEWS_ERROR = "NEWS/FETCH_ERROR",
    FETCH_NOTICES_TRIGGER = "NOTICES/FETCH_TRIGGER",
    FETCH_NOTICES_SUCCESS = "NOTICES/FETCH_SUCCESS",
    FETCH_NOTICES_ERROR = "NOTICES/FETCH_ERROR",
    FETCH_TRANSLATION_TRIGGER = "TRANSLATION/FETCH_TRIGGER",
    FETCH_TRANSLATION_SUCCESS = "TRANSLATION/FETCH_SUCCESS",
    FETCH_TRANSLATION_ERROR = "TRANSLATION/FETCH_ERROR",
    FETCH_TRANSLATION_STATIC_CONTENT_TRIGGER = "TRANSLATION/STATIC_CONTENT/FETCH_TRIGGER",
    FETCH_TRANSLATION_STATIC_CONTENT_SUCCESS = "TRANSLATION/STATIC_CONTENT/FETCH_SUCCESS",
    FETCH_TRANSLATION_STATIC_CONTENT_ERROR = "TRANSLATION/STATIC_CONTENT/FETCH_ERROR",
}

export type languages = "fi" | "sv" | "nl";

export type selectFields = "fields" | "fields,sys.createdAt";

/** Recent news */
export enum RecentNewsItemConf {
    INGRESS_MAX_LENGTH = 97,
    DEFAULT_FORMAT_DATE = "dd'.'MM'.'yyyy",
}

export type RecentNewsContentfulConfig = {
    select: selectFields;
    content_type: "recentNews";
    "fields.language[in]": languages;
    "fields.application[in]": string;
};

export type RecentNewsFields = {
    application: EntryFields.Symbol;
    language: EntryFields.Symbol;
    headline: EntryFields.Symbol;
    content: EntryFields.Text;
    dueDate: EntryFields.Date;
    ingress: string;
    createdDate: string;
};

export type RecentNewsType = {
    fields: RecentNewsFields;
    sys: {
        createdAt: EntryFields.Date;
    };
};

export type RecentNewsReducerState = {
    news: RecentNewsFields[] | null;
};

/** REFACTOR: This fixes type issues in Flex Online AppState as it needs to use feature-luvittaja-login,
 *  type T is defined as the LoginState being used, by default it is FeatureLoginState from feature-lfp-login
 *  todo: check if login state can be completely removed from this type
 */
export type FeatureNewsState<T = FeatureLoginState> = {
    news: RecentNewsReducerState;
} & T;

//Notices
export enum NoticesItemConf {
    INGRESS_MAX_LENGTH = 97,
    DEFAULT_FORMAT_DATE = "dd'.'MM'.'yyyy",
}

export type NoticesContentfulConfig = {
    select: selectFields;
    content_type: "staticContentNotice";
    "fields.application[in]": string;
};

export type NoticesFields = {
    application: EntryFields.Symbol;
    notice: string;
    label: string;
    active: string;
    createdDate: string;
};

export type NoticesType = {
    fields: NoticesFields;
    sys: {
        createdAt: EntryFields.Date;
    };
};

export type NoticesReducerState = {
    notices: NoticesFields[] | null;
};

export type FeatureNoticesState = {
    notices: NoticesReducerState;
};

/** Translations */
export type TranslationContentfulConfig = {
    select: selectFields;
    content_type: "translationV2";
    limit: number;
    "fields.language[in]": languages;
    "fields.application[in]": string;
};
export type TranslationStaticContentConfig = {
    "fields.slug[in]": string;
    content_type: "staticContent";
};

export type TranslationV2Fields = {
    application: EntryFields.Symbol;
    language: EntryFields.Symbol;
    translationKey: EntryFields.Symbol;
    translationValue: EntryFields.Symbol;
};

export type TranslationV2Type = {
    fields: TranslationV2Fields;
    sys: {
        type: string;
    };
};

export interface translationMap<T> {
    [key: string]: T;
}

export type TranslationV2ReducerState = {
    translation: TranslationV2Type[] | null;
    messages: translationMap<string> | undefined;
};

export type TranslationStaticContentReducerState = {
    translation: translationMap<string> | null;
    messages: translationMap<string> | undefined;
};

/** REFACTOR: This fixes type issues in Flex Online AppState as it needs to use feature-luvittaja-login,
 *  type T is defined as the LoginState being used, by default it is FeatureLoginState from feature-lfp-login
 *  todo: check if login state can be completely removed from this type
 */
export type FeatureTranslationV2State<T = FeatureLoginState> = {
    translation: TranslationV2ReducerState;
} & T &
    FeatureLoaderState;

export type FeatureTranslationStaticContentState<T = FeatureLoginState> = {
    translation: TranslationStaticContentReducerState;
} & T &
    FeatureLoaderState;
