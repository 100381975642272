import { takeEvery, put, call } from "redux-saga/effects";

import { loaderActions } from "@opr-finance/feature-loader";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { translationActions } from "../actions/translation";
import * as translationApi from "../api/translation";
import { ContentfulActionConstants, TranslationV2Type } from "../types/contentful";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* handleFetchTranslationTrigger(
    action: ReturnType<typeof translationActions.fetchTranslationTrigger>
) {
    try {
        const translation: TranslationV2Type[] = yield call(translationApi.getTranslation, {
            contentParams: action.payload.contentParams,
            clientParams: action.payload.clientParams,
        });

        yield put(translationActions.fetchTranslationSuccess(translation));
        yield put(
            loaderActions.loaderTrigger({
                loading: false,
            })
        );
    } catch (e) {
        logger.log("FEtc translations failed!");
    }
}

export function* watchFetchTranslationTrigger() {
    yield takeEvery(
        ContentfulActionConstants.FETCH_TRANSLATION_TRIGGER,
        handleFetchTranslationTrigger
    );
}
