import { call, put, takeEvery, select } from "redux-saga/effects";
import { smeDocumentActions } from "../actions";
import { fetchDocument } from "../api";
import { ActionType } from "typesafe-actions";
import { smeDownloadDocument } from "../component";

export function* handleSmeDocumentFetch(
    action: ActionType<typeof smeDocumentActions.smeFetchDocumentTrigger>
) {
    try {
        const { token, mockApiCalls, gwUrl, documentId, accountId } = action.payload;
        const fileContent = yield call(fetchDocument, {
            token,
            documentId,
            accountId,
            gwUrl,
            mockApiCalls,
        });

        const documentUrl = window.URL.createObjectURL(fileContent);

        smeDownloadDocument(documentUrl);
    } catch (e) {}
}

// export function* handleSmeDocumentInitialize(
//     action: ActionType<typeof smeDocumentActions.smeFetchPromissoryNoteIdInitializer>
// ) {
//     try {
//         const { token, mockApiCalls, gwUrl, activeAccountId } = action.payload;
//         const activeAccount = yield select((state: T_DocumentReducerState)=> state.)
//     } catch (e) {}
// }

// export function* watchSmeDocumentInitialize() {
//     yield takeEvery(
//         smeDocumentActions.smeFetchPromissoryNoteIdInitializer,
//         handleSmeDocumentInitialize
//     );
// }

export function* watchSmeDocumentFetch() {
    yield takeEvery(smeDocumentActions.smeFetchDocumentTrigger, handleSmeDocumentFetch);
}
