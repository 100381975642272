import { contentfulClient } from "./contentfulClient";
import { RecentNewsType, ContentfulParams, RecentNewsContentfulConfig } from "../types/contentful";
import { format, isBefore, parseISO } from "date-fns";

export const getRecentNews = async (
    data: ContentfulParams<RecentNewsContentfulConfig>
): Promise<RecentNewsType[]> => {
    const items = (await contentfulClient(data.clientParams)
        .getEntries(data.contentParams)
        .then((response) => response.items)) as RecentNewsType[];
    const now: string = format(new Date(), "yyyy-MM-dd'T'HH:mmxxx");
    const availableNews: RecentNewsType[] = items.filter((item) =>
        isBefore(parseISO(now), parseISO(item.fields.dueDate))
    );

    return availableNews;
};
