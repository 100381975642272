import { createReducer } from "typesafe-actions";
import { produce } from "immer";

import { SmeDocumentAction, smeDocumentActions } from "../actions";
import { T_DocumentReducerState, E_DocumentDownloadStatus } from "../types";

const initalState = {
    document: null,
    documentId: undefined,
    config: {
        gwUrl: "",
        token: "",
        mockApiCalls: false,
        activeAccountId: "",
        defaultPromissoryNoteId: "",
    },
};
export const smeDocumentReducer = createReducer<T_DocumentReducerState, SmeDocumentAction>(
    initalState
).handleAction(smeDocumentActions.smeFetchPromissoryNoteIdInitializer, (state, action) => {
    return produce(state, (drafState) => {
        drafState.config = action.payload;
    });
});
