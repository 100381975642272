import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { FeatureLoginState } from "../types/general";

export type AuthenticationProps = {
    children: ReactNode;
};

export function Authenticator(props: AuthenticationProps) {
    const { isValidating, isValidToken, isInitialized } = useSelector(
        (state: FeatureLoginState) => {
            return {
                isValidating: state.login.isValidating,
                isValidToken: state.login.isValidToken,
                isInitialized: state.login.isInitialized,
            };
        }
    );

    if (isValidating && !isInitialized) {
        return <div>Is validating...</div>;
    }

    return <>{props.children}</>;
}
