import * as VP from "@opr-finance/api-definitions";
import { FeatureLoginState } from "@opr-finance/feature-lfp-login";
import { DefaultInitializerType } from "@opr-finance/utils";

export enum StatementsActionConstants {
    FETCH_STATEMENTS_INITIALIZER = "STATEMENTS/INITIALIZER",
    FETCH_STATEMENTS_TRIGGER = "STATEMENTS/FETCH_STATEMENTS_TRIGGER",
    FETCH_STATEMENTS_SUCCESS = "STATEMENTS/FETCH_STATEMENTS_SUCCESS",
    FETCH_STATEMENTS_ERROR = "STATEMENTS/FETCH_STATEMENTS_ERROR",
}

export enum StatementsItemConf {
    DEFAULT_FORMAT_DATE = "dd'.'MM'.'yyyy",
}

/** First implementation - this should be tied to VP's StatementStatuses type... somehow
 *
 * export type StatementStatus =
 * | "PENDING"
 * | "UNPAID"
 * | "PARTIALLYPAID"
 * | "PAIDINFULL"
 * | "OVERDUE"
 * | "COLLECTION"
 * | "CURING";
 */
export enum StatemnetStatuses {
    PENDING = "PENDING",
    UNPAID = "UNPAID",
    PARTIALLYPAID = "PARTIALLYPAID",
    PAIDINFULL = "PAIDINFULL",
    OVERDUE = "OVERDUE",
    COLLECTION = "COLLECTION",
    CURING = "CURING",
}

export type StatementsMessageData = {
    contentfulKey: string;
    messageId: string;
};

export type StatementsGeneratedMessages = {
    [key: string]: {
        id: string;
    };
};

export type FetchStatementsRequest = {
    accountId: VP.components["parameters"]["accountId"] | undefined;
} & DefaultInitializerType;

export type T_VpStatementV2 = VP.components["schemas"]["StatementV2"];

export interface FormattedStatement extends T_VpStatementV2 {
    title: string;
    amount: string;
    fee: string;
    translatedStatus?: string;
}

export type StatementsInitializerType = DefaultInitializerType & {
    accountId: string | undefined;
};

export type StatementsReducerState = {
    statements: Array<T_VpStatementV2> | [];
    formatted: Array<FormattedStatement> | [];
    config: StatementsInitializerType;
};

/** REFACTOR: This fixes type issues in Flex Online AppState as it needs to use feature-luvittaja-login,
 *  type T is defined as the LoginState being used, by default it is FeatureLoginState from feature-lfp-login
 *  todo: check if login state can be completely removed from this type
 */
export type FeatureStatementsState<T = FeatureLoginState> = {
    statements: StatementsReducerState;
} & T;
