import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { StyledPageTitle } from "@opr-finance/component-content";
import { StyledGrid } from "@opr-finance/component-grid";
import { StartPageStyles } from "@opr-finance/theme-flex-online";
import { loginSessionActions } from "@opr-finance/feature-login-session";
import { AppLoader } from "@opr-finance/feature-loader";
import { FeatureNoticesState, NoticesFields } from "@opr-finance/feature-contentful";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { StartPageProps, T_BankObject } from "./types";
import { BankButton } from "../../components/BankButton/BankButton";
import logoDanske from "../../images/banks/danske.png";
import logoOp from "../../images/banks/op.png";
import logoNordea from "../../images/banks/nordea.png";
import logoHandelsbanken from "../../images/banks/handelsbanken.png";
import logoSpankki from "../../images/banks/spankki.png";
import logoAktia from "../../images/banks/aktia.png";
import logoSaastopankki from "../../images/banks/saastopankki.png";
import logoOmaSp from "../../images/banks/omasp.png";
import logoAlandsbanken from "../../images/banks/alandsbanken.png";
import logoPop from "../../images/banks/pop.png";
import logoMobiilivarmenne from "../../images/banks/mobiilivarmenne.png";
import { Notice } from "../../components/Notice";
import { messages } from "./messages";

export function StartPage(props: StartPageProps) {
    const logger = new ConsoleLogger({ level: LOG_LEVEL });
    const { formatMessage: fm } = useIntl();
    const dispatch = useDispatch();

    const [bankChosen, setBankChosen] = useState<boolean>(false);

    const banks: Array<T_BankObject> = [
        {
            authId: "ftn-danskebank-auth",
            logo: logoDanske,
            logoWidth: "184px",
            logoHeight: "29px",
        },
        {
            authId: "ftn-op-auth",
            logo: logoOp,
            logoWidth: "186px",
            logoHeight: "51px",
        },
        {
            authId: "ftn-nordea-auth",
            logo: logoNordea,
            logoWidth: "151px",
            logoHeight: "33px",
        },
        {
            authId: "ftn-handelsbanken-auth",
            logo: logoHandelsbanken,
            logoWidth: "223px",
            logoHeight: "23px",
        },
        {
            authId: "ftn-spankki-auth",
            logo: logoSpankki,
            logoWidth: "147px",
            logoHeight: "32px",
        },
        {
            authId: "ftn-aktia-auth",
            logo: logoAktia,
            logoWidth: "105px",
            logoHeight: "35px",
        },
        {
            authId: "ftn-sp-auth",
            logo: logoSaastopankki,
            logoWidth: "204px",
            logoHeight: "37px",
        },
        {
            authId: "ftn-omasp-auth",
            logo: logoOmaSp,
            logoWidth: "112px",
            logoHeight: "51px",
        },
        {
            authId: "ftn-alandsbanken-auth",
            logo: logoAlandsbanken,
            logoWidth: "170px",
            logoHeight: "13px",
        },
        {
            authId: "ftn-pop-auth",
            logo: logoPop,
            logoWidth: "165px",
            logoHeight: "54px",
        },
        {
            authId: "mobiilivarmenne-ftn",
            logo: logoMobiilivarmenne,
            logoWidth: "133px",
            logoHeight: "91px",
        },
    ];

    function handleClick(bank) {
        logger.log(bank);
        dispatch(loginSessionActions.loginSessionTrigger({ bank }));
        setBankChosen(true);
    }

    const { notices } = useSelector((state: FeatureNoticesState) => state.notices);

    return (
        <StyledGrid styleConfig={{ root: StartPageStyles.startPageRootStyles() }}>
            {notices &&
                notices.length > 0 &&
                notices.map((notice: NoticesFields, index) => (
                    <StyledGrid
                        styleConfig={{ root: StartPageStyles.startPageNoticeListContainer() }}
                        key={`notice-${index}`}>
                        <Notice
                            notice={notice.notice}
                            styleConfig={{
                                noticeContainer: StartPageStyles.startPageNoticeContainer({
                                    label: notice.label,
                                }),
                                notice: StartPageStyles.startPageNotice(),
                            }}
                        />
                    </StyledGrid>
                ))}
            <StyledPageTitle
                title={fm(messages.pageTitle)}
                styleConfig={{
                    pageTitleContainer: props.styleConfig.titleBox,
                    pageTitleText: props.styleConfig.pageTitle,
                }}
            />
            {bankChosen ? (
                <AppLoader />
            ) : (
                <StyledGrid styleConfig={{ root: StartPageStyles.startPageContainer() }}>
                    <StyledGrid styleConfig={{ root: StartPageStyles.startPageContentStyles() }}>
                        {banks.map((bank, index) => (
                            <BankButton
                                styleConfig={{
                                    buttonContainer: StartPageStyles.startPageBank(),
                                    bankContainer: StartPageStyles.startPageBankContainer({
                                        url: bank.logo,
                                        width: bank.logoWidth,
                                        height: bank.logoHeight,
                                    }),
                                }}
                                key={`item-${index}`}
                                onClick={() => handleClick(bank.authId)}
                            />
                        ))}
                    </StyledGrid>
                </StyledGrid>
            )}
        </StyledGrid>
    );
}
