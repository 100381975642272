import { createReducer } from "typesafe-actions";
import { produce } from "immer";

import { UserReducerState } from "../types/general";
import { UserLoginAction, userLoginActions } from "../actions/login";

export const initialState: UserReducerState = {
    token: null,
    tokenData: null,
    isInitialized: false,
    isValidating: false,
    isValidToken: false,
    loginUrl: "",
    verifyUrl: "",
    appBaseUrl: "",
    verifyApiRequestMethod: "POST",
    errorUrl: "",
    successUrl: "",
    logoutUrl: "",
    mockLogin: false,
};

export const userReducer = createReducer<UserReducerState, UserLoginAction>(initialState)
    .handleAction(userLoginActions.loginInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.loginUrl = action.payload.loginUrl;
            draftState.verifyUrl = action.payload.verifyUrl;
            draftState.appBaseUrl = action.payload.appBaseUrl;
            draftState.verifyApiRequestMethod = action.payload.verifyApiRequestMethod;
            draftState.errorUrl = action.payload.errorUrl;
            draftState.successUrl = action.payload.successUrl;
            draftState.logoutUrl = action.payload.logoutUrl;
            draftState.mockLogin = action.payload.mockLogin;
        });
    })
    .handleAction(userLoginActions.updateIsValidatingToken, (state, action) => {
        return produce(state, (draftState) => {
            draftState.isValidating = action.payload;
        });
    })
    .handleAction(userLoginActions.updateToken, (state, action) => {
        return produce(state, (draftState) => {
            draftState.token = action.payload;
        });
    })
    .handleAction(userLoginActions.updateIsValidToken, (state, action) => {
        return produce(state, (draftState) => {
            draftState.isValidToken = action.payload;
        });
    })
    .handleAction(userLoginActions.updateIsInitialized, (state, action) => {
        return produce(state, (draftState) => {
            draftState.isInitialized = action.payload;
        });
    })
    .handleAction(userLoginActions.updateTokenData, (state, action) => {
        return produce(state, (draftState) => {
            draftState.tokenData = action.payload;
        });
    });
