import { DefaultInitializerType } from "@opr-finance/utils";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";
import { T_YearlyOverviewDataNl, T_YearlyOverviewDataFi, T_YearlyOverviewDataSe } from "../types";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export type T_CountryProductId =
    | "netherlands-flex-online"
    | "finland-flex-online"
    | "sweden-flex-online";

export type T_ReportingRequest = {
    cid: T_CountryProductId;
    accountNumber: string | undefined;
    reportingEndDate: string;
} & DefaultInitializerType;

type T_ReportingData<T extends T_CountryProductId> = {
    "netherlands-flex-online": T_YearlyOverviewDataNl;
    "finland-flex-online": T_YearlyOverviewDataFi;
    "sweden-flex-online": T_YearlyOverviewDataSe;
}[T];

function addParameter(url: string, key: string, value: string | number) {
    const separator = url.indexOf("?") !== -1 ? "&" : "?";
    return `${url}${separator}${key}=${value}`;
}

export async function fetchReport<T extends T_CountryProductId>(
    data: T_ReportingRequest
): Promise<T_ReportingData<T>> {
    if (
        data.cid === "netherlands-flex-online" ||
        data.cid === "finland-flex-online" ||
        data.cid === "sweden-flex-online"
    ) {
        let url = `${data.gwUrl}/reporting/${data.cid}/${data.accountNumber}`;
        const method = "GET";

        if (data.cid === "netherlands-flex-online" && data.reportingEndDate)
            url = addParameter(url, "year", data.reportingEndDate.slice(0, 4));
        else {
            url = addParameter(url, "enddate", data.reportingEndDate);
        }
        const result: Response = await fetch(url, {
            method,
            headers: {
                "content-type": "application/json",
                authorization: data.token as string,
            },
        });
        if (result.status === 200) {
            const response = await result.json();
            return response as T_ReportingData<T>;
        } else {
            throw new Error("Failed to get report from reporting api with status:" + result.status);
        }
    }
    throw new Error(`product ${data.cid} is not supported`);
}
