import * as VP from "@opr-finance/api-definitions";
import { httpFaker, DefaultInitializerType } from "@opr-finance/utils";
import { FetchStatementsRequest, T_VpStatementV2 } from "../types/statements";

export async function fetchStatements(
    data: FetchStatementsRequest
): Promise<Array<T_VpStatementV2>> {
    const url = `${data.gwUrl}/api/revolving/v2/accounts/${data.accountId}/statements`;
    const method = "GET";

    /*
    45e476d2-075f-43d5-8ed1-56bc93e6518e
    cf03ae57-aa8e-476c-b7ba-a8a598605207
    361ccbbc-acea-47a9-b542-58eaea92e82f
    51596433-3dec-47a2-9bbe-803328627f20
    75a60353-d00f-486e-8c24-a9070e539385
    51bc8b94-06be-42c0-8a29-1e471c664737
    */

    if (data.mockApiCalls) {
        const normalStatements: Array<T_VpStatementV2> = [
            {
                seriesNum: undefined,
                dueDate: undefined,
                paidInFullDate: undefined,
                overdueDays: undefined,
                statementAmount: undefined,
                paidAmount: 0,
                unpaidAmount: undefined,
                statementType: "PENDING",
                statementFee: undefined,
                paymentMethod: "OCR",
                status: "PENDING",
                createdDate: "2020-09-09 15:20:06",
                documentId: undefined,
            },
            {
                seriesNum: "1003",
                dueDate: "2020-09-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 9.0,
                statementAmount: 3.6e2,
                paidAmount: 0,
                unpaidAmount: 3.6e2,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "UNPAID",
                createdDate: "2020-09-09 15:20:06",
                documentId: "82bbb5759ede4bdb9c6ed1ba73a408fe",
            },
            {
                seriesNum: "1002",
                dueDate: "2020-08-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 10.0,
                statementAmount: 241,
                paidAmount: 0,
                unpaidAmount: 241,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "OVERDUE",
                createdDate: "2020-08-15 03:00:02",
                documentId: "efb8371f7b98456aa13fd743628ce20e",
            },
            {
                seriesNum: "1001",
                dueDate: "2020-07-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 10.0,
                statementAmount: 241,
                paidAmount: 200,
                unpaidAmount: 41,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PARTIALLYPAID",
                createdDate: "2020-07-15 03:00:01",
                documentId: "5fab154b56424bc089b82e926f3b6c8a",
            },
            {
                seriesNum: "1000",
                dueDate: "2020-06-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 9.0,
                statementAmount: 684,
                paidAmount: 0,
                unpaidAmount: 684,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PAIDINFULL",
                createdDate: "2020-06-16 16:20:14",
                documentId: "1cc997cbe04b4473a02b2d998e7ffa03",
            },
        ];

        const allPaidStatements: Array<T_VpStatementV2> = [
            {
                seriesNum: undefined,
                dueDate: undefined,
                paidInFullDate: undefined,
                overdueDays: undefined,
                statementAmount: undefined,
                paidAmount: 0,
                unpaidAmount: undefined,
                statementType: "PENDING",
                statementFee: undefined,
                paymentMethod: "OCR",
                status: "PENDING",
                createdDate: "2020-09-09 15:20:06",
                documentId: undefined,
            },
            {
                seriesNum: "1003",
                dueDate: "2020-09-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 0,
                statementAmount: 3.6e2,
                paidAmount: 0,
                unpaidAmount: 3.6e2,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PAIDINFULL",
                createdDate: "2020-09-09 15:20:06",
                documentId: "82bbb5759ede4bdb9c6ed1ba73a408fe",
            },
            {
                seriesNum: "1002",
                dueDate: "2020-08-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 0,
                statementAmount: 241,
                paidAmount: 0,
                unpaidAmount: 241,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PAIDINFULL",
                createdDate: "2020-08-15 03:00:02",
                documentId: "efb8371f7b98456aa13fd743628ce20e",
            },
            {
                seriesNum: "1001",
                dueDate: "2020-07-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 0,
                statementAmount: 241,
                paidAmount: 200,
                unpaidAmount: 41,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PAIDINFULL",
                createdDate: "2020-07-15 03:00:01",
                documentId: "5fab154b56424bc089b82e926f3b6c8a",
            },
            {
                seriesNum: "1000",
                dueDate: "2020-06-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 0,
                statementAmount: 684,
                paidAmount: 0,
                unpaidAmount: 684,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PAIDINFULL",
                createdDate: "2020-06-16 16:20:14",
                documentId: "1cc997cbe04b4473a02b2d998e7ffa03",
            },
        ];

        const rescoringCleanStatements: Array<T_VpStatementV2> = [
            {
                seriesNum: undefined,
                dueDate: undefined,
                paidInFullDate: undefined,
                overdueDays: undefined,
                statementAmount: undefined,
                paidAmount: 0,
                unpaidAmount: undefined,
                statementType: "PENDING",
                statementFee: undefined,
                paymentMethod: "OCR",
                status: "PENDING",
                createdDate: "2020-09-09 15:20:06",
                documentId: undefined,
            },
            {
                seriesNum: "1003",
                dueDate: "2020-09-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 0,
                statementAmount: 3.6e2,
                paidAmount: 0,
                unpaidAmount: 3.6e2,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "UNPAID",
                createdDate: "2020-09-09 15:20:06",
                documentId: "82bbb5759ede4bdb9c6ed1ba73a408fe",
            },
            {
                seriesNum: "1002",
                dueDate: "2020-08-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 0,
                statementAmount: 241,
                paidAmount: 0,
                unpaidAmount: 241,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PAIDINFULL",
                createdDate: "2020-08-15 03:00:02",
                documentId: "efb8371f7b98456aa13fd743628ce20e",
            },
            {
                seriesNum: "1001",
                dueDate: "2020-07-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 0,
                statementAmount: 241,
                paidAmount: 200,
                unpaidAmount: 41,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PAIDINFULL",
                createdDate: "2020-07-15 03:00:01",
                documentId: "5fab154b56424bc089b82e926f3b6c8a",
            },
            {
                seriesNum: "1000",
                dueDate: "2020-06-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 0,
                statementAmount: 684,
                paidAmount: 0,
                unpaidAmount: 684,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PAIDINFULL",
                createdDate: "2020-06-16 16:20:14",
                documentId: "1cc997cbe04b4473a02b2d998e7ffa03",
            },
            {
                seriesNum: "999",
                dueDate: "2020-06-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 1,
                statementAmount: 684,
                paidAmount: 0,
                unpaidAmount: 684,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PAIDINFULL",
                createdDate: "2020-06-16 16:20:14",
                documentId: "1cc997cbe04b4473a02b2d998e7ffa03",
            },
        ];

        const rescoringOverdueDayStatements: Array<T_VpStatementV2> = [
            {
                seriesNum: undefined,
                dueDate: undefined,
                paidInFullDate: undefined,
                overdueDays: undefined,
                statementAmount: undefined,
                paidAmount: 0,
                unpaidAmount: undefined,
                statementType: "PENDING",
                statementFee: undefined,
                paymentMethod: "OCR",
                status: "PENDING",
                createdDate: "2020-09-09 15:20:06",
                documentId: undefined,
            },
            {
                seriesNum: "1003",
                dueDate: "2020-09-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 0,
                statementAmount: 3.6e2,
                paidAmount: 0,
                unpaidAmount: 3.6e2,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "UNPAID",
                createdDate: "2020-09-09 15:20:06",
                documentId: "82bbb5759ede4bdb9c6ed1ba73a408fe",
            },
            {
                seriesNum: "1002",
                dueDate: "2020-08-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 1.0,
                statementAmount: 241,
                paidAmount: 0,
                unpaidAmount: 241,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PAIDINFULL",
                createdDate: "2020-08-15 03:00:02",
                documentId: "efb8371f7b98456aa13fd743628ce20e",
            },
            {
                seriesNum: "1001",
                dueDate: "2020-07-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 0,
                statementAmount: 241,
                paidAmount: 200,
                unpaidAmount: 41,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PAIDINFULL",
                createdDate: "2020-07-15 03:00:01",
                documentId: "5fab154b56424bc089b82e926f3b6c8a",
            },
            {
                seriesNum: "1000",
                dueDate: "2020-06-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 9.0,
                statementAmount: 684,
                paidAmount: 0,
                unpaidAmount: 684,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PAIDINFULL",
                createdDate: "2020-06-16 16:20:14",
                documentId: "1cc997cbe04b4473a02b2d998e7ffa03",
            },
        ];

        const rescoringOverdueStatements: Array<T_VpStatementV2> = [
            {
                seriesNum: undefined,
                dueDate: undefined,
                paidInFullDate: undefined,
                overdueDays: undefined,
                statementAmount: undefined,
                paidAmount: 0,
                unpaidAmount: undefined,
                statementType: "PENDING",
                statementFee: undefined,
                paymentMethod: "OCR",
                status: "PENDING",
                createdDate: "2020-09-09 15:20:06",
                documentId: undefined,
            },
            {
                seriesNum: "1003",
                dueDate: "2020-09-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 0,
                statementAmount: 3.6e2,
                paidAmount: 0,
                unpaidAmount: 3.6e2,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "UNPAID",
                createdDate: "2020-09-09 15:20:06",
                documentId: "82bbb5759ede4bdb9c6ed1ba73a408fe",
            },
            {
                seriesNum: "1002",
                dueDate: "2020-08-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 1.0,
                statementAmount: 241,
                paidAmount: 0,
                unpaidAmount: 241,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "OVERDUE",
                createdDate: "2020-08-15 03:00:02",
                documentId: "efb8371f7b98456aa13fd743628ce20e",
            },
            {
                seriesNum: "1001",
                dueDate: "2020-07-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 0,
                statementAmount: 241,
                paidAmount: 200,
                unpaidAmount: 41,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PAIDINFULL",
                createdDate: "2020-07-15 03:00:01",
                documentId: "5fab154b56424bc089b82e926f3b6c8a",
            },
            {
                seriesNum: "1000",
                dueDate: "2020-06-28 23:59:59",
                paidInFullDate: undefined,
                overdueDays: 0,
                statementAmount: 684,
                paidAmount: 0,
                unpaidAmount: 684,
                statementType: "REGULAR",
                statementFee: 39,
                paymentMethod: "OCR",
                status: "PAIDINFULL",
                createdDate: "2020-06-16 16:20:14",
                documentId: "1cc997cbe04b4473a02b2d998e7ffa03",
            },
        ];

        /* return httpFaker<FetchStatementsRequest, Array<T_VpStatementV2>>(
            url,
            method,
            data,
            normalStatements
        ); */
        /* return httpFaker<FetchStatementsRequest, Array<T_VpStatementV2>>(
            url,
            method,
            data,
            rescoringCleanStatements
        ); */
        /* return httpFaker<FetchStatementsRequest, Array<T_VpStatementV2>>(
            url,
            method,
            data,
            rescoringOverdueDayStatements
        ); */

        return httpFaker<FetchStatementsRequest, Array<T_VpStatementV2>>(
            url,
            method,
            data,
            allPaidStatements
        );

        return httpFaker<FetchStatementsRequest, Array<T_VpStatementV2>>(
            url,
            method,
            data,
            rescoringOverdueStatements
        );
    }

    const result: Response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": data.token as string,
        },
    });

    return result.json();
}
