import { defineMessages } from "react-intl";

export const messages = defineMessages({
    show: {
        id: "flex-online-fi-generic-table-paginator-show",
        defaultMessage: "Translation missing",
    },
    noData: {
        id: "flex-online-fi-generic-table-nodata",
        defaultMessage: "Translation missing",
    },
});
