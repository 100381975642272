import { DefaultInitializerType } from "@opr-finance/utils";

export enum E_DocumentActionConstants {
    FETCH_DOCUMENT_TRIGGER = "DOCUMENT/FETCH_DOCUMENT_TRIGGER",
    FETCH_DOCUMENT_SUCCESS = "DOCUMENT/FETCH_DOCUMENT_SUCCESS",
    FETCH_DOCUMENT_ERROR = "DOCUMENT/FETCH_DOCUMENT_ERROR",
    PROMISSORY_NOTE_ID_INITIALIZER = "DOCUMENT/PROMISSORY_NOTE_ID_INITIALIZER",
}

export enum E_DocumentType {
    PROMISSORY_NOTE = "PROMISSORY_NOTE",
    INVOICE = "invoice",
    POA = "poa",
}

export enum E_DocumentDownloadStatus {
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    ERROR = "ERROR",
}
export type T_DocumentTypes = typeof E_DocumentType[keyof typeof E_DocumentType];

export type T_PromissoryNoteIdInitializer = {
    activeAccountId: string | undefined;
    defaultPromissoryNoteId: string | undefined;
} & DefaultInitializerType;

export type T_PromissoryNoteIdInitialized = {
    documentId: string | undefined;
};

// export type T_DocumentInitializer = DefaultInitializerType;

export type T_DocumentProps = {
    documentId: string | undefined;
    downloadStatus: typeof E_DocumentDownloadStatus[keyof typeof E_DocumentDownloadStatus];
    data?: Blob;
    documentUrl?: string;
};

export type T_DocumentReducerState = {
    document: {
        [key: string]: T_DocumentProps;
    } | null;
    documentId: string | undefined;
    config: T_PromissoryNoteIdInitializer;
};

export type T_FeatureDocumentState = {
    document: T_DocumentReducerState;
};

export type T_FetchDocumentRequest = {
    documentId: string | undefined;
    accountId: string | undefined;
} & DefaultInitializerType;
