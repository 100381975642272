import styled from "styled-components";
import { color } from "styled-system";

export const Container = styled.div`
    display: flex;
    align-items: row;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    padding-top: 15px;
`;

export const PaginatorContainer = styled.div`
    display: flex;
`;

export const ButtonLeft = styled.div`
    display: flex;
    width: 32px;
    height: 30px;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    color: #fff;
    background: linear-gradient(
        0deg,
        rgba(79, 169, 60, 1) 0%,
        rgba(133, 184, 117, 1.3) 50%,
        rgba(79, 169, 60, 1) 100%
    );
    &:hover {
        background: linear-gradient(
            0deg,
            rgba(141, 199, 126, 1) 0%,
            rgba(174, 222, 138, 1) 50%,
            rgba(141, 199, 126, 1) 100%
        );
        cursor: pointer;
    }
    &:active {
        background: linear-gradient(
            0deg,
            rgba(109, 185, 104, 1) 0%,
            rgba(142, 209, 119, 1.35) 50%,
            rgba(109, 185, 104, 1) 100%
        );
        cursor: pointer;
    }
    & > svg {
        position: relative;
        left: -1px;
    }
`;

export const ButtonRight = styled(ButtonLeft)`
    margin-right: 16px;

    & > svg {
        position: relative;
        left: 1px;
    }
`;

export const Current = styled.div`
    color: #124890;
    display: flex;
    width: 35px;
    height: 30px;
    align-items: center;
    justify-content: center;
`;

export const Select = styled.div`
    color: #124890;
    display: flex;
    flex-direction: column;
    min-height: 40px;
    padding-bottom: 4px;
    width: 100px;
`;

export const Selected = styled.div`
    color: #124890;
    display: flex;
    flex-direction: row;
    border: 1px solid #e0e0e0;
    background-color: #ffffff;
    height: 31px;
    width: 100%;
    padding: 5px 10px;
    margin: 0;
    cursor: pointer;
`;

export const SelectedLabel = styled.div`
    display: flex;
    flex: 1;
`;

export const SelectedIcon = styled.div`
    display: flex;
    width: 10px;
    height: 30px;
`;

export const Options = styled.div`
    margin-top: 16px;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    height: 100%;
    width: 100%;
`;

export const Option = styled.div`
    display: flex;
    flex-direction: row;
    height: 30px;
    text-align: center;
    padding: 8px;
    background-color: #ffffff;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0;
    &:last-of-type {
        border-radius: 4px;
    }
`;
