import { contentfulClient } from "./contentfulClient";
import { NoticesType, ContentfulParams, NoticesContentfulConfig } from "../types/contentful";
import { format, isBefore, parseISO } from "date-fns";

export const getNotices = async (
    data: ContentfulParams<NoticesContentfulConfig>
): Promise<NoticesType[]> => {
    const notices = (await contentfulClient(data.clientParams)
        .getEntries(data.contentParams)
        .then((response) => response.items)) as NoticesType[];
    return notices;
};
