import { ActionType, createAction } from "typesafe-actions";

import {
    E_SmeLoan_AccountActionConstants,
    T_SmeLoan_AccountInitializerType,
    T_SmeLoan_AccountIdPayload,
    T_AccountUpdatePayload,
    T_VpRegularLoanAccountForCreationResponse,
} from "../types";

export const smeLoanAccountActions = {
    accountInitializer: createAction(
        E_SmeLoan_AccountActionConstants.ACCOUNT_INIT_FEATURE
    )<T_SmeLoan_AccountInitializerType>(),
    accountSetAccountId: createAction(
        E_SmeLoan_AccountActionConstants.SET_ACCOUNT_ID
    )<T_SmeLoan_AccountIdPayload>(),
    fetchAccountTrigger: createAction(
        E_SmeLoan_AccountActionConstants.FETCH_ACCOUNT_TRIGGER
    )<T_SmeLoan_AccountIdPayload>(),
    fetchAccountSuccess: createAction(
        E_SmeLoan_AccountActionConstants.FETCH_ACCOUNT_SUCCESS
    )<T_VpRegularLoanAccountForCreationResponse>(),
    fetchAccountError: createAction(E_SmeLoan_AccountActionConstants.FETCH_ACCOUNT_ERROR)(),
    updateAccountTrigger: createAction(
        E_SmeLoan_AccountActionConstants.UPDATE_ACCOUNT_TRIGGER
    )<T_AccountUpdatePayload>(),
    updateAccountSuccess: createAction(
        E_SmeLoan_AccountActionConstants.UPDATE_ACCOUNT_SUCCESS
    )<T_AccountUpdatePayload>(),
};

export type SmeLoanAccountAction = ActionType<typeof smeLoanAccountActions>;
