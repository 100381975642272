import { FunctionComponent, ReactNode } from "react";
import styled from "styled-components";
import {
    color,
    border,
    space,
    layout,
    buttonStyle,
    flexbox,
    ColorProps,
    BorderProps,
    SpaceProps,
    LayoutProps,
    ButtonStyleProps,
    FlexboxProps,
    variant,
} from "styled-system";
import { AppThemeProps } from "@opr-finance/themes";
import { css, SystemStyleObject } from "@styled-system/css";

export type BaseButtonProps = ColorProps &
    SpaceProps &
    LayoutProps &
    BorderProps &
    FlexboxProps &
    ButtonStyleProps & {
        onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
        children: ReactNode;
    };
export type BaseButtonPropsWithTheme = BaseButtonProps & AppThemeProps;

export const BaseButton: FunctionComponent<BaseButtonProps> = styled.button<BaseButtonProps>`
    ${color}
    ${border}
    ${space}
    ${layout}
    ${buttonStyle}
    ${flexbox}
    cursor: pointer;
`;

export type StyleBaseButtonProps = {
    children: ReactNode;
    disabled?: boolean;
    styleConfig: {
        root: SystemStyleObject;
    };
} & StyleBaseButtonConfigProps;

type StyleBaseButtonConfigProps = {
    type?: string;
    name?: string;
    onClick?: (e: React.ChangeEvent<HTMLButtonElement>) => void;
    children: ReactNode;
    styleConfig: SystemStyleObject;
};

type StyleBaseButtonRootProps = StyleBaseButtonConfigProps & SystemStyleObject;

export const StyleBaseButton: FunctionComponent<StyleBaseButtonRootProps> = styled.button<StyleBaseButtonRootProps>`
    ${(props: StyleBaseButtonRootProps) => {
        return css(props.styleConfig);
    }}
`;
