import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { translationActions, TranslationAction } from "../actions/translation";
import { TranslationV2ReducerState, TranslationV2Type } from "../types/contentful";

export const initialState = {
    translation: null,
    messages: undefined,
};

const arrayToObject = (array: TranslationV2Type[], keyField: string, keyValue: string) =>
    array.reduce((obj, item) => {
        obj[item.fields[keyField]] = item.fields[keyValue];
        return obj;
    }, {});

export const translationReducer = createReducer<TranslationV2ReducerState, TranslationAction>(
    initialState
).handleAction(translationActions.fetchTranslationSuccess, (state, action) => {
    return produce(state, (draftState) => {
        const messages = arrayToObject(action.payload, "translationKey", "translationValue");
        draftState.translation = action.payload;
        draftState.messages = messages;
    });
});
