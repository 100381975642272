import { takeEvery, put, call, select, race, take } from "redux-saga/effects";

import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { customerActions } from "../actions/customer";
import { activeAccountActions } from "../actions/activeAccount";
import * as customerApi from "../api/customer";
import {
    CustomerActionConstants,
    FeatureLoginState,
    LoginReducerState,
    T_VpUserInformation,
} from "../types/general";
import { loginActions } from "..";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* watchFetchCustomerTrigger() {
    yield takeEvery(CustomerActionConstants.FETCH_CUSTOMER_TRIGGER, handleFetchCustomerTrigger);
}

export function* handleFetchCustomerTrigger() {
    try {
        const config = (yield select((state: FeatureLoginState) => {
            return state.login;
        })) as LoginReducerState;

        const productType = yield select((state: FeatureLoginState) => {
            return state.customer.productType;
        });

        const token = localStorage.getItem("token") as string | null;

        if (!token) {
            return (window.location.href = "/");
        }

        const customer: T_VpUserInformation = yield call(customerApi.fetchCustomer, {
            mockApiCalls: config.mockApiCalls,
            token,
            gwUrl: config.gwUrl,
        });

        if (!customer) {
            return (window.location.href = config.noLoanUrl);
        }
        if (customer.accountList && customer.accountList.length > 0) {
            const activeAccounts = customer.accountList.filter(
                (account) => account.type === productType
            );
            yield put(
                activeAccountActions.selectFromAccounts({
                    accountList: activeAccounts,
                    productType,
                })
            );
            const { success, error } = yield race({
                success: take(activeAccountActions.selectFromAccountsSuccess),
                error: take(activeAccountActions.selectFromAccountsError),
            });

            if (success) {
                yield put(customerActions.fetchCustomerSuccess(customer));
                yield put(loginActions.updateIsInitialized(true));
                yield put(loginActions.loginCompleteSuccess());
            } else {
                window.location.href = config.noLoanUrl;
            }
        } else {
            window.location.href = config.noLoanUrl;
        }
    } catch (e) {
        logger.log("fetch customer trigger failed");
    }
}
