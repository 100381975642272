import { FinnishBusinessIds } from "finnish-business-ids";
import FinnishBankUtils from "finnish-bank-utils";
import { PhoneNumberUtil } from "google-libphonenumber";
const phoneUtil = PhoneNumberUtil.getInstance();

export function isValidFinnishCompanyId(value: string): boolean {
    if (!value) return true;
    return FinnishBusinessIds.isValidBusinessId(value);
}

export function isValidFinnishIban(value: string | undefined): boolean {
    if (!value) return true;
    return FinnishBankUtils.isValidFinnishIBAN(value);
}

export function isValidPhoneNumberFi(value: string | undefined): boolean {
    if (!value) return true;
    const validString = /^[+\d]?([[:space:]]|\d*)*$/g; // plus OR digit on the first position AND digits OR white spaces
    const test = validString.test(value);
    if (test) {
        const number = phoneUtil.parse(value, "FI");
        return phoneUtil.isValidNumberForRegion(number, "FI");
    } else {
        return false;
    }
}
