import { CurrencyLocale, CurrencyFormat } from "./types";

type CurrencyStyle = "currency"; // or add ther styles like "decimal", "percent"

export type CurrencyProps = {
    value: string | number | undefined;
    locale: CurrencyLocale;
    currency: CurrencyFormat;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    style?: CurrencyStyle;
};

export const currency = ({
    value,
    locale,
    currency,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    style = "currency" as CurrencyStyle,
}: CurrencyProps): string => {
    if (typeof value === "undefined") return "Error";
    const parsedValue: number = typeof value === "number" ? value : parseFloat(value);
    return parsedValue.toLocaleString(locale, {
        style,
        currency,
        currencyDisplay: "symbol",
        minimumFractionDigits,
        maximumFractionDigits,
    });
};
