import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "flex-online-fi-AccountListPage-header",
    },
    loanNumber: {
        id: "flex-online-fi-AccountListPage-loan-number-text",
    },
});
