import { E_SmeTransactionsItemConf } from "../types";

export const getDateFormat = (country) => {
    let dateFormat =
        country && E_SmeTransactionsItemConf[country.toUpperCase()]
            ? E_SmeTransactionsItemConf[country.toUpperCase()]
            : E_SmeTransactionsItemConf.DEFAULT_FORMAT_DATE;

    if (!dateFormat) {
        dateFormat = E_SmeTransactionsItemConf.DEFAULT_FORMAT_DATE;
    }

    return dateFormat;
};
