import * as Leandev from "@opr-finance/api-definitions";

import { httpFaker, WithAuthToken, WithMockApiCalls, WithGwUrl } from "@opr-finance/utils";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { T_VpAccount, T_VpRegularLoanAccount, T_VpRevolvingAccount } from "../types/general";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export type FetchAccountRequest = {
    accountId: string | undefined;
    productType: string;
} & WithAuthToken &
    WithMockApiCalls &
    WithGwUrl;

export const getUrlType = (productType: string): string => {
    switch (productType) {
        case "CREDIT_LOAN":
            return "revolving";
        case "REGULAR_LOAN":
            return "loan";
        default:
            return "null";
    }
};

export async function fetchAccount(data: FetchAccountRequest): Promise<T_VpAccount | undefined> {
    const type = getUrlType(data.productType);
    const url = `${data.gwUrl}/api/${type}/v2/accounts/${data.accountId}`;
    const method = "GET";

    if (data.mockApiCalls) {
        const regularLoanAccounts: Array<T_VpRegularLoanAccount> = [
            {
                id: "9800",
                promissoryNoteDocumentId: "601aaf3fdae3d022cc3a4076",
                accountNumber: "7101975",
                state: "COLLECTION",
                createDate: "2021-02-03 15:23:25",
                appliedAmount: 1000,
                remainingPrincipal: 1000,
                remainingTenor: 1,
                interestRate: 0.39,
                mainHolderId: "50213",
                coHolderId: undefined,
                statementChannel: "PAPER",
                product: {
                    id: "1",
                    name: "Fixza",
                    baseInterestRate: 0.39,
                    lateInterestRate: 0,
                    description: "",
                    dueDay: 0,
                    createDate: "2020-11-30 14:01:46",
                    serviceFeeRate: undefined,
                    amountToUseInCalculationServiceFee: "ORIGINAL_AMOUNT",
                    startupFee: 495,
                    ocrFee: 39,
                    directDebitFee: 39,
                    latePaymentFee: 0,
                    collectionFeeStatement: 0,
                    collectionFeeAccount: 0,
                    reminderFee: 60,
                },
                mandateList: [],
                disbursementAccount: {
                    id: "47131",
                    disbursementType: "SE_BG",
                    externalAccountNumber: "83279-9949560693",
                    bankName: "Swedbank",
                },
                monthlyCost: 1032.5,
            },
            {
                id: "24200",
                promissoryNoteDocumentId: "5fe090c3cba7a069e19cacbc",
                accountNumber: "7104870",
                state: "CLOSED",
                createDate: "2020-12-21 13:11:01",
                appliedAmount: 1000,
                remainingPrincipal: 0,
                remainingTenor: 0,
                interestRate: 0.39,
                mainHolderId: "54763",
                coHolderId: undefined,
                statementChannel: "ELECTRONIC_EMAIL_NOTIFICATION",
                product: {
                    id: "1",
                    name: "Fixza",
                    baseInterestRate: 0.39,
                    lateInterestRate: 0,
                    description: "",
                    dueDay: 0,
                    createDate: "2021-01-14 16:24:49",
                    serviceFeeRate: undefined,
                    amountToUseInCalculationServiceFee: "ORIGINAL_AMOUNT",
                    startupFee: 495,
                    ocrFee: 39,
                    directDebitFee: 39,
                    latePaymentFee: 0,
                    collectionFeeStatement: 0,
                    collectionFeeAccount: 0,
                    reminderFee: 60,
                },
                mandateList: [],
                disbursementAccount: {
                    id: "55111",
                    disbursementType: "SE_BG",
                    externalAccountNumber: "81430-2530690730",
                    bankName: "Swedbank",
                },
                monthlyCost: 0,
            },
            {
                id: "69200",
                promissoryNoteDocumentId: "5fe090c3cba7a069e19cacbc",
                accountNumber: "7113988",
                state: "CLOSED",
                createDate: "2020-12-21 13:11:01",
                appliedAmount: 1000,
                remainingPrincipal: 0,
                remainingTenor: 0,
                interestRate: 0.39,
                mainHolderId: "54763",
                coHolderId: undefined,
                statementChannel: "ELECTRONIC_EMAIL_NOTIFICATION",
                product: {
                    id: "1",
                    name: "Fixza",
                    baseInterestRate: 0.39,
                    lateInterestRate: 0,
                    description: "",
                    dueDay: 0,
                    createDate: "2021-01-14 16:24:49",
                    serviceFeeRate: undefined,
                    amountToUseInCalculationServiceFee: "ORIGINAL_AMOUNT",
                    startupFee: 495,
                    ocrFee: 39,
                    directDebitFee: 39,
                    latePaymentFee: 0,
                    collectionFeeStatement: 0,
                    collectionFeeAccount: 0,
                    reminderFee: 60,
                },
                mandateList: [],
                disbursementAccount: {
                    id: "55111",
                    disbursementType: "SE_BG",
                    externalAccountNumber: "81430-2530690730",
                    bankName: "Swedbank",
                },
                monthlyCost: 0,
            },
        ];

        const creaditLoanAccounts: Array<T_VpRevolvingAccount> = [
            {
                id: "6100630",
                promissoryNoteDocumentId: "695a9407a5c74cc6b0b9d8e774b669b3",
                accountNumber: "6100457",
                externalPartyId: undefined,
                state: "OPEN",
                createDate: "2020-05-16 10:44:30",
                nextDueDate: "2020-10-28",
                creditLimit: 5000.0,
                availableCreditLimit: 3990.6544,
                usedCreditLimit: 1009.3456,
                remainingPrincipal: 1009.3456,
                mainHolderId: "50001",
                coHolderId: undefined,
                trancheBlockStatus: "MANUAL",
                product: {
                    id: "5ed89cd93d972612a3693023",
                    name: "Everyday+",
                    baseInterestRate: 0.0325,
                    lateInterestRate: 0.4,
                    description: "",
                    dueDay: 28,
                    created: "2020-09-09 15:18:27",
                    startupFee: 395,
                    ocrFee: 39,
                    directDebitFee: 39,
                },
                mandateList: undefined,
                trancheList: [
                    {
                        id: "1",
                        offerName: undefined,
                        appliedAmount: 1000.0,
                        createDate: "2020-06-16 14:45:19",
                        appliedTenor: undefined,
                        remainingTenor: undefined,
                        signatureDocumentId: undefined,
                    },
                    {
                        id: "51",
                        offerName: undefined,
                        appliedAmount: 1000.0,
                        createDate: "2020-06-16 15:15:01",
                        appliedTenor: undefined,
                        remainingTenor: undefined,
                        signatureDocumentId: undefined,
                    },
                    {
                        id: "101",
                        offerName: undefined,
                        appliedAmount: 1000.0,
                        createDate: "2020-06-16 16:09:14",
                        appliedTenor: undefined,
                        remainingTenor: undefined,
                        signatureDocumentId: undefined,
                    },
                    {
                        id: "151",
                        offerName: undefined,
                        appliedAmount: 1000.0,
                        createDate: "2020-05-16 10:44:30",
                        appliedTenor: undefined,
                        remainingTenor: undefined,
                        signatureDocumentId: undefined,
                    },
                    {
                        id: "301",
                        offerName: undefined,
                        appliedAmount: 1000.0,
                        createDate: "2020-06-18 08:12:09",
                        appliedTenor: undefined,
                        remainingTenor: undefined,
                        signatureDocumentId: undefined,
                    },
                    {
                        id: "551",
                        offerName: undefined,
                        appliedAmount: 1000.0,
                        createDate: "2020-06-22 07:51:02",
                        appliedTenor: undefined,
                        remainingTenor: undefined,
                        signatureDocumentId: undefined,
                    },
                ],
            },
            {
                id: "6113297",
                promissoryNoteDocumentId: "81e9a9b47cf04c698b11f9755042dfc5",
                accountNumber: "8131765",
                externalPartyId: undefined,
                state: "CLOSED",
                createDate: "2020-10-14 12:47:06",
                nextDueDate: undefined,
                creditLimit: 10000,
                availableCreditLimit: 0,
                usedCreditLimit: 10000,
                remainingPrincipal: 0,
                mainHolderId: "54763",
                coHolderId: undefined,
                trancheBlockStatus: "UNBLOCKED",
                product: {
                    id: "5ee36a8700b1c829031cf36b",
                    name: "Everyday Plus",
                    baseInterestRate: 0.39,
                    lateInterestRate: 0,
                    description: "",
                    dueDay: 28,
                    created: "2020-10-30 13:03:35",
                    startupFee: 395,
                    ocrFee: 39,
                    directDebitFee: 39,
                },
                mandateList: [
                    {
                        payerNumber: "8131765",
                        status: "CANCELLED",
                        id: "2043",
                        createDate: "2020-10-21 06:15:24",
                    },
                ],
                trancheList: [
                    {
                        id: "231251",
                        offerName: undefined,
                        appliedAmount: 3000,
                        createDate: "2020-10-14 14:40:06",
                        appliedTenor: undefined,
                        remainingTenor: undefined,
                        signatureDocumentId: undefined,
                    },
                    {
                        id: "233851",
                        offerName: undefined,
                        appliedAmount: 5000,
                        createDate: "2020-10-15 08:46:09",
                        appliedTenor: undefined,
                        remainingTenor: undefined,
                        signatureDocumentId: undefined,
                    },
                    {
                        id: "234051",
                        offerName: undefined,
                        appliedAmount: 2000,
                        createDate: "2020-10-15 09:58:01",
                        appliedTenor: undefined,
                        remainingTenor: undefined,
                        signatureDocumentId: undefined,
                    },
                ],
            },
            {
                id: "6122165",
                promissoryNoteDocumentId: "81e9a9b47cf04c698b11f9755042dfc5",
                accountNumber: "8131765",
                externalPartyId: undefined,
                state: "PENDING",
                createDate: "2020-10-14 12:47:06",
                nextDueDate: undefined,
                creditLimit: 10000,
                availableCreditLimit: 0,
                usedCreditLimit: 10000,
                remainingPrincipal: 0,
                mainHolderId: "54763",
                coHolderId: undefined,
                trancheBlockStatus: "UNBLOCKED",
                product: {
                    id: "5ee36a8700b1c829031cf36b",
                    name: "Everyday Plus",
                    baseInterestRate: 0.39,
                    lateInterestRate: 0,
                    description: "",
                    dueDay: 28,
                    created: "2020-10-30 13:03:35",
                    startupFee: 395,
                    ocrFee: 39,
                    directDebitFee: 39,
                },
                mandateList: [
                    {
                        payerNumber: "8131765",
                        status: "CANCELLED",
                        id: "2043",
                        createDate: "2020-10-21 06:15:24",
                    },
                ],
                trancheList: [
                    {
                        id: "231251",
                        offerName: undefined,
                        appliedAmount: 3000,
                        createDate: "2020-10-14 14:40:06",
                        appliedTenor: undefined,
                        remainingTenor: undefined,
                        signatureDocumentId: undefined,
                    },
                    {
                        id: "233851",
                        offerName: undefined,
                        appliedAmount: 5000,
                        createDate: "2020-10-15 08:46:09",
                        appliedTenor: undefined,
                        remainingTenor: undefined,
                        signatureDocumentId: undefined,
                    },
                    {
                        id: "234051",
                        offerName: undefined,
                        appliedAmount: 2000,
                        createDate: "2020-10-15 09:58:01",
                        appliedTenor: undefined,
                        remainingTenor: undefined,
                        signatureDocumentId: undefined,
                    },
                ],
            },
        ];

        const accountById = () => {
            if (data.productType === "CREDIT_LOAN")
                return creaditLoanAccounts.find((account) => account.id === data.accountId);
            if (data.productType === "REGULAR_LOAN")
                return regularLoanAccounts.find((account) => account.id === data.accountId);
            return {};
        };

        logger.log("========");
        return httpFaker<null, T_VpAccount | undefined>(url, method, null, accountById());
    }

    const result: Response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": data.token as string,
        },
    });

    return result.json();
}
