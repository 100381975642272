import * as Effects from "redux-saga/effects";
import { getUnixTime } from "date-fns";
import jwt_decode from "jwt-decode";
import { ActionType } from "typesafe-actions";

import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

const { takeEvery, put, select } = Effects;
const call: any = Effects.call;
const logger = new ConsoleLogger({ level: LOG_LEVEL });

import {
    UserLoginActionConstants,
    FeatureUserLoginState,
    UserReducerState,
    UserTokenData,
} from "../types/general";
import { loginVerifyToken } from "../api/login";
import { userLoginActions } from "../actions/login";

export function* watchStartLogin(): Generator {
    yield takeEvery(UserLoginActionConstants.USER_LOGIN_START, handleStartLogin);
    yield takeEvery(UserLoginActionConstants.USER_LOGIN_START_WITH_BANK, handleStartLoginWithBank);
}

export function* handleStartLogin(
    action: ActionType<typeof userLoginActions.loginStart>
): Generator {
    const userConfig = (yield select((state: FeatureUserLoginState) => {
        return state.user;
    })) as UserReducerState;

    logger.log("start login, redirect to", userConfig.loginUrl);

    if (userConfig.mockLogin) {
        const token = "random.test.token";
        window.location.href = `${userConfig.loginUrl}?jwt=${token}`;
    } else {
        window.location.href = userConfig.loginUrl;
    }
}

export function* handleStartLoginWithBank(
    action: ActionType<typeof userLoginActions.loginStartWithBank>
): Generator {
    const userConfig = (yield select((state: FeatureUserLoginState) => {
        return state.user;
    })) as UserReducerState;

    logger.log("start login, redirect to", userConfig.loginUrl, "with bank", action.payload.bank);

    if (userConfig.mockLogin) {
        const token = "random.test.token";
        window.location.href = `${userConfig.loginUrl}?jwt=${token}`;
    } else {
        window.location.href = `${userConfig.loginUrl}?bank=${action.payload.bank}`;
    }
}

export function* watchCompleteLogin(): Generator {
    yield takeEvery(UserLoginActionConstants.USER_LOGIN_COMPLETE, handleCompleteLogin);
}

export function* handleCompleteLogin(): Generator {
    try {
        const query = new URLSearchParams(window.location.search);
        const jwt = query.get("jwt") as string;
        const userConfig = (yield select((state: FeatureUserLoginState) => {
            return state.user;
        })) as UserReducerState;

        const result = (yield call(loginVerifyToken, {
            url: userConfig.verifyUrl,
            token: jwt,
            mockApiCalls: userConfig.mockLogin,
            method: userConfig.verifyApiRequestMethod ?? "POST",
        })) as boolean;

        if (userConfig.mockLogin) {
            logger.log("path a", userConfig.mockLogin);

            localStorage.setItem("user_token", jwt);
            localStorage.setItem("mock_login", userConfig.mockLogin ? "true" : "false");

            window.location.href = userConfig.successUrl;
        } else {
            logger.log("path b", userConfig.mockLogin);

            if (!result) {
                window.location.href = `${userConfig.appBaseUrl}/expired`;
            }

            localStorage.setItem("user_token", jwt);

            logger.log("login success!", userConfig);

            window.location.href = userConfig.successUrl;
        }
    } catch (e) {
        logger.log("login failed!", e);
    }
}

export function* watchCheckLoginStatus(): Generator {
    yield takeEvery(UserLoginActionConstants.USER_LOGIN_CHECK_STATUS, handleCheckLoginStatus);
}

export function* handleCheckLoginStatus(): Generator {
    try {
        const userConfig = (yield select((state: FeatureUserLoginState) => {
            return state.user;
        })) as UserReducerState;

        logger.log("check login status with configs", userConfig);

        yield put(userLoginActions.updateIsValidatingToken(true));

        const token: string | null = localStorage.getItem("user_token");

        const mockLogin: boolean =
            localStorage.getItem("mock_login") === "true" ? true : userConfig.mockLogin;

        if (!token) {
            window.location.href = userConfig.errorUrl;
            return;
        }

        let isValidToken: boolean = false;
        let tokenData: UserTokenData | null = null;

        isValidToken = (yield call(loginVerifyToken, {
            token,
            url: userConfig.verifyUrl,
            mockApiCalls: mockLogin,
            verifyApiCallMethod: userConfig.verifyApiRequestMethod ?? "POST",
        })) as boolean;

        logger.log("is valid token?", isValidToken);

        if (mockLogin) {
            tokenData = {
                exp: getUnixTime(new Date()) + 15 * 60 * 1000, // add 15 minutes to unix timestamp
                iss: "",
                jti: "",
                iat: 0,
                aud: "",
                sid: "",
                attrs: {
                    fullname: "Testoman Tester",
                    ssn: "12341431",
                    firstname: "Testoman",
                    lastname: "Tester",
                    birthdate: "2000-02-01",
                },
                nbf: 0,
                sub: "12341431",
            };
        } else {
            tokenData = jwt_decode<UserTokenData>(token);
        }

        yield put(userLoginActions.updateIsValidToken(isValidToken));
        yield put(userLoginActions.updateToken(token));
        yield put(userLoginActions.updateTokenData(tokenData));

        if (!isValidToken) {
            localStorage.removeItem("user_token");
            yield put(userLoginActions.updateToken(""));
            window.location.href = `${userConfig.appBaseUrl}/expired`;
        }

        yield put(userLoginActions.updateIsValidatingToken(false));
        yield put(userLoginActions.updateIsInitialized(true));
        yield put(userLoginActions.loginCheckStatusSuccess());
    } catch (e) {
        logger.log("validation failed", e);

        yield put(userLoginActions.updateToken(""));
        yield put(userLoginActions.updateIsValidToken(false));
    }
}

export function* watchLogout(): Generator {
    yield takeEvery(UserLoginActionConstants.USER_LOGOUT, handleLogout);
}

export function* handleLogout(): Generator {
    try {
        logger.log("HANDLE LOGOUT!");

        const userConfig = (yield select((state: FeatureUserLoginState) => {
            return state.user;
        })) as UserReducerState;

        yield put(userLoginActions.updateIsValidatingToken(true));

        const token: string | null = localStorage.getItem("user_token");
        localStorage.removeItem("mock_login");

        logger.log("got token for logout", token);

        if (!token) {
            logger.log("no token found?", token);
            yield put(userLoginActions.updateIsValidToken(false));
            yield put(userLoginActions.updateToken(""));
            yield put(userLoginActions.updateIsValidatingToken(false));
            window.location.href = userConfig.errorUrl;
            return;
        }

        yield put(userLoginActions.updateIsValidToken(false));
        yield put(userLoginActions.updateToken(""));
        yield put(userLoginActions.updateIsValidatingToken(false));

        window.location.href = userConfig.logoutUrl;
    } catch (e) {
        logger.log("validation failed", e);

        yield put(userLoginActions.updateToken(""));
        yield put(userLoginActions.updateIsValidToken(false));
    }
}
