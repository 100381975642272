import * as VP from "@opr-finance/api-definitions";
import { DefaultInitializerType } from "@opr-finance/utils";

export enum E_InvoicesActionConstants {
    FETCH_INVOICES_INITIALIZER = "INVOICES/INITIALIZER",
    FETCH_INVOICES_TRIGGER = "INVOICES/FETCH_INVOICES_TRIGGER",
    FETCH_INVOICES_SUCCESS = "INVOICES/FETCH_INVOICES_SUCCESS",
    FETCH_INVOICES_ERROR = "INVOICES/FETCH_INVOICES_ERROR",
}

export enum E_InvoicesItemConf {
    DEFAULT_FORMAT_DATE = "dd'.'MM'.'yyyy",
    FINLAND = "dd'.'MM'.'yyyy",
    NETHERLANDS = "dd'.'MM'.'yyyy",
    SWEDEN = "yyyy'-'MM'-'dd",
}

/** First implementation - this should be tied to VP's InvoiceStatuses type... somehow
 *
 * export type InvoiceStatus =
 * | "PENDING"
 * | "UNPAID"
 * | "PARTIALLYPAID"
 * | "PAIDINFULL"
 * | "OVERDUE"
 * | "COLLECTION"
 * | "CURING";
 */
export enum E_InvoiceStatuses {
    PENDING = "PENDING",
    UNPAID = "UNPAID",
    PARTIALLYPAID = "PARTIALLYPAID",
    PAIDINFULL = "PAIDINFULL",
    OVERDUE = "OVERDUE",
    COLLECTION = "COLLECTION",
    CURING = "CURING",
}

export type T_InvoicesMessageData = {
    contentfulKey: string;
    messageId: string;
};

export type T_InvoicesGeneratedMessages = {
    [key: string]: {
        id: string;
    };
};
type additionalInvoiceFields = {
    title: string;
    amount: string;
    fees: string;
};

export type T_FetchInvoicesRequest = {
    activeAccountId: string | undefined;
} & DefaultInitializerType;

export type T_VpInvoice = VP.components["schemas"]["Invoice"];
export type T_VpInvoices = Array<T_VpInvoice>;
export type T_VpInvoicesResponse = VP.components["schemas"]["InvoicesResponse"];

export type T_FormattedInvoice = T_VpInvoice & additionalInvoiceFields;

export type T_Currency = {
    currencyCountry: string;
};

export type T_ApiResponse = {
    original: T_VpInvoices;
    formatted: Array<T_FormattedInvoice>;
};

export type T_InvoicesInitializerType = DefaultInitializerType & {
    activeAccountId: string | undefined;
} & T_Currency;

export type T_InvoicesReducerState = {
    original: T_VpInvoices | [];
    formatted: Array<T_FormattedInvoice> | [];
    config: T_InvoicesInitializerType;
};

/** REFACTOR: This fixes type issues in Flex Online AppState as it needs to use feature-luvittaja-login,
 *  type T is defined as the LoginState being used, by default it is FeatureLoginState from feature-lfp-login
 *  todo: check if login state can be completely removed from this type
 */
export type T_FeatureInvoicesState = {
    invoices: T_InvoicesReducerState;
};
