import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { format, parseISO } from "date-fns";
import { noticesActions, NoticesAction } from "../actions/notices";
import { NoticesReducerState, NoticesItemConf } from "../types/contentful";

export const initialState = {
    notices: null,
};

export const noticesReducer = createReducer<NoticesReducerState, NoticesAction>(
    initialState
).handleAction(noticesActions.fetchNoticesSuccess, (state, action) => {
    return produce(state, (draftState) => {
        const notices = action.payload.map((item) => {
            const createdDate = format(
                parseISO(item.sys.createdAt),
                NoticesItemConf.DEFAULT_FORMAT_DATE
            );
            // const ingress = item.fields.content.slice(0, NoticesItemConf.INGRESS_MAX_LENGTH);

            // if (ingress.endsWith(" ")) {
            //     item.fields.ingress = `${ingress}...`;
            // } else {
            //     const lastSpace = ingress.lastIndexOf(" ");
            //     const lastSpaceIngress = ingress.slice(0, lastSpace + 1);
            //     item.fields.ingress = `${lastSpaceIngress}...`;
            // }

            return {
                ...item.fields,
                createdDate,
            };
        });

        draftState.notices = notices.filter((notices) => notices.active === "Yes");
    });
});
