import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "flex-online-fi-eligible-topup-page-title",
    },
    subHeading1: {
        id: "flex-online-fi-eligible-topup-page-subheading-1",
    },
    subHeading2: {
        id: "flex-online-fi-eligible-topup-page-subheading-2",
    },
    pageInfo1: {
        id: "flex-online-fi-eligible-topup-page-info1",
    },
    pageInfo2: {
        id: "flex-online-fi-eligible-topup-page-info2",
    },
    contactPhone: {
        id: "flex-online-fi-eligible-topup-page-phone",
    },
    textEmail: {
        id: "flex-online-fi-text-email",
    },
    emailLink: {
        id: "flex-online-fi-email-customerService-link",
    },
    emailLinkText: {
        id: "flex-online-fi-email-customerService-text",
    },
});
