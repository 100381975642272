import styled, { createGlobalStyle, GlobalStyleComponent } from "styled-components";

export type GlobalStylesProps = {};

export const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
    body {
        margin-bottom: 56px;
    }
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 70px;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0 -1px 4px 0 rgba(12, 68, 92, 0.2);
    @media (min-width: 786px) {
        display: none;
        visibility: hidden;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-around;
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;
