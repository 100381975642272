import { call, put, select, takeEvery } from "redux-saga/effects";

import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { invoicesActions } from "../actions/index.actions";
import * as invoicesApi from "../api/index.api";
import {
    E_InvoicesActionConstants,
    T_FeatureInvoicesState,
    T_InvoicesInitializerType,
    T_VpInvoicesResponse,
} from "../types/index.types";
import { formattedInvoices } from "../payload/invoices";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* watchFetchInvoices() {
    yield takeEvery(E_InvoicesActionConstants.FETCH_INVOICES_TRIGGER, handleFetchInvoices);
}

export function* handleFetchInvoices() {
    try {
        const { token, mockApiCalls, gwUrl, activeAccountId, currencyCountry } = (yield select(
            (state: T_FeatureInvoicesState) => state.invoices.config
        )) as T_InvoicesInitializerType;

        if (!token) {
            throw new Error("No token");
        }

        const invoices: T_VpInvoicesResponse = yield call(invoicesApi.fetchInvoices, {
            token,
            activeAccountId,
            mockApiCalls,
            gwUrl,
        });

        const formatted = formattedInvoices(invoices, currencyCountry);

        const payload: any = {
            origional: invoices.invoices,
            formatted,
        };

        yield put(invoicesActions.fetchInvoicesSuccess(payload));
    } catch (e) {
        logger.log("fetch invoices trigger failed", e);
    }
}
