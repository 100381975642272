import React, { useState } from "react";
import { differenceInDays, format, lastDayOfMonth, addDays, isAfter } from "date-fns";

import { StyledGrid } from "@opr-finance/component-grid";
import { Font } from "@opr-finance/component-fonts";
import { StyledButton } from "@opr-finance/component-button";
import { DatepickerSelect, MonthPicker } from "@opr-finance/component-datepicker";
import { ButtonLoader } from "@opr-finance/component-loader";
import { dateFormatter, useWindowSize } from "@opr-finance/utils";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";
import { generatePdf } from "@opr-finance/feature-pdf-generator";

import { useDispatch, useSelector } from "react-redux";
import { reportingActions } from "../actions/index";
import { T_ReportingComponentProps } from "../types";
import { T_CountryProductId, fetchReport } from "../api/reporting";
import ReactDOM from "react-dom";
import { PrepairingPdfComponent } from "./PrepairingPdfComponent";
import { T_GatewayProps } from "@opr-finance/utils/src/types/general";
import { getGwProps } from "@opr-finance/utils/src/getGwProps";
import dayjs, { Dayjs } from "dayjs";

export function Reporting(props: T_ReportingComponentProps) {
    const dispatch = useDispatch();
    const logger = new ConsoleLogger({ level: LOG_LEVEL });

    const {
        styles,
        cid,
        reportingYears,
        translations,
        accountCreatedDate,
        pdfGeneratorUrl,
        locale,
        dateFormat,
        latestReportDate,
        earliestReportDate,
    } = props;
    const token = localStorage.getItem("token");

    const dateFormatMonthPicker = "DD.MM.YYYY";

    const formatToString = (date: string | Date) => {
        const formattedDate =
            typeof date === "string"
                ? format(new Date(date), dateFormat)
                : format(date, dateFormat);

        return formattedDate;
    };

    const getReportingDate = (date: Date) => {
        const firstDayNextMonth = addDays(date, 1);
        const dateString = dateFormatter(firstDayNextMonth).ymd;
        return dateString;
    };

    const setReportingDate = (value: string) => {
        const lastDayReportingMonth = lastDayOfMonth(new Date(value));
        setReportingMonth(dayjs(lastDayReportingMonth));
        const reportingDate = getReportingDate(lastDayReportingMonth);
        setReportingEndDate(reportingDate);
    };

    const defaultReportDate =
        cid === "finland-flex-online" && latestReportDate ? getReportingDate(latestReportDate) : "";
    const [reportingEndDate, setReportingEndDate] = useState<string>(defaultReportDate);
    const [reportingMonth, setReportingMonth] = useState<Dayjs>(dayjs(latestReportDate));
    const [isPdfLoadingError, setPdfLoadingError] = useState(false);
    const [isRequestPending, setIsRequestPending] = useState(false);

    const state: any = useSelector((state) => state);
    const currentDate = new Date();
    const accountAgeInDays = differenceInDays(new Date(), new Date(accountCreatedDate));
    const isReportAvailable = () => {
        if (cid === "sweden-flex-online") return accountAgeInDays < 365;
        else if (cid === "finland-flex-online") {
            if (earliestReportDate) return isAfter(currentDate, earliestReportDate);
            else return false;
        } else return true;
    };
    const gw: T_GatewayProps = getGwProps();

    const data = {
        cid: cid as T_CountryProductId,
        accountNumber: state.account.account.accountNumber,
        reportingEndDate: "",
        token: localStorage.getItem("token") as string,
        mockApiCalls: gw.mock,
        gwUrl: gw.baseUrl,
    };

    const handleGetReport = async (date: string) => {
        const pdfWindow = window.open("", "POPUP_WINDOW", "");

        ReactDOM.render(<PrepairingPdfComponent />, pdfWindow!.document.body);

        setIsRequestPending(true);
        setPdfLoadingError(false);

        try {
            const reportingData = await fetchReport({ ...data, reportingEndDate: date });

            const pdfUrl = (await getPdfReport(reportingData)) as string;

            pdfWindow!.location.href = pdfUrl;
        } catch (e: any) {
            setIsRequestPending(false);
            setPdfLoadingError(true);

            pdfWindow!.close();
        }
    };

    const getPdfReport = async (reportingData) => {
        try {
            if (!token) {
                logger.log("got no token, back to front page");
                return (window.location.href = "/");
            }
            const result = (await generatePdf(reportingData, pdfGeneratorUrl, token)) as string;

            dispatch(
                reportingActions.reportingSuccess({
                    status: "",
                    yearlyOverview: reportingData,
                })
            );
            setIsRequestPending(false);

            return result;
        } catch (error) {
            logger.log("error in getPdfReport", error);
            throw new Error();
        }
    };

    const blockInstructions = () => {
        let message: string;
        if (cid === "netherlands-flex-online") {
            message =
                reportingYears && reportingYears.length > 0
                    ? translations.reportingInstructions
                    : translations.noReportsMessage;
        } else {
            message = translations.reportingInstructions;
        }
        return message;
    };

    return (
        <StyledGrid styleConfig={{ root: styles.rootContainer }}>
            <Font styleConfig={{ root: styles.blockHeadingStyles }} as="p">
                {translations.blockHeading}
            </Font>

            <Font as="p" styleConfig={{ root: styles.reportingInstructionsText }}>
                {blockInstructions()}
            </Font>
            {cid === "netherlands-flex-online" ? (
                <StyledGrid styleConfig={{ root: styles.reportsContainer }}>
                    {reportingYears &&
                        reportingYears.map((year) => (
                            <StyledButton
                                onClick={() => handleGetReport(`${year}-12-31`)}
                                key={year}
                                disabled={isRequestPending}
                                styleConfig={{ root: styles.button }}>
                                {" "}
                                <Font as="p" styleConfig={{ root: styles.buttonText }}>
                                    {isRequestPending ? (
                                        <>
                                            <span> {translations.loadingText}</span>
                                            <ButtonLoader
                                                size="1x"
                                                icon={["fas", "spinner"]}
                                                styles={{ margin: "0 5px", color: "white" }}
                                            />
                                        </>
                                    ) : (
                                        year
                                    )}
                                </Font>{" "}
                            </StyledButton>
                        ))}
                </StyledGrid>
            ) : (
                <StyledGrid styleConfig={{ root: styles.reportsContainer }}>
                    {cid === "finland-flex-online" ? (
                        <MonthPicker
                            config={{
                                value: reportingMonth,
                                onChange: (newValue) => setReportingDate(newValue),
                                locale: locale,
                                maxDate: dayjs(latestReportDate),
                                minDate: dayjs(earliestReportDate),
                                dateFormat: dateFormatMonthPicker,
                                disabled: isRequestPending,
                            }}
                        />
                    ) : (
                        <DatepickerSelect
                            styleConfig={{
                                regularStyles: styles.reportingDatepicker,
                                errorStyles: {},
                                successStyles: {},
                                validation: {},
                                popupContainer: {},
                                inputField: {},
                            }}
                            config={{
                                toDate: new Date(),
                                fromDate: new Date(accountCreatedDate),
                                handleDate: (date) => {
                                    const formattedDate = dateFormatter(date);
                                    setReportingEndDate(formattedDate.ymd);
                                },
                                name: reportingEndDate,
                                value: reportingEndDate && formatToString(reportingEndDate),
                                dateFormat: dateFormat,
                                placeholder:
                                    translations.dateInputPlaceholder || "Please select end date",
                                disabled: false,
                                errorMessage: {},
                                locale,
                            }}
                        />
                    )}
                    <StyledButton
                        onClick={() => handleGetReport(reportingEndDate)}
                        disabled={reportingEndDate === "" || !isReportAvailable || isRequestPending}
                        styleConfig={{ root: styles.button }}>
                        <Font as="p" styleConfig={{ root: styles.buttonText }}>
                            {isRequestPending ? (
                                <>
                                    <span> {translations.loadingText}</span>
                                    <ButtonLoader
                                        size="1x"
                                        icon={["fas", "spinner"]}
                                        styles={{ margin: "0 5px", color: "white" }}
                                    />
                                </>
                            ) : (
                                translations.buttonText
                            )}
                        </Font>
                    </StyledButton>
                </StyledGrid>
            )}
            {isPdfLoadingError && (
                <Font as="p" styleConfig={{ root: styles.errorText }}>
                    {translations.errorText}
                </Font>
            )}
        </StyledGrid>
    );
}
