export function cleanLocalStorage(): void {
    const token = localStorage.getItem("token");
    const smeId = localStorage.getItem("smeId");

    if (token) {
        localStorage.removeItem("token");
    }
    if (smeId) {
        localStorage.removeItem("smeId");
    }
}
