import React, { FunctionComponent } from "react";
import { useRouteMatch } from "react-router-dom";
import { NavigationLink } from "./NavigationLink";
import { NavigationProps } from "./types";

export const Navigation: FunctionComponent<NavigationProps> = ({ navigationItems, ...rest }) => {
    return (
        <>
            {navigationItems.map((item, index) => {
                return (
                    <NavigationLink
                        key={index}
                        to={item.linkTo}
                        active={
                            useRouteMatch({ path: item.linkTo, exact: true })?.isExact
                                ? "true"
                                : "false"
                        }
                        {...rest}>
                        <span>{item.navigationText}</span>
                    </NavigationLink>
                );
            })}
        </>
    );
};
