import { call, put, takeEvery, select } from "redux-saga/effects";

import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { documentActions } from "../actions/document";
import * as documentApi from "../api/document";
import {
    FeatureDocumentState,
    DocumentInitializerType,
    IGeneratePdfRequest,
    DocumentType,
    DocumentDownloadStatus,
} from "../types/document";
import { downloadDocument, showDocument } from "../component/document";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* handleFetchDocument(
    action: ReturnType<typeof documentActions.fetchDocumentTrigger>
) {
    try {
        const { token, mockApiCalls, gwUrl, generatorUrlBase, source } = (yield select(
            (state: FeatureDocumentState) => state.document.config
        )) as DocumentInitializerType;

        switch (action.payload.documentType) {
            case DocumentType.INVOICE:
            case DocumentType.PROMISSORY_NOTE:
                const fileContent = yield call(documentApi.fetchDocument, {
                    documentId: action.payload.documentId,
                    token,
                    mockApiCalls,
                    gwUrl,
                });
                const documentUrl = window.URL.createObjectURL(fileContent);

                yield put(
                    documentActions.fetchDocumentSuccess({
                        documentId: action.payload.documentId,
                        downloadStatus: DocumentDownloadStatus.COMPLETED,
                        documentUrl,
                    })
                );

                downloadDocument(documentUrl);
                break;
            case DocumentType.POA:
                const pdfPayload: IGeneratePdfRequest = {
                    params: {
                        token,
                        mockApiCalls,
                        gwUrl,
                        generatorUrlBase,
                        source,
                        documentType: action.payload.documentType,
                    },
                    payload: action.payload.data,
                };
                const poaPdfContent = yield call(documentApi.generateAndFetchPdf, pdfPayload);
                logger.log(poaPdfContent);

                const poaPdfUrl = URL.createObjectURL(poaPdfContent);

                yield put(
                    documentActions.fetchDocumentSuccess({
                        documentId: DocumentType.POA,
                        downloadStatus: DocumentDownloadStatus.COMPLETED,
                        documentUrl: poaPdfUrl,
                    })
                );

                showDocument(poaPdfUrl);
                break;
            default:
                logger.log("document type not found");
                yield put(
                    documentActions.fetchDocumentError({
                        documentId: "0",
                        downloadStatus: DocumentDownloadStatus.ERROR,
                    })
                );
        }
    } catch (e) {
        logger.log("Fetch document failed");
        yield put(
            documentActions.fetchDocumentError({
                documentId: action.payload.documentId,
                downloadStatus: DocumentDownloadStatus.ERROR,
            })
        );
    }
}

export function* handleGetPromissoryNoteDocumentId(
    action: ReturnType<typeof documentActions.fetchPromissoryNoteIdInitializer>
) {
    try {
        const { token, mockApiCalls, gwUrl } = (yield select(
            (state: FeatureDocumentState) => state.document.config
        )) as DocumentInitializerType;

        const documentList = yield call(documentApi.fetchAccountDocumentsMeta, {
            accountNumber: action.payload.accountNumber,
            token,
            mockApiCalls,
            gwUrl,
        });
        let documentId: string | undefined = "";

        if (documentList.length === 0) {
            documentId = action.payload.defaultPromissoryNoteId;
        } else {
            const promissoryNotes = documentList
                .filter(
                    (document) => document.metadata.documentType === DocumentType.PROMISSORY_NOTE
                )
                .sort((a, b) => (a.metadata.lastUpdateDate < b.metadata.lastUpdateDate ? 1 : -1));
            if (promissoryNotes.length === 0) {
                documentId = action.payload.defaultPromissoryNoteId;
            } else {
                documentId = promissoryNotes[0].metadata.documentId;
            }
        }

        yield put(documentActions.fetchPromissoryNoteIdInitialized({ documentId }));
    } catch (e) {}
}

export function* watchFetchDocument() {
    yield takeEvery(documentActions.fetchDocumentTrigger, handleFetchDocument);
    yield takeEvery(
        documentActions.fetchPromissoryNoteIdInitializer,
        handleGetPromissoryNoteDocumentId
    );
}
