import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { DocumentAction, documentActions } from "../actions/document";
import { DocumentReducerState, DocumentDownloadStatus } from "../types/document";

const initialState = {
    document: null,
    documentId: undefined,
    config: {
        mockApiCalls: false,
        gwUrl: "",
        token: "",
        generatorUrlBase: "",
        source: "",
        language: "",
    },
};

export const documentReducer = createReducer<DocumentReducerState, DocumentAction>(initialState)
    .handleAction(documentActions.fetchDocumentInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config.mockApiCalls = action.payload.mockApiCalls;
            draftState.config.gwUrl = action.payload.gwUrl;
            draftState.config.token = action.payload.token;
            draftState.config.generatorUrlBase = action.payload.generatorUrlBase;
            draftState.config.source = action.payload.source;
            draftState.config.language = action.payload.language;
        });
    })
    .handleAction(documentActions.fetchDocumentTrigger, (state, action) => {
        return produce(state, (draftState) => {
            draftState.document = {
                ...draftState.document,
                [action.payload.documentId as string]: {
                    documentId: action.payload.documentId,
                    downloadStatus: DocumentDownloadStatus.IN_PROGRESS,
                },
            };
        });
    })
    .handleAction(documentActions.fetchDocumentSuccess, (state, action) => {
        return produce(state, (draftState) => {
            draftState.document = {
                ...draftState.document,
                [action.payload.documentId as string]: {
                    documentUrl: action.payload.documentUrl,
                    documentId: action.payload.documentId,
                    downloadStatus: action.payload.downloadStatus,
                },
            };
        });
    })
    .handleAction(documentActions.fetchDocumentError, (state, action) => {
        return produce(state, (draftState) => {
            draftState.document = {
                ...draftState.document,
                [action.payload.documentId as string]: {
                    documentId: action.payload.documentId,
                    downloadStatus: action.payload.downloadStatus,
                },
            };
        });
    })
    .handleAction(documentActions.fetchPromissoryNoteIdInitialized, (state, action) => {
        return produce(state, (draftState) => {
            draftState.documentId = action.payload.documentId;
        });
    });
