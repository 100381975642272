import styled from "styled-components";

export const Root = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const RootContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

export const ItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 34px;
    width: 70px;
    align-items: center;
    justify-content: center;
`;

export const Item = styled.div`
    display: flex;
    flex-direction: row;
    height: 34px;
    align-items: center;
    justify-content: center;
`;
