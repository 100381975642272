import React, { useState, useRef } from "react";
import styled from "styled-components";
import { css } from "@styled-system/css";
import { TextField } from "@opr-finance/component-forms";

import { usePopper } from "react-popper";
import { format, parse } from "date-fns";
import FocusTrap from "focus-trap-react";
import { fi, nl, sv, enGB } from "date-fns/locale";
import { DayPicker } from "react-day-picker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/fi";

import type {
    T_BaseComponentProps,
    T_DatepickerProps,
    T_DatepickerRangeProps,
    T_MonthPickerProps,
} from "./types";
import "react-day-picker/dist/style.css";
import "./styles.css";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

const datePickerSlotProps = {
    desktopPaper: {
        sx: {
            ".MuiPickersMonth-monthButton": {
                color: "rgb(18, 72, 144)",
            },
            ".MuiPickersMonth-monthButton.Mui-disabled": {
                opacity: 0.25,
            },
            ".MuiPickersMonth-monthButton.Mui-selected": {
                color: "#ffff",
            },
            ".MuiPickersCalendarHeader-label": {
                color: "rgb(18, 72, 144)",
                fontWeight: 600,
            },
        },
    },
    mobilePaper: {
        sx: {
            ".MuiPickersMonth-monthButton": {
                color: "rgb(18, 72, 144)",
            },
            ".MuiPickersMonth-monthButton.Mui-disabled": {
                opacity: 0.25,
            },
            ".MuiPickersMonth-monthButton.Mui-selected": {
                color: "#ffff",
            },
            ".MuiPickersCalendarHeader-label": {
                color: "rgb(18, 72, 144)",
                fontWeight: 600,
            },
        },
    },
    openPickerButton: {
        sx: {
            ".MuiSvgIcon-root": {
                color: "rgb(18, 72, 144)",
            },
        },
    },
};

const BaseComponent = (props: T_BaseComponentProps) => {
    return (
        <div
            ref={props.popperRef}
            style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
            }}>
            <span ref={props.inputRef} tabIndex={-1}>
                <TextField
                    inputConfig={{
                        placeholder: props.placeholder,
                        value: props.value,
                        onClick: props.onClick,
                        onChange(e) {
                            props.onChange;
                        },
                    }}
                    styleConfig={{
                        root: props.styleConfig!.regularStyles,
                    }}
                />
            </span>
            {/*  <span ref={props.buttonRef} tabIndex={-1}>
                <button onClick={props.handleReset}>x</button>
            </span> */}
        </div>
    );
};

const StyledDatepickerField = styled(BaseComponent)<T_BaseComponentProps>`
    ${(props: T_BaseComponentProps) => css(props.styleConfig)};
`;

export const DatepickerBasic = () => {
    const [selected, setSelected] = React.useState<Date>();

    let footer = <p>Please pick a day.</p>;
    if (selected) {
        footer = <p>You picked {format(selected, "PP")}.</p>;
    }
    return <DayPicker mode="single" selected={selected} onSelect={setSelected} footer={footer} />;
};

export const DatepickerSelect = (props: T_DatepickerProps) => {
    const logger = new ConsoleLogger({ level: LOG_LEVEL });
    const { config, styleConfig } = props;
    let locale: Locale;
    switch (config?.locale) {
        case "fi":
            locale = fi;
            break;
        case "sv":
            locale = sv;
            break;
        case "nl":
            locale = nl;
            break;
        default:
            locale = enGB;
    }

    const dateFormat = config?.dateFormat ? config.dateFormat : "yyyy-MM-dd";
    const formattedInitialValue = config.value
        ? parse(config.value, dateFormat, new Date())
        : undefined;
    const [selected, setSelected] = useState<Date | undefined>(formattedInitialValue);
    const [isPopperOpen, setIsPopperOpen] = useState(false);

    const popperRef = useRef<HTMLDivElement>(null);
    //const buttonRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

    const popper = usePopper(popperRef.current, popperElement, {
        placement: "bottom-start",
    });

    const closePopper = () => {
        setIsPopperOpen(false);
        // buttonRef?.current?.focus();
        inputRef?.current?.focus();
    };

    const handleClick = () => {
        setIsPopperOpen(!isPopperOpen);
    };

    const handleReset = () => {
        setSelected(undefined);
        config?.handleDate?.("");
    };

    const handleDaySelect = (date: Date | undefined) => {
        setSelected(date);
        if (date) {
            config?.handleDate?.(date);
            closePopper();
        } else {
            config?.handleDate?.("");
        }
    };

    return (
        <div>
            <StyledDatepickerField
                popperRef={popperRef}
                inputRef={inputRef}
                //buttonRef={buttonRef}
                //handleReset={handleReset}
                placeholder={config?.placeholder}
                value={config?.value}
                onChange={() => logger.log("OnChange")}
                onClick={handleClick}
                styleConfig={styleConfig}
            />

            {isPopperOpen && (
                <FocusTrap
                    active
                    focusTrapOptions={{
                        initialFocus: false,
                        allowOutsideClick: true,
                        clickOutsideDeactivates: true,
                        onDeactivate: closePopper,
                    }}>
                    <div
                        tabIndex={-1}
                        style={popper.styles.popper}
                        className="popupContainer"
                        {...popper.attributes.popper}
                        ref={setPopperElement}
                        role="dialog">
                        <DayPicker
                            initialFocus={isPopperOpen}
                            mode="single"
                            locale={locale}
                            defaultMonth={selected}
                            selected={selected}
                            onSelect={handleDaySelect}
                            toDate={config.toDate}
                            fromDate={config.fromDate}
                            captionLayout="dropdown"
                        />
                    </div>
                </FocusTrap>
            )}
        </div>
    );
};

export const DatepickerRange = (props: T_DatepickerRangeProps) => {
    const { config, styleConfig } = props;
    return (
        <>
            <DatepickerSelect
                config={{
                    name: config.name1,
                    placeholder: config.placeholder1,
                    value: config.value1,
                    handleDate: config.handleDate1,
                    dateFormat: config.dateFormat,
                    locale: config.locale,
                    toDate: config.toDate1,
                    fromDate: config.fromDate1,
                }}
                styleConfig={styleConfig}
            />
            {" - "}
            <DatepickerSelect
                config={{
                    name: config.name2,
                    placeholder: config.placeholder2,
                    value: config.value2,
                    handleDate: config.handleDate2,
                    dateFormat: config.dateFormat,
                    locale: config.locale,
                    toDate: config.toDate2,
                    fromDate: config.fromDate2,
                }}
                styleConfig={styleConfig}
            />
        </>
    );
};

export const MonthPicker = (props: T_MonthPickerProps) => {
    const { config } = props;
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={config.locale}>
            <DatePicker
                openTo="month"
                views={["year", "month"]}
                value={config.value}
                onChange={config.onChange}
                maxDate={config.maxDate}
                minDate={config.minDate}
                format={config.dateFormat}
                disabled={config.disabled}
                slotProps={{
                    ...datePickerSlotProps,
                    textField: {
                        size: "small",
                        sx: {
                            ".MuiInputBase-root": {
                                paddingTop: "1px",
                                paddingBottom: "1px",
                                borderRadius: "5px",
                                margin: ["10px 0", "10px 30px"],
                            },
                        },
                    },
                    field: {
                        readOnly: true, //prevent user interaction on input field
                    },
                }}
            />
        </LocalizationProvider>
    );
};
