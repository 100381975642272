import styled from "styled-components";
import { FunctionComponent } from "react";
import { AppTheme, AppThemeProps } from "@opr-finance/themes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { variant, color, space, typography } from "styled-system";
import { css } from "@styled-system/css";
import { ClickableIconProps, IconProps, ClickableIconContainerProps } from "./types";

export type IsActiveProp = {
    active: string | null | undefined;
};

export type BasicIconProps = IconProps & IsActiveProp;
export type IconPropsWithTheme = BasicIconProps & AppThemeProps;

const variants = (theme: AppTheme) => ({
    primary: {
        color: theme.colors.primary,
        ":hover": {
            color: theme.colors.secondary,
        },
    },
    secondary: {
        color: theme.colors.secondary,
        ":hover": {
            color: theme.colors.primary,
        },
    },
    largeButton: {
        color: theme.colors.green,
    },
});

export const BasicStyledIcon: FunctionComponent<BasicIconProps> = styled(
    FontAwesomeIcon
)<BasicIconProps>`
    ${(props: BasicIconProps) => (props.cursor === "pointer" ? "cursor:pointer;" : "")}
    ${(props: IconPropsWithTheme) =>
        variant({
            variants: variants(props.theme),
        })}
    ${color}
    ${space}
    ${typography}
`;

export const ClickableIconContainer: FunctionComponent<ClickableIconContainerProps> = styled.div<ClickableIconContainerProps>`
    cursor: pointer;
    ${(props: ClickableIconContainerProps) => css(props.styleConfig.container)}
`;
