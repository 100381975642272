import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
`;

export const PaginatorContainer = styled.div`
    display: flex;
`;

export const ButtonLeft = styled.div`
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;
    background-color: #0b445c;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        background-color: #5593a8;
    }
    & > svg {
        position: relative;
        left: -1px;
    }
`;

export const ButtonRight = styled(ButtonLeft)`
    margin-right: 16px;
    & > svg {
        position: relative;
        left: 1px;
    }
`;

export const Current = styled.div`
    display: flex;
    width: 65px;
    height: 30px;
    align-items: center;
    justify-content: center;
`;

export const Select = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 40px;
    padding-bottom: 4px;
    width: 118px;
`;

export const Selected = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    background-color: #ffffff;
    height: 30px;
    width: 100%;
    padding: 8px;
    margin: 0;
    cursor: pointer;
`;

export const SelectedLabel = styled.div`
    display: flex;
    flex: 1;
`;

export const SelectedIcon = styled.div`
    display: flex;
    width: 10px;
    height: 30px;
`;

export const Options = styled.div`
    margin-top: 16px;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
    height: 100%;
    width: 100%;
`;

export const Option = styled.div`
    display: flex;
    flex-direction: row;
    height: 30px;
    text-align: left;
    padding: 8px;
    background-color: #ffffff;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0;
    &:last-of-type {
        border-radius: 4px;
    }
`;
