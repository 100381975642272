import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { T_ReportingReducerState } from "../types";
import { T_ReportingAction, reportingActions } from "../actions";

const initialState: T_ReportingReducerState = {
    yearlyOverview: {
        loanAccountNumber: "",
        companyName: "",
        organizationNumber: "",
        email: "",
        city: "",
        zipcode: "",
        streetAddress: "",
        status: "",
        reportingYear: "",
        reportingStartDate: "",
        reportingEndDate: "",
        remainingPrincipalSumEnd: "",
    },
    status: "",
    config: {
        mockApiCalls: false,
        gwUrl: "",
        token: "",
        accountNumber: undefined,
        cid: "",
        country: "",
    },
};

export const reportingReducer = createReducer<T_ReportingReducerState, T_ReportingAction>(
    initialState
)
    .handleAction(reportingActions.reportingInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config = action.payload;
        });
    })
    .handleAction(reportingActions.reportingTrigger, (state, action) => {
        return produce(state, (draftState) => {
            draftState.status = "";
        });
    })
    .handleAction(reportingActions.reportingSuccess, (state, action) => {
        return produce(state, (draftState) => {
            draftState.yearlyOverview = action.payload.yearlyOverview;
            draftState.status = action.payload.status;
        });
    })
    .handleAction(reportingActions.reportingError, (state, action) => {
        return produce(state, (draftState) => {
            draftState.yearlyOverview = action.payload.yearlyOverview;
            draftState.status = action.payload.status;
        });
    });
