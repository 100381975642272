import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { ActiveAccountNumberState } from "../types/general";
import { activeAccountActions, ActiveAccountAction } from "../actions/activeAccount";

export const initialState: ActiveAccountNumberState = {
    accountInfo: {
        accountId: undefined,
        accountNumber: undefined,
    },
    account: null,
};

export const activeAccountReducer = createReducer<ActiveAccountNumberState, ActiveAccountAction>(
    initialState
).handleAction(activeAccountActions.selectFromAccountsSuccess, (state, action) => {
    return produce(state, (draftState) => {
        draftState.accountInfo = action.payload.activeAccount;
        draftState.account = action.payload.account;
    });
});
