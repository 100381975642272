import { call, put, select, takeEvery } from "redux-saga/effects";

import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { smeTransactionsActions } from "../actions/smeTransactions";
import * as transactionsApi from "../api/smeTransactions";
import {
    E_SmeTransactionsActionConstants,
    T_SmeFeatureTransactionsState,
    T_SmeTransactionsInitializerType,
} from "../types";
import { ActionType } from "typesafe-actions";
import { formatSmeTransactions } from "../payload";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* handleSmeFetchTransactions(
    action: ActionType<typeof smeTransactionsActions.smeFetchTransactionsTrigger>
) {
    try {
        const {
            token,
            mockApiCalls,
            gwUrl,
            gwUrlV2,
            shownInStatement,
            size,
            excludeTransactionTypes,
            accountId,
            country,
        } = (yield select(
            (state: T_SmeFeatureTransactionsState) => state.transactions.config
        )) as T_SmeTransactionsInitializerType;

        if (!token) {
            logger.log("got no token, back to front page");
            return (window.location.href = "/");
        }

        const formattedStartDate =
            action.payload?.startDate ?? new Date("2000-01-01").toISOString().split("T")[0];
        const formattedEndDate = action.payload?.endDate ?? new Date().toISOString().split("T")[0];

        const transactions = yield call(transactionsApi.fetchSmeTransactions, {
            token,
            gwUrl,
            gwUrlV2,
            accountId,
            mockApiCalls,
            shownInStatement,
            size,
            excludeTransactionTypes,
            country,
            startDate: formattedStartDate.toString(),
            endDate: formattedEndDate.toString(),
        });

        const payload = formatSmeTransactions(transactions, country);

        yield put(smeTransactionsActions.smeFetchTransactionsSuccess(payload));
    } catch (e) {
        logger.log("fetch transactions trigger failed", e);
    }
}

export function* watchSmeFetchTransactions() {
    yield takeEvery(
        E_SmeTransactionsActionConstants.FETCH_TRANSACTIONS_TRIGGER,
        handleSmeFetchTransactions
    );
}
