import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";
import { T_YearlyOverviewData } from "@opr-finance/feature-reporting";

type T_ApiError = {
    message: string;
};

type T_Transaction = {
    amount: string;
    transactionDate: string;
    date: string;
    type: string;
};

type T_AccountData = {
    companyName: string;
    organisationNumber: string;
    accountNumber: string;
    applicationNumber?: string;
    creationDate: string;
};

type T_TransactionsDataPaginated = {
    pageNumber: number;
    transactions: T_Transaction[];
};
type T_TransactionData = T_AccountData & {
    dateFrom: string;
    dateTo: string;
    pagesTotal: number;
    transactions: T_TransactionsDataPaginated[];
};

const logger = new ConsoleLogger({ level: LOG_LEVEL });

const documentLayout = {
    pageHeight: 297,
    headerHeight: 40,
    loanInfoHeight: 45,
    titleHeight: 10,
    tableHeadheight: 10,
    footerHeight: 40,
    tableLineHeight: 3.33,
    margin: 20,
};

const createPaginatedData = (transactions: T_Transaction[]) => {
    const transactionsTotal = transactions.length;
    const transactionsDataPaginated: T_TransactionsDataPaginated[] = [];
    const {
        pageHeight,
        headerHeight,
        loanInfoHeight,
        titleHeight,
        tableHeadheight,
        footerHeight,
        tableLineHeight,
        margin,
    } = documentLayout;
    let pageNumber = 1;
    let pagesTotal = 1;
    const tableHeightFirstPage =
        pageHeight -
        (headerHeight + loanInfoHeight + titleHeight + tableHeadheight + footerHeight + margin * 2);

    const linesFirstPage = Math.ceil(tableHeightFirstPage / tableLineHeight);
    logger.log(tableHeightFirstPage, linesFirstPage, transactionsTotal);
    if (transactionsTotal <= linesFirstPage) {
        const dataFirstPage: T_TransactionsDataPaginated = { pageNumber, transactions };
        transactionsDataPaginated.push(dataFirstPage);
        logger.log("transactionsDataPaginated 1", transactionsDataPaginated);
    } else {
        const tableHeightNextPages =
            pageHeight - (headerHeight + tableHeadheight + footerHeight + margin * 2);
        const linesNextPages = tableHeightNextPages / tableLineHeight;
        pagesTotal = Math.ceil((transactionsTotal - linesFirstPage) / linesNextPages);
        const transactionsFirtPage = transactions.slice(0, linesFirstPage);
        const dataFirstPage = { pageNumber, transactions: transactionsFirtPage };
        transactionsDataPaginated.push(dataFirstPage);
        let startIndex = linesFirstPage;
        let endIndex = startIndex + linesNextPages;
        do {
            const transactionsNexpage = transactions.slice(startIndex, endIndex);
            pageNumber += 1;
            startIndex = endIndex;
            endIndex = startIndex + linesNextPages;
            logger.log(pageNumber, startIndex, endIndex, transactionsNexpage);
            const dataNextpage = { pageNumber, transactions: transactionsNexpage };
            transactionsDataPaginated.push(dataNextpage);
        } while (pageNumber <= pagesTotal);
        logger.log("transactionsDataPaginated totalpages", transactionsDataPaginated, pagesTotal);
    }
    logger.log("transactionsDataPaginated totalpages", transactionsDataPaginated, pagesTotal);
    return { transactionsDataPaginated, pagesTotal };
};
export const createTransactionsData = (
    transactions: T_Transaction[],
    accountData: T_AccountData,
    startDate: string,
    endDate: string
) => {
    const dateFrom = startDate ? startDate : accountData.creationDate;
    const { transactionsDataPaginated, pagesTotal } = createPaginatedData(transactions);
    const transactionsData: T_TransactionData = {
        companyName: accountData.companyName,
        organisationNumber: accountData.organisationNumber,
        accountNumber: accountData.accountNumber,
        applicationNumber: accountData.applicationNumber,
        creationDate: accountData.creationDate,
        dateFrom,
        dateTo: endDate,
        pagesTotal,
        transactions: transactionsDataPaginated,
    };
    return transactionsData;
};

export const generatePdf = async (
    data: T_TransactionData | T_YearlyOverviewData,
    pdfGeneratorUrl: string,
    authToken: string
) => {
    const config = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: authToken,
        },
        body: JSON.stringify(data),
    };

    try {
        const response: Response = await fetch(pdfGeneratorUrl, config);

        if (response.status === 403) window.location.href = "/expired";

        if (response.status === 201 || response.status === 200) {
            const blob = await response.blob();
            const fileUrl = URL.createObjectURL(blob);

            return fileUrl;
        }
        throw new Error("Unhandled Error");
    } catch (e: unknown) {
        const err = e as T_ApiError;
        console.log(err);
        throw new Error(err.message);
    }
};
