import React, { FunctionComponent } from "react";
import { css } from "@styled-system/css";
import styled from "styled-components";
import { Link } from "react-scroll";
import { TScrollStyleProps, TScrollProps } from "./types";

const ScrollContainer: FunctionComponent<TScrollStyleProps> = styled.div<TScrollStyleProps>`
    ${(props: TScrollStyleProps) => {
        return css(props.styleConfig);
    }}
`;

export function Scroll({
    children,
    to,
    styleConfig,
    spy = true,
    smooth = true,
    offset = 0,
    duration = 500,
}: TScrollProps) {
    return (
        <ScrollContainer styleConfig={styleConfig}>
            <Link to={to} spy={spy} smooth={smooth} offset={offset} duration={duration}>
                {children}
            </Link>
        </ScrollContainer>
    );
}
