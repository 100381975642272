import React, { ReactNode } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { TableTheme, AppTheme } from "@opr-finance/themes";
import { Paginator, PaginatorProps, PaginatorListOption } from "../Paginator/Paginator";
import { Container } from "./Table.styled";

export type TData<T> = T[];
export type TColumns<T> = TableColumn<T>[];

export type TableProps<T> = {
    theme: AppTheme;
    data: TData<T>;
    columns: TColumns<T>;
    onRowClicked?: (row: T) => void;
    itemsPerPage: number;
    leftIcon: ReactNode;
    rightIcon: ReactNode;
    pagesIcon: ReactNode;
    noTableHead?: boolean;
    translation: {
        labelPrefix: string;
        noDataText: string;
    };
};

export function Table<T>(props: TableProps<T>) {
    const pages = Math.ceil(props.data.length / props.itemsPerPage);

    return (
        <Container>
            <DataTable
                dense={true}
                noTableHead={props.noTableHead ? props.noTableHead : false}
                noDataComponent={props.translation.noDataText}
                columns={props.columns}
                data={props.data}
                pagination
                paginationPerPage={props.itemsPerPage}
                paginationComponent={(paginationProps: PaginatorProps) => {
                    return (
                        <Paginator
                            {...paginationProps}
                            paginationIconPrevious={props.leftIcon}
                            paginationIconNext={props.rightIcon}
                            pagesIcon={props.pagesIcon}
                            labelPrefix={props.translation.labelPrefix}
                            divider={props.itemsPerPage}
                            pages={pages}
                        />
                    );
                }}
                customStyles={{
                    table: {
                        style: {
                            border: 0,
                        },
                    },
                    headCells: {
                        style: {
                            fontFamily: props.theme.table.header.fontFamily,
                            fontSize: props.theme.table.header.fontSize,
                            fontWeight: props.theme.table.header.fontWeight,
                            color: props.theme.table.header.color,
                            margin: 0,
                            padding: 0,
                        },
                    },
                    rows: {
                        style: {
                            alignItems: "spaceBetween",
                        },
                    },
                    cells: {
                        style: {
                            fontFamily: props.theme.table.cell.fontFamily,
                            fontSize: props.theme.table.cell.fontSize,
                            color: props.theme.table.cell.color,
                            margin: 0,
                            padding: 0,
                            minHeight: "32px",
                            width: "100%",
                            display: "flex",
                            alignItems: "flexStart",
                            justifyContent: "flexStart",
                        },
                    },
                    pagination: {
                        style: {
                            fontFamily: "arial",
                            fontSize: "14px",
                            textAlign: "center",
                        },
                        pageButtonsStyle: {
                            backgroundColor: props.theme.table.cell.color,
                            borderRadius: 0,
                            color: "#fff",
                        },
                    },
                }}
                onRowClicked={(row: T) => {
                    if (props.onRowClicked) {
                        props.onRowClicked(row);
                    }
                }}
            />
        </Container>
    );
}
