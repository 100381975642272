import { LOG_LEVEL, LogLevel } from "./environment";

/** Signature of a logging function */
export interface LogFn {
    (message?: any, ...optionalParams: any[]): void;
}

/** Basic logger interface */
export interface Logger {
    log: LogFn;
    warn: LogFn;
    error: LogFn;
}

const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => {};

/** Logger which outputs to the browser console */
export class ConsoleLogger implements Logger {
    readonly log: LogFn;
    readonly debug: LogFn;
    readonly warn: LogFn;
    readonly error: LogFn;
    readonly table: LogFn;

    constructor(options?: { level?: LogLevel }) {
        const { level } = options || {};

        this.log = console.log.bind(console);
        this.debug = console.warn.bind(console);
        this.warn = console.warn.bind(console);
        this.error = console.error.bind(console);
        this.table = console.table.bind(console);

        if (level === "none") {
            this.log = NO_OP;
            this.debug = NO_OP;
            this.warn = NO_OP;
            this.error = NO_OP;
            this.table = NO_OP;

            return;
        }

        //in the future we can implemented different log levels e.g. like this
        // if (level === "error") {
        //     this.warn = NO_OP;
        //     this.log = NO_OP;

        //     return;
        // }

        // if (level === "warn") {
        //     this.log = NO_OP;

        //     return;
        // }
    }
}

export const logger = new ConsoleLogger({ level: LOG_LEVEL });
