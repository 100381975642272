import React from "react";

import { StyledGrid } from "@opr-finance/component-grid";

import { GlobalStyles } from "./GlobalStyles";
import { StyledLayoutProps } from "../types";

export function StyledLayout(props: StyledLayoutProps) {
    return (
        <StyledGrid id="app-base-container" styleConfig={{ root: props.styleConfig.rootGrid }}>
            <GlobalStyles bodyBacgroundColor={props.styleConfig.bodyBackgroundColor} />
            <StyledGrid styleConfig={{ root: props.styleConfig.fullWidthGrid }}>
                {props.header}
            </StyledGrid>
            <StyledGrid styleConfig={{ root: props.styleConfig.mainContentGrid }}>
                {props.children}
            </StyledGrid>
            <StyledGrid styleConfig={{ root: props.styleConfig.fullWidthGrid }}>
                {props.footer}
            </StyledGrid>
        </StyledGrid>
    );
}
