import React from "react";
import { useIntl } from "react-intl";

import { Wrapper } from "@opr-finance/component-wrapper";
import { Text } from "@opr-finance/component-fonts";
import { Icon } from "@opr-finance/component-icon";
import { StyledGrid } from "@opr-finance/component-grid";
import { FooterStyles } from "@opr-finance/theme-flex-online";
import { Font } from "@opr-finance/component-fonts";
import { Link } from "@opr-finance/component-link-to";

import { FooterColumns } from "../index";
import { FooterComponentStyle } from "../types";
import { messages } from "./messages";

const Column1 = (props: FooterComponentStyle) => {
    const { formatMessage: fm } = useIntl();
    return (
        <StyledGrid styleConfig={{ root: FooterStyles.footerColumn() }}>
            <Font as="p" styleConfig={{ root: props.styleConfig.fontFooterTitle }}>
                {fm(props.inputConfig.messages.headingLinks)}
            </Font>
            <Font as="p" styleConfig={{ root: props.styleConfig.fontFooterText }}>
                <Link
                    styleConfig={{ root: FooterStyles.footerLink() }}
                    href={`${process.env.REACT_APP_REGISTRY_DESCRIPTION}`}
                    target="_blank"
                >
                    {fm(props.inputConfig.messages.registrationDocumentLinkText)}
                </Link>
            </Font>
            <Font as="p" styleConfig={{ root: props.styleConfig.fontFooterText }}>
                <Link
                    styleConfig={{ root: FooterStyles.footerLink }}
                    href={`${process.env.REACT_APP_COOKIES}`}
                    target="_blank"
                >
                    {fm(props.inputConfig.messages.cookiesLinkText)}
                </Link>
            </Font>
        </StyledGrid>
    );
};

const Column2 = (props: FooterComponentStyle) => {
    const { formatMessage: fm } = useIntl();
    return (
        <StyledGrid styleConfig={{ root: FooterStyles.footerColumn() }}>
            <StyledGrid styleConfig={{ root: props.styleConfig.fontFooterTitle }}>
                {fm(props.inputConfig.messages.headingCustomerService)}
            </StyledGrid>
            <StyledGrid styleConfig={{ root: FooterStyles.footerRow }}>
                <Font styleConfig={{ root: FooterStyles.footerIconWrapper }}>
                    <Icon icon={["far", "clock"]} size="2x" />
                </Font>
                {fm(props.inputConfig.messages.openingHours)}
            </StyledGrid>
            <StyledGrid styleConfig={{ root: FooterStyles.footerRow }}>
                <Font styleConfig={{ root: FooterStyles.footerIconWrapper }}>
                    <Icon icon={["fas", "phone-alt"]} size="2x" />
                </Font>
                {fm(props.inputConfig.messages.phoneNumber)}
            </StyledGrid>
            <StyledGrid styleConfig={{ root: FooterStyles.footerRow }}>
                <Font styleConfig={{ root: FooterStyles.footerIconWrapper }}>
                    <Icon icon={["fas", "at"]} size="2x" />
                </Font>
                <Link
                    styleConfig={{ root: FooterStyles.footerLink }}
                    href={fm(props.inputConfig.messages.emailTextLink)}
                >
                    {fm(props.inputConfig.messages.emailText)}
                </Link>
            </StyledGrid>
            <StyledGrid styleConfig={{ root: FooterStyles.footerRow }}>
                <Font styleConfig={{ root: FooterStyles.footerIconWrapper }}>
                    <Icon icon={["fas", "question"]} size="2x" />
                </Font>
                <Link
                    styleConfig={{ root: FooterStyles.footerLink }}
                    href={`${process.env.REACT_APP_FAQ_URL}`}
                    target="_blank"
                >
                    {fm(props.inputConfig.messages.FAQLinkText)}
                </Link>
            </StyledGrid>
        </StyledGrid>
    );
};

const Column3 = (props: FooterComponentStyle) => {
    const { formatMessage: fm } = useIntl();
    return (
        <StyledGrid styleConfig={{ root: FooterStyles.footerColumn() }}>
            <Font styleConfig={{ root: props.styleConfig.fontFooterTitle }}>
                {fm(props.inputConfig.messages.headingContactAddress)}
            </Font>

            <Font as="p" styleConfig={{ root: props.styleConfig.fontFooterText }}>
                {fm(props.inputConfig.messages.companyName)}
            </Font>
            <Font as="p" styleConfig={{ root: props.styleConfig.fontFooterText }}>
                {fm(props.inputConfig.messages.companyAddress)}
            </Font>
            <Font as="p" styleConfig={{ root: props.styleConfig.fontFooterText }}>
                {fm(props.inputConfig.messages.companyZip)}
            </Font>
            <Font as="p" styleConfig={{ root: props.styleConfig.fontFooterText }}>
                {fm(props.inputConfig.messages.businessID)}
            </Font>
        </StyledGrid>
    );
};

export const StyledFooterContent = (props: FooterComponentStyle) => {
    var footerColumns = [
        { content: () => Column1(props), order: [2, 2, 1], marginBottom: ["16px", "0px"] },
        { content: () => Column2(props), order: [1, 1, 2], marginBottom: ["16px", "0ox"] },
        { content: () => Column3(props), order: [3] },
    ];

    return footerColumns;
};
