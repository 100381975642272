import { takeEvery, put, call } from "redux-saga/effects";

import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { noticesActions } from "../actions/notices";
import * as noticesApi from "../api/notices";
import { ContentfulActionConstants, NoticesType } from "../types/contentful";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* handleFetchNoticesTrigger(
    action: ReturnType<typeof noticesActions.fetchNoticesTrigger>
) {
    try {
        const notices: NoticesType[] = yield call(noticesApi.getNotices, {
            contentParams: action.payload.contentParams,
            clientParams: action.payload.clientParams,
        });
        yield put(noticesActions.fetchNoticesSuccess(notices));
    } catch (e) {
        logger.log("Fetch notices failed");
        logger.log(e);
    }
}

export function* watchFetchNoticesTrigger() {
    yield takeEvery(ContentfulActionConstants.FETCH_NOTICES_TRIGGER, handleFetchNoticesTrigger);
}
