import { PhoneNumberUtil } from "google-libphonenumber";
const phoneUtil = PhoneNumberUtil.getInstance();

export function isValidPhoneNumberSe(value: string | undefined): boolean {
    if (!value) return true;
    const validString = /^[+\d]?([[:space:]]|\d*)*$/g; // plus OR digit on the first position AND digits OR white spaces
    const test = validString.test(value);
    if (test) {
        const number = phoneUtil.parse(value, "NL");
        return number && phoneUtil.isValidNumberForRegion(number, "SE");
    } else {
        return false;
    }
}

export function isValidSwedishBankAccount(value: string): boolean {
    if (!value) return false;
    const expr = /^\d+[-]+\d+$/;
    const reg = new RegExp(expr);
    const result = reg.exec(value);
    return result ? true : false;
}

export const validateZipCode = (value: string | undefined): boolean => {
    if (!value) return true;
    const trimmedString = value.replace(/\s/g, "");
    if (trimmedString.length !== 5) return false;
    const regexExpr = /^\d+$/;
    const result = regexExpr.test(trimmedString);

    return result ? true : false;
};
