import { isAfter, lastDayOfMonth, subMonths } from "date-fns";
import { T_FormattedInvoice } from "@opr-finance/feature-statements";

export const getLastReportingYear = (date: Date) => {
    const currentDay = date.getDate();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();
    return currentMonth === 1 && currentDay < 10 ? currentYear - 1 : currentYear;
};

export const getReportingYears = (start, end, step) =>
    Array.from({ length: (end - start) / step + 1 }, (value, index) => start + index * step);

export const getLastReportMonth = (date: Date) => {
    // Finland: the report for a certain month can be created not earlier then the 10th day of next month
    const latestReportingMonth = isAfter(date, getCurrentMonthReportDate(date))
        ? lastDayOfMonth(subMonths(date, 1))
        : lastDayOfMonth(subMonths(date, 2));
    return latestReportingMonth;
};

const getCurrentMonthReportDate = (date: Date) =>
    new Date(`${date.getMonth() + 1}.10.${date.getFullYear()}`);

export const getEarliestReportDate = (date: Date) => {
    // Finland: the report is available as soon as account created, money withdrawn and invoice created on day 10th of the next month
    const earliestReportMonth = lastDayOfMonth(subMonths(date, 1));
    return earliestReportMonth;
};

export const findMinDate = (arr: T_FormattedInvoice[]) => {
    const validDates = arr
        .filter((obj) => obj.createdDate && !isNaN(new Date(obj.createdDate).getTime()))
        .map((obj) => new Date(obj.createdDate!).getTime());

    const minDate = validDates.length > 0 ? new Date(Math.min(...validDates)) : new Date();
    return minDate;
};
