import React from "react";
import { useIntl } from "react-intl";
import { StyledGrid } from "@opr-finance/component-grid";
import { StyledPageTitle } from "@opr-finance/component-content";
import { Font } from "@opr-finance/component-fonts";
import { StyledLink } from "@opr-finance/component-link-to";

import { LogoutPageProps } from "./types";
import { messages } from "./messages";

export function LogoutPage(props: LogoutPageProps) {
    const { formatMessage: fm } = useIntl();
    return (
        <StyledGrid styleConfig={{ root: props.styleConfig.rootStyles }}>
            <StyledPageTitle
                title={fm(messages.pageTitle)}
                styleConfig={{
                    pageTitleContainer: props.styleConfig.titleBox,
                    pageTitleText: props.styleConfig.pageTitle,
                }}
            />
            <StyledGrid styleConfig={{ root: props.styleConfig.pageContent }}>
                <Font as="p" styleConfig={{ root: props.styleConfig.content }}>
                    {`${fm(messages.bodyText)} `}
                    <StyledLink
                        href={`${process.env.REACT_APP_LOGOUT_REDIRECT}`}
                        styleConfig={{ root: props.styleConfig.link }}
                    >
                        {fm(messages.linkText)}
                    </StyledLink>
                </Font>
            </StyledGrid>
        </StyledGrid>
    );
}
