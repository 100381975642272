import * as VP from "@opr-finance/api-definitions";
import { FeatureLoginState } from "@opr-finance/feature-lfp-login";
import { DefaultInitializerType } from "@opr-finance/utils";

export enum TransactionsActionConstants {
    FETCH_TRANSACTIONS_INITIALIZER = "TRANSACTIONS/INITIALIZER",
    FETCH_TRANSACTIONS_TRIGGER = "TRANSACTIONS/FETCH_TRANSACTIONS_TRIGGER",
    FETCH_TRANSACTIONS_SUCCESS = "TRANSACTIONS/FETCH_TRANSACTIONS_SUCCESS",
    FETCH_TRANSACTIONS_ERROR = "TRANSACTIONS/FETCH_TRANSACTIONS_ERROR",
}

export enum TransactionsItemConf {
    DEFAULT_FORMAT_DATE = "dd'.'MM'.'yyyy",
}

export enum TransactionStatuses {
    LOAN_ADJUSTMENT_INTEREST_TRANSACTION = "LoanAdjustmentInterestTransaction",
    LOAN_TRANCHE_DISBURSEMENT_TRANSACTION = "LoanTrancheDisbursementTransaction",
    OVER_PAYMENT_AFTER_LAST_STATEMENT_TRANSACTION = "OverPaymentAfterLastStatementTransaction",
    PAYBACK_TO_CUSTOMER_FROM_ACCOUNT_TRANSACTION = "PaybackToCustomerFromAccountTransaction",
    PAYMENT_TRANSACTION = "PaymentTransaction",
    STATEMENT_AMORTIZATION_TRANSACTION = "StatementAmortizationTransaction",
    STATEMENT_FEE_TRANSACTION = "StatementFeeTransaction",
    STATEMENT_INTEREST_TRANSACTION = "StatementInterestTransaction",
    STATEMENT_REMINDER_FEE_TRANSACTION = "StatementReminderFeeTransaction",
    STATEMENT_ROUNDING_TRANSACTION = "StatementRoundingTransaction",
    STATEMENT_STARTUP_FEE_TRANSACTION = "StatementStartupFeeTransaction",
    STATEMENT_TRANCHE_STARTUP_FEE_TRANSACTION = "StatementTrancheStartupFeeTransaction",
    STATEMENT_DIRECT_DEBIT_FEE_TRANSACTION = "StatementDirectDebitFeeTransaction",
}

export type MessageData = {
    contentfulKey: string;
    messageId: string;
};

export type GeneratedMessages = {
    [key: string]: {
        id: string;
    };
};

type T_TmpVpSchemasTransaction = VP.components["schemas"]["Transaction"];

export interface FormattedTransactions extends T_TmpVpSchemasTransaction {
    formattedDate: string;
    formattedAmount: string;
    formattedTitle: string;
}

export type TransactionsInitializerType = DefaultInitializerType & {
    countPerPage: number;
    shownInStatement: boolean;
    excludeTransactionTypes?: string;
    accountId: string | undefined;
};

export type TransactionReducerState = {
    transactions: Array<VP.components["schemas"]["Transaction"]> | [];
    formatted: Array<FormattedTransactions> | [];
    config: TransactionsInitializerType;
};

/** REFACTOR: This fixes type issues in Flex Online AppState as it needs to use feature-luvittaja-login,
 *  type T is defined as the LoginState being used, by default it is FeatureLoginState from feature-lfp-login
 *  todo: check if login state can be completely removed from this type
 */
export type FeatureTransactionsState<T = FeatureLoginState> = {
    transactions: TransactionReducerState;
} & T;

/* API definitions for VP v19 and up */
export type T_VpTransactionsForRevolving = VP.operations["getLoanTransactionsForRevolving"];
export type T_VpTransactionsParameters = T_VpTransactionsForRevolving["parameters"];
export type T_VpTransactionsQueryParameters = T_VpTransactionsParameters["query"];
export type T_VpTransactionsResponse =
    T_VpTransactionsForRevolving["responses"]["200"]["content"]["application/hal+json"];

export type FetchTransactionsRequest = {
    accountId?: T_VpTransactionsParameters["path"]["accountId"];
} & T_VpTransactionsQueryParameters &
    DefaultInitializerType;
