import { createAction, ActionType } from "typesafe-actions";
import {
    E_ReportingActionConstants,
    T_ReportingInitializerType,
    T_ReportingPayload,
} from "../types";
import { T_ReportingSuccessAction } from "../types";

export const reportingActions = {
    reportingInitializer: createAction(
        E_ReportingActionConstants.REPORTING_INITIALIZER
    )<T_ReportingInitializerType>(),
    reportingTrigger: createAction(
        E_ReportingActionConstants.REPORTING_TRIGGER
    )<T_ReportingPayload>(),
    reportingSuccess: createAction(
        E_ReportingActionConstants.REPORTING_SUCCESS
    )<T_ReportingSuccessAction>(),
    reportingError: createAction(
        E_ReportingActionConstants.REPORTING_ERROR
    )<T_ReportingSuccessAction>(),
};

export type T_ReportingAction = ActionType<typeof reportingActions>;
