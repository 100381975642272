import { takeEvery, put, call, select } from "redux-saga/effects";
import { ActionType } from "typesafe-actions";

import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { ErrorActionConstants } from "../types/error";
import { errorActions } from "../actions/error";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* watchErrorTrigger() {
    yield takeEvery(ErrorActionConstants.ERROR_TRIGGER, handleErrorTrigger);
}

export function* handleErrorTrigger(action: ActionType<typeof errorActions.errorTrigger>) {
    try {
        logger.log("got error", action.payload.message);

        window.location.href = action.payload.url;
    } catch (e) {
        logger.log("error trigger failed");
    }
}
