import * as VP from "@opr-finance/api-definitions";

type T_VpAccountState = VP.components["schemas"]["AccountState"];

type T_CheckWithdrawEligibility = {
    blockedStatus?: boolean;
    availableCreditLimit: number | undefined;
    accountState: T_VpAccountState | undefined;
    unpaidAmount: number | undefined;
    overdueDays: number | undefined;
    isIbanMissing?: boolean;
};

type T_CheckTopupEligibility = {
    creditLimit: number;
    accountState: T_VpAccountState | undefined;
    overdueDays: number | undefined;
    paidInvoicesCount: number;
    blockedStatus?: boolean;
};

export type T_TopupRules = {
    creditLimit: number;
    accountState: T_VpAccountState[];
    overdueDays: number;
    paidInvoices: number;
    blockedStatus?: boolean;
};
export type T_WithdrawalRules = {
    minWithdrawal: number;
    accountState: T_VpAccountState[];
    overdueDays: number;
    blockedStatus?: boolean;
};

export enum E_WithdrawNotEligibleReason {
    OVERDUE_INVOICES = "OVERDUE_INVOICES",
    AVAILABLE_CREDIT_TOO_SMALL = "AVAILABLE_CREDIT_TOO_SMALL",
    NOT_ABLE_TO_MAKE_WITHDRAWAL = "NOT_ABLE_TO_MAKE_WITHDRAWAL",
    NO_IBAN = "NO_IBAN",
}

export const checkWithdrawEligibility = (
    data: T_CheckWithdrawEligibility,
    limits: T_WithdrawalRules
): boolean => {
    if (
        data.accountState === undefined &&
        data.overdueDays === undefined &&
        data.unpaidAmount === undefined &&
        data.availableCreditLimit === undefined
    )
        return false;
    if (data.availableCreditLimit && data.availableCreditLimit < limits.minWithdrawal) return false;
    if (!limits.accountState.some((x) => x === data.accountState)) return false;
    if (data.overdueDays && data.overdueDays > limits.overdueDays) return false;
    if (data.isIbanMissing) return false;
    if (typeof limits.blockedStatus !== "undefined" && limits.blockedStatus !== data.blockedStatus)
        return false;
    return true;
};

export const checkWithdrawEligibilityWithReasons = (
    data: T_CheckWithdrawEligibility,
    limits: T_WithdrawalRules
): { isCustomerEligible: boolean; notEligibleReason?: string } => {
    const reasons: string[] = [];

    if (
        data.accountState === undefined ||
        data.overdueDays === undefined ||
        data.unpaidAmount === undefined ||
        data.availableCreditLimit === undefined
    ) {
        return { isCustomerEligible: false };
    }

    // prio 1 reason
    if (data.overdueDays > limits.overdueDays) {
        return {
            isCustomerEligible: false,
            notEligibleReason: E_WithdrawNotEligibleReason.OVERDUE_INVOICES,
        };
    }

    // prio 2 reason
    if (data.availableCreditLimit && data.availableCreditLimit < limits.minWithdrawal)
        return {
            isCustomerEligible: false,
            notEligibleReason: E_WithdrawNotEligibleReason.AVAILABLE_CREDIT_TOO_SMALL,
        };

    // prio 3 reason
    if (typeof limits.blockedStatus !== "undefined" && limits.blockedStatus !== data.blockedStatus)
        return {
            isCustomerEligible: false,
            notEligibleReason: E_WithdrawNotEligibleReason.NOT_ABLE_TO_MAKE_WITHDRAWAL,
        };

    // other reason
    if (!limits.accountState.some((x) => x === data.accountState))
        return {
            isCustomerEligible: false,
            notEligibleReason: E_WithdrawNotEligibleReason.NOT_ABLE_TO_MAKE_WITHDRAWAL,
        };

    // other reason
    if (data.isIbanMissing)
        return {
            isCustomerEligible: false,
            notEligibleReason: E_WithdrawNotEligibleReason.NO_IBAN,
        };

    return { isCustomerEligible: true };
};

export const checkTopupEligibility = (
    data: T_CheckTopupEligibility,
    limits: T_TopupRules
): boolean => {
    if (
        data.creditLimit === undefined &&
        data.accountState === undefined &&
        data.overdueDays === undefined &&
        data.paidInvoicesCount === undefined
    )
        return false;
    if (data.creditLimit === limits.creditLimit) return false;
    if (!limits.accountState.some((x) => x === data.accountState)) return false;
    if (data.overdueDays && data.overdueDays > limits.overdueDays) return false;
    if (data.paidInvoicesCount < limits.paidInvoices) return false;
    if (typeof limits.blockedStatus !== "undefined" && limits.blockedStatus !== data.blockedStatus)
        return false;
    return true;
};
