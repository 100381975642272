import React, { FunctionComponent, ReactNode } from "react";
import css, { SystemStyleObject } from "@styled-system/css";
import styled from "styled-components";

type BaseImgContainerProps = {
    styleConfig: SystemStyleObject;
    children: ReactNode;
};

type ImageContainerProps = {
    styleConfig: SystemStyleObject;
    children: ReactNode;
};

const BaseImgContainer: FunctionComponent<BaseImgContainerProps> = styled.div<BaseImgContainerProps>`
    ${(props: BaseImgContainerProps) => {
        return css(props.styleConfig);
    }}
`;

export const ImageContainer = (props: ImageContainerProps) => {
    return <BaseImgContainer styleConfig={props.styleConfig}>{props.children}</BaseImgContainer>;
};
