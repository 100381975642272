import { ActionType, createAction } from "typesafe-actions";
import {
    ContentfulActionConstants,
    RecentNewsType,
    ContentfulParams,
    RecentNewsContentfulConfig,
} from "../types/contentful";

export const recentNewsActions = {
    fetchRecentNewsTrigger: createAction(ContentfulActionConstants.FETCH_NEWS_TRIGGER)<
        ContentfulParams<RecentNewsContentfulConfig>
    >(),
    fetchRecentNewsSuccess: createAction(ContentfulActionConstants.FETCH_NEWS_SUCCESS)<
        RecentNewsType[]
    >(),
    fetchRecentNewsError: createAction(ContentfulActionConstants.FETCH_NEWS_ERROR)(),
};

export type RecentNewsAction = ActionType<typeof recentNewsActions>;
