import React from "react";

import { StyledGrid } from "@opr-finance/component-grid";
import {
    ButtonStyles,
    FontsStyles,
    LoanPageStyles,
    ModalStyles,
} from "@opr-finance/theme-flex-online";
import { StyledButton } from "@opr-finance/component-button";
import { Icon } from "@opr-finance/component-icon";
import { Modal } from "@opr-finance/component-modal-dialog";
import { Font } from "@opr-finance/component-fonts";

import { T_ModalProps } from "./types";

export function NotificationModal(props: T_ModalProps) {
    return (
        <Modal
            modalTitle={props.modalTitle}
            isOpen={props.modalOpen}
            onClick={() => props.toggleModalOpen(false)}
            styleConfig={{
                closeIcon: ModalStyles.modalCloseIcon(),
                overlay: ModalStyles.modalOverlay(),
                content: ModalStyles.modalContentScroll({ height: ["fit-content"] }),
                title: ModalStyles.modalTitle(),
                titleText: ModalStyles.titleText(),
            }}>
            <StyledGrid styleConfig={{ root: LoanPageStyles.modalLayout() }}>
                <Font styleConfig={{ root: FontsStyles.fontContentText() }} as="p">
                    {props.modalContent}
                </Font>
                <StyledButton
                    onClick={props.handleClick}
                    styleConfig={{
                        root: ButtonStyles.greenButtonStyles({ marginTop: "42px" }),
                    }}>
                    <Font styleConfig={{ root: ButtonStyles.buttonFontStyles() }}>
                        {props.modalButtonText}
                        <Icon icon={["fa", "angle-double-right"]} />{" "}
                    </Font>
                </StyledButton>
            </StyledGrid>
        </Modal>
    );
}
