import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";
import { T_FetchInvoicesRequest, T_VpInvoicesResponse } from "../types/invoices";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

type T_HeadersWithMock = HeadersInit & {
    "X-statements-type"?:
        | "no-invoices"
        | "normal"
        | "all-paid"
        | "rescoring-clean"
        | "rescoring-overdue-day"
        | "rescoring-overdue-statements";
};

export async function fetchInvoices(data: T_FetchInvoicesRequest): Promise<T_VpInvoicesResponse> {
    const url = `${data.gwUrl}/api/loan/v8/accounts/${data.activeAccountId}/invoices`;
    const method = "GET";

    const headers: T_HeadersWithMock = {
        "content-type": "application/json",
        authorization: data.token as string,
        ...(data.mockApiCalls && { "X-statements-type": "rescoring-overdue-day" }),
    };

    const result: Response = await fetch(url, {
        method,
        headers,
    });
    const response = await result.json();

    if (data.mockApiCalls) {
        logger.log("MOCK DATA - COMPANY INVOICE DATA");
        logger.log(response);
    }

    return response;
}
