import { PhoneNumberUtil } from "google-libphonenumber";
const phoneUtil = PhoneNumberUtil.getInstance();

export function isValidPhoneNumberNl(value: string | undefined): boolean {
    if (!value) return true;
    const validString = /^[+\d]?([[:space:]]|\d*)*$/g; // plus OR digit on the first position AND digits OR white spaces
    const test = validString.test(value);
    if (test) {
        const number = phoneUtil.parse(value, "NL");

        return number && phoneUtil.isValidNumberForRegion(number, "NL");
    } else {
        return false;
    }
}
