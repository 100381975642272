import React, { CSSProperties } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";

type T_LoaderProps = {
    color?: string;
    size: SizeProp;
    icon: IconProp;
    styles?: CSSProperties;
};

export function ButtonLoader(props: T_LoaderProps) {
    return (
        <FontAwesomeIcon
            style={props.styles}
            color={props.color}
            size={props.size} // sizes are 1x, 2x, 3x...
            className="fa-spin"
            icon={props.icon}
        />
    );
}
