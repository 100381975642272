import { useEffect, useRef, DependencyList } from "react";

export function useSkipFirstRender(callback: () => void, dependencies: DependencyList) {
    const firstRenderRef = useRef(true);

    useEffect(() => {
        if (firstRenderRef.current) {
            firstRenderRef.current = false;
            return;
        }
        callback();
    }, dependencies);
}
