import { DefaultInitializerType, httpFaker } from "@opr-finance/utils";
import { IGeneratePdfRequest } from "../types/document";
import * as VP from "@opr-finance/api-definitions";

export type FetchDocumentRequest = { documentId: string | undefined } & DefaultInitializerType;
export type FetchAccountDocumentsMeta = {
    accountNumber: string | undefined;
} & DefaultInitializerType;

export async function fetchDocument(data: FetchDocumentRequest): Promise<Blob> {
    const url = `${data.gwUrl}/api/storage/v2/documents/${data.documentId}/file`;
    const method = "GET";

    if (data.mockApiCalls) {
        return new Blob(["Dummy pdf"], { type: "application/octet-stream" });
    }

    const result: Response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/pdf",
            "X-Auth-Token": data.token as string,
        },
    });

    return result.blob();
}

export async function generateAndFetchPdf(data: IGeneratePdfRequest): Promise<Blob> {
    const url = data.params.mockApiCalls
        ? `${data.params.generatorUrlBase}/api/online/v1/${data.params.source}/${data.params.documentType}?localDev=true`
        : `${data.params.generatorUrlBase}/api/online/v1/${data.params.source}/${data.params.documentType}`;

    const method = "POST";

    const result: Response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/pdf",
            "X-Auth-Token": data.params.token as string,
        },
        body: JSON.stringify({ ...data.payload }),
    });

    return result.blob();
}

//! NOTE: VP.Document in Promise changed to any since latest api-definitions
//! doesn't have documentation / types for this endpoint
export async function fetchAccountDocumentsMeta(data: FetchAccountDocumentsMeta): Promise<any> {
    const url = `${data.gwUrl}/api/storage/v2/documents`;
    const method = "POST";

    if (data.mockApiCalls) {
        //return httpFaker<null, VP.Document>(url, method, null, []);
        return httpFaker<null, any>(url, method, null, [
            {
                documentId: "07d5eeeaf1bc44e182d9a8e30cb10b10",
                filename: "promissoryNote_6151232_20210216.pdff",
                uploadDate: 1613383980630,
                length: 47737,
                metadata: {
                    documentId: "07d5eeeaf1bc44e182d9a8e30cb10b10",
                    version: 0,
                    creator: "ronlin",
                    ssn: null,
                    customerId: "50213",
                    applicationId: null,
                    applicantId: null,
                    accountNumber: "8100075",
                    purchaseId: null,
                    documentType: "PROMISSORY_NOTE",
                    contentType: "application/pdf",
                    signStatus: "SIGNED",
                    signType: "MANUAL",
                    note: null,
                    autoCleanDays: null,
                    serviceName: null,
                    encoding: null,
                    customerType: null,
                    tags: {},
                    lastUpdateDate: 1613383980630,
                    smeId: null,
                    uploadChannel: null,
                },
            },
            {
                documentId: "49a4a387b5d2448a8274453d47a515c3",
                filename: "latest_document.pdf",
                uploadDate: 1613483980630,
                length: 4854,
                metadata: {
                    documentId: "49a4a387b5d2448a8274453d47a515c3",
                    version: 0,
                    creator: "ronlin",
                    ssn: null,
                    customerId: "50213",
                    applicationId: null,
                    applicantId: null,
                    accountNumber: "8100075",
                    purchaseId: null,
                    documentType: "PROMISSORY_NOTE",
                    contentType: "application/pdf",
                    signStatus: "SIGNED",
                    signType: "MANUAL",
                    note: null,
                    autoCleanDays: null,
                    serviceName: null,
                    encoding: null,
                    customerType: null,
                    tags: {},
                    lastUpdateDate: 1613483980630,
                    smeId: null,
                    uploadChannel: null,
                },
            },
            {
                documentId: "602bcfccdae3d022cc3a40a0",
                filename: "promissoryNote_6151232_20210216.pdf",
                uploadDate: 1613483980630,
                length: 84254,
                metadata: {
                    documentId: "602bcfccdae3d022cc3a40a0",
                    version: 0,
                    creator: "madbee",
                    ssn: null,
                    customerId: "50009",
                    applicationId: null,
                    applicantId: null,
                    accountNumber: "6101364",
                    purchaseId: null,
                    documentType: "CONTRACT",
                    contentType: "application/pdf",
                    signStatus: "SIGNED",
                    signType: "MANUAL",
                    note: null,
                    autoCleanDays: null,
                    serviceName: null,
                    encoding: null,
                    customerType: null,
                    tags: {
                        MANUAL_UPLOAD: "1",
                    },
                    lastUpdateDate: 1613483980630,
                    smeId: null,
                    uploadChannel: "CSR",
                },
            },
        ]);
    }

    const result: Response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/json",
            "X-Auth-Token": data.token as string,
        },
        body: JSON.stringify({
            "metadata.accountNumber": data.accountNumber,
        }),
    });

    return result.json();
}
