import { ActionType, createAction } from "typesafe-actions";
import {
    ContentfulActionConstants,
    ContentfulParams,
    translationMap,
    TranslationStaticContentConfig,
} from "../types/contentful";

export const translationStatiContentActions = {
    fetchTranslationStaticContentTrigger: createAction(
        ContentfulActionConstants.FETCH_TRANSLATION_STATIC_CONTENT_TRIGGER
    )<ContentfulParams<TranslationStaticContentConfig>>(),
    fetchTranslationStaticContentSuccess: createAction(
        ContentfulActionConstants.FETCH_TRANSLATION_STATIC_CONTENT_SUCCESS
    )<translationMap<string>>(),
    fetchTranslationStaticContentError: createAction(
        ContentfulActionConstants.FETCH_TRANSLATION_STATIC_CONTENT_ERROR
    )(),
};

export type TranslationStaticContentAction = ActionType<typeof translationStatiContentActions>;
