import React from "react";
import { BasicSectionBox } from "./SectionBox.styled";
import { SectionBoxProps } from "./types";

export function SectionBox(props: SectionBoxProps) {
    return (
        <BasicSectionBox variant={props.variant ? props.variant : "basic"} {...props}>
            {props.children}
        </BasicSectionBox>
    );
}
