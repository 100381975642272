import { createReducer } from "typesafe-actions";
import { produce } from "immer";

import { LoginReducerState } from "../types/general";
import { LoginAction, loginActions } from "../actions/login";

export const initialState: LoginReducerState = {
    token: undefined,
    isInitialized: false,
    isValidating: false,
    isValidToken: false,
    loginUrl: "",
    successUrl: "",
    errorUrl: "",
    noLoanUrl: "",
    mockApiCalls: false,
    expiredTokenUrl: "",
    gwUrl: "",
};

export const loginReducer = createReducer<LoginReducerState, LoginAction>(initialState)
    .handleAction(loginActions.loginInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.loginUrl = action.payload.loginUrl;
            draftState.successUrl = action.payload.successUrl;
            draftState.errorUrl = action.payload.errorUrl;
            draftState.mockApiCalls = action.payload.mockApiCalls;
            draftState.noLoanUrl = action.payload.noLoanUrl;
            draftState.expiredTokenUrl = action.payload.expiredTokenUrl;
            draftState.gwUrl = action.payload.gwUrl;
        });
    })
    .handleAction(loginActions.updateIsValidatingToken, (state, action) => {
        return produce(state, (draftState) => {
            draftState.isValidating = action.payload;
        });
    })
    .handleAction(loginActions.updateToken, (state, action) => {
        return produce(state, (draftState) => {
            draftState.token = action.payload;
        });
    })
    .handleAction(loginActions.updateIsValidToken, (state, action) => {
        return produce(state, (draftState) => {
            draftState.isValidToken = action.payload;
        });
    })
    .handleAction(loginActions.updateIsInitialized, (state, action) => {
        return produce(state, (draftState) => {
            draftState.isInitialized = action.payload;
        });
    });
