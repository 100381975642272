import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { T_FetchDocumentRequest } from "../types";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export async function fetchDocument(data: T_FetchDocumentRequest): Promise<Blob> {
    const url = `${data.gwUrl}/api/loan/v8/accounts/${data.accountId}/documents/${data.documentId}/download`;
    const method = "GET";

    if (data.mockApiCalls) {
        return new Blob(["Dummy pdf"], { type: "application/octet-stream" });
    }

    logger.log("test url--", url);

    const result: Response = await fetch(url, {
        method,
        headers: {
            "content-type": "application/pdf",
            Authorization: data.token as string,
        },
    });

    return result.blob();
}
