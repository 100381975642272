import React from "react";
import { format, parseISO } from "date-fns";
import { DateFormat } from "./types";

export type DateFormatterProps = {
    date?: string;
    format?: DateFormat;
};

export function dateFormatter(props: DateFormatterProps): string {
    return format(
        props.date ? new Date(parseISO(props.date)) : new Date(),
        props.format ? props.format : DateFormat.Finland
    );
}

export function DateFormatter(props: DateFormatterProps) {
    try {
        const value = format(
            props.date ? new Date(parseISO(props.date)) : new Date(),
            props.format ? props.format : DateFormat.Finland
        );
        return <>{value}</>;
    } catch (e) {
        return null;
    }
}
