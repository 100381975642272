import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    .rdt_TableCol_Sortable {
        color: #124890;
        :hover {
            color: #124890;
        }
    }

    .rdt_TableHeader {
        display: none;
        visibility: hidden;
    }

    .rdt_TableCell:first-of-type {
        border-left: 1px solid #e0e0e0;
    }

    .rdt_TableCell:last-of-type {
        border-right: 1px solid #e0e0e0;
    }

    .rdt_TableHeadRow {
        border-left: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-top: 1px solid #e0e0e0;
    }

    .rdt_TableRow:last-of-type {
        border-bottom: 1px solid #e0e0e0;
        border-right: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;

        .rdt_TableCell:first-of-type {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 0;
        }

        .rdt_TableCell:last-of-type {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom-left-radius: 0;

            border-left: 0;
            border-top: 0;
            border-right: 0;
            border-bottom: 0;
        }
    }
`;
