import { ActionType, createAction } from "typesafe-actions";
import {
    E_SmeTransactionsActionConstants,
    T_SmeTransactionsInitializerType,
    T_SmeTransactionsSuccessAction,
} from "../types";
import { T_SmeTransactionTriggerPayload } from "../types/smeTransactions";

export const smeTransactionsActions = {
    smeFetchTransactionsInitializer: createAction(
        E_SmeTransactionsActionConstants.FETCH_TRANSACTIONS_INITIALIZER
    )<T_SmeTransactionsInitializerType>(),
    smeFetchTransactionsTrigger: createAction(
        E_SmeTransactionsActionConstants.FETCH_TRANSACTIONS_TRIGGER
    )<T_SmeTransactionTriggerPayload>(),
    smeFetchTransactionsSuccess: createAction(
        E_SmeTransactionsActionConstants.FETCH_TRANSACTIONS_SUCCESS
    )<T_SmeTransactionsSuccessAction>(),
    smeFetchTransactionsError: createAction(
        E_SmeTransactionsActionConstants.FETCH_TRANSACTIONS_ERROR
    )(),
};

export type SmeTransactionsAction = ActionType<typeof smeTransactionsActions>;
