import { ActionType, createAction } from "typesafe-actions";
import {
    CustomerActionConstants,
    T_VpAccountInfo,
    T_VpRevolvingAccount,
    T_VpUserInformation,
} from "../types/general";

export const customerActions = {
    customerInitializer: createAction(CustomerActionConstants.CUSTOMER_INITIALIZER)<{
        productType: "DEPOSIT" | "REGULAR_LOAN" | "CREDIT_LOAN";
    }>(),
    fetchCustomerTrigger: createAction(CustomerActionConstants.FETCH_CUSTOMER_TRIGGER)(),
    fetchCustomer: createAction(CustomerActionConstants.FETCH_CUSTOMER)(),
    fetchCustomerSuccess: createAction(
        CustomerActionConstants.FETCH_CUSTOMER_SUCCESS
    )<T_VpUserInformation>(),
    setSelectedAccount: createAction(CustomerActionConstants.SELECT_ACCOUNT)<T_VpAccountInfo>(),
    setRevolvingAccount: createAction(
        CustomerActionConstants.SET_REVOLVING_ACCOUNT
    )<T_VpRevolvingAccount>(),
};

export type CustomerAction = ActionType<typeof customerActions>;
