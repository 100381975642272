import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { FeatureLoginState } from "../types/general";

export type IsAuthenticatedProps = {
    isAuthenticatedComponent: ReactNode;
    isNotAuthenticatedComponent: ReactNode;
};

export function IsAuthenticated(props: IsAuthenticatedProps) {
    const token = useSelector((state: FeatureLoginState) => {
        return state.login.token;
    });

    return <>{token ? props.isAuthenticatedComponent : props.isNotAuthenticatedComponent}</>;
}
