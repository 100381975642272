import React, { ReactNode } from "react";
import {
    Container,
    Links,
    LinksTitle,
    Title,
    MorePageCloseText,
    MorePageTitleText,
    RestContainer,
} from "./MorePage.styled";
import { Text, Font } from "@opr-finance/component-fonts";
import { HeaderStyles } from "@opr-finance/theme-flex-online";

export type LinkContainerProps = {
    linksTitle: string;
    links: ReactNode;
};

export function LinkContainer(props: LinkContainerProps) {
    return (
        <Links>
            <LinksTitle>
                <Font styleConfig={{ root: HeaderStyles.mobileNavMoreItemsHeading }}>
                    {/* {props.linksTitle} */}
                </Font>
            </LinksTitle>
            {props.links}
        </Links>
    );
}

export type MorePageProps = {
    title: string;
    header?: ReactNode;
    after?: ReactNode;
    isVisible: boolean;
    onCloseClick: () => void;
} & LinkContainerProps;

export function MorePage(props: MorePageProps) {
    if (!props.isVisible) {
        return null;
    }
    return (
        <Container>
            {props.header}
            <Title>
                <MorePageTitleText>
                    <Font styleConfig={{ root: HeaderStyles.mobileNavMoreTitle }}>
                        {props.title}
                    </Font>
                </MorePageTitleText>
                <MorePageCloseText>
                    <Font
                        styleConfig={{ root: HeaderStyles.mobileNavMoreClose }}
                        onClick={props.onCloseClick}
                        as="p">
                        X
                    </Font>
                </MorePageCloseText>
            </Title>
            <LinkContainer linksTitle={props.linksTitle} links={props.links} />
            <RestContainer>{props.after}</RestContainer>
        </Container>
    );
}
