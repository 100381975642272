import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "flex-online-fi-user-page-title",
    },
    Subheading2: {
        id: "flex-online-fi-topup-page-subheading-2",
    },
    Subheading3: {
        id: "flex-online-fi-topup-page-subheading-3",
    },
    companyName: {
        id: "flex-online-fi-topup-page-text-company-name",
    },
    businessID: {
        id: "flex-online-fi-topup-page-text-company-businessID",
    },
    guaranteeName: {
        id: "flex-online-fi-topup-page-text-guarantee-name",
    },
    guaranteeSSN: {
        id: "flex-online-fi-topup-page-text-guarantee-SSN",
    },
    guaranteePhone: {
        id: "flex-online-fi-topup-page-text-guarantee-phone",
    },
    TopInfo: {
        id: "flex-online-fi-user-page-top-info",
    },
    textCustomerNumber: {
        id: "flex-online-fi-user-page-text-customer-number",
    },
    textSSN: {
        id: "flex-online-fi-user-page-text-SSN",
    },
    textEmail: {
        id: "flex-online-fi-user-page-text-email",
    },
    textAddress: {
        id: "flex-online-fi-user-page-text-address",
    },
    alertTextRequired: {
        id: "flex-online-fi-topup-text-alert-required-field",
    },
    editBtnText: {
        id: "flex-online-fi-user-page-edit-button-text",
    },
    saveBtnText: {
        id: "flex-online-fi-user-page-save-button-text",
    },
});
