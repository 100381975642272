import { T_GatewayProps } from "./types/general";

const mock: boolean = (process.env.REACT_APP_MOCK as string) === "1";
const baseApiPath: string = process.env.REACT_APP_API_PATH as string;
const baseAuthPath: string = process.env.REACT_APP_AUTH_PATH as string;
const baseUrl: string = mock
    ? (process.env.REACT_APP_MOCK_GW_URL as string)
    : (process.env.REACT_APP_GW_URL as string);

export const getGwProps = (): T_GatewayProps => {
    const gwProps = {
        mock,
        baseApiPath,
        baseUrl,
        fullApiUrl: baseUrl + baseApiPath,
        fullAuthUrl: baseUrl + baseAuthPath,
    };

    return gwProps;
};
