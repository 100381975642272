import * as VP from "@opr-finance/api-definitions";
import { DefaultInitializerType } from "@opr-finance/utils";

export type T_SmeLoan_AccountPayload = {};

export type T_SmeLoan_AccountIdPayload = {
    activeAccountId: string;
};

export enum E_BankNumberCountry {
    FINLAND = "IBAN",
    SWEDEN = "SE-CLEARING",
    NETHERLANDS = "IBAN",
}

export type T_AccountUpdatePayload = {
    bankAccount: string | undefined;
    accountId: string | undefined;
    country: keyof typeof E_BankNumberCountry;
};

export enum E_SmeLoan_AccountActionConstants {
    ACCOUNT_INIT_FEATURE = "ACCOUNT/INIT_FEATURE",
    SET_ACCOUNT_ID = "ACCOUNT/SET_ID",
    FETCH_ACCOUNT_TRIGGER = "ACCOUNT/FETCH_ACCOUNT_TRIGGER",
    FETCH_ACCOUNT_SUCCESS = "ACCOUNT/FETCH_ACCOUNT_SUCCESS",
    FETCH_ACCOUNT_ERROR = "ACCOUNT/FETCH_ACCOUNT_ERROR",
    UPDATE_ACCOUNT_TRIGGER = "UPDATE_ACCOUNT_TRIGGER",
    UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS",
}

export type T_SmeLoan_AccountInitializerType = DefaultInitializerType & {
    noAuth: string;
    errorUrl: string;
    noLoanUrl: string;
};

export type T_VpRegularLoanAccountForCreationResponse =
    VP.components["schemas"]["RegularLoanAccountForCreationResponseV8"];
export type T_VpAccountState = VP.components["schemas"]["AccountState"];
export type T_VpDisbursementAccount = VP.components["schemas"]["DisbursementAccountV1"];

export type T_SmeLoan_AccountReducerState = {
    account: T_VpRegularLoanAccountForCreationResponse | null;
    activeAccountId?: string | null;
    accountState: T_VpAccountState | undefined;
    config: T_SmeLoan_AccountInitializerType;
};

export type T_SmeLoan_FeatureAccountState = {
    account: T_SmeLoan_AccountReducerState;
};

export type T_AccountUpdateRequest = {
    token: string;
    gwUrl: string;
    accountId: string;
    bankAccount: string;
    country: keyof typeof E_BankNumberCountry;
};

export enum E_AllowedAccountStates {
    OPEN = "OPEN",
    PENDING = "PENDING",
    COLLECTION = "COLLECTION",
}
