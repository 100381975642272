import { takeEvery, put, call } from "redux-saga/effects";

import { loaderActions } from "@opr-finance/feature-loader";
import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { translationStatiContentActions } from "../actions/translationStaticContent";
import * as translationApi from "../api/translationStaticContent";
import { ContentfulActionConstants } from "../types/contentful";
import { IStaticContent } from "../types/generated";
import { getTranslations } from "../payload/payload";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* handleFetchTranslationStaticContentTrigger(
    action: ReturnType<typeof translationStatiContentActions.fetchTranslationStaticContentTrigger>
) {
    try {
        const translation: IStaticContent[] = yield call(
            translationApi.getTranslationStaticContent,
            {
                contentParams: action.payload.contentParams,
                clientParams: action.payload.clientParams,
            }
        );

        // Handle Payload

        const payload = getTranslations(translation);

        yield put(translationStatiContentActions.fetchTranslationStaticContentSuccess(payload));
        yield put(
            loaderActions.loaderTrigger({
                loading: false,
            })
        );
    } catch (e) {
        yield put(translationStatiContentActions.fetchTranslationStaticContentError());
        logger.log("Fetch translations failed!");
    }
}

export function* watchFetchTranslationStaticContentTrigger() {
    yield takeEvery(
        ContentfulActionConstants.FETCH_TRANSLATION_STATIC_CONTENT_TRIGGER,
        handleFetchTranslationStaticContentTrigger
    );
}
