export { httpFaker } from "./api";
export type {
    WithAuthToken,
    WithMockApiCalls,
    WithGwUrl,
    DefaultInitializerType,
    FontStyleProps,
    ButtonStyleProps,
    InputStyleProps,
} from "./types/general";
export { add, remove, pickOff, pickOne } from "./list";
export { extractBirthdateFromSsn } from "./extractBirthdateFromSsn";
export { generateKey } from "./key";
export { useWindowSize } from "./hooks/useWindowSize";
export { useQuery } from "./hooks/useQuery";
export { useSkipFirstRender } from "./hooks/useSkipFirstRender";
export { isNumber } from "./isNumber";
export { formatOrgNumFin } from "./formatOrgNumFin";
export { formatOrgNumSwe } from "./formatOrgNumSwe";
export { roundValueToMinOrMax } from "./roundValueToMinOrMax";
export { dateFormatter } from "./dateFormatter";
export { history } from "./history";
export { extractYear, substractYear, lastDayOfGivenYear } from "./dateManipulation";
export {
    checkWithdrawEligibility,
    checkWithdrawEligibilityWithReasons,
    checkTopupEligibility,
} from "./eligibilityCheck";
export type { T_WithdrawalRules, T_TopupRules } from "./eligibilityCheck";
export {
    getTrackingData,
    getCookie,
    getGAClientId,
    getCookieConsents,
    getSourceInfo,
    getClientApplicationId,
    sendGAEvent,
    sendGAConversion,
    sendDataLayerEvent,
    getUtmParameter,
} from "./trackingUtils";
export { sumByPropertyAndType } from "./transactionBlockUtils";
