import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";

import { StyledGrid } from "@opr-finance/component-grid";
import { StyledPageTitle } from "@opr-finance/component-content";
import { Image } from "@opr-finance/component-image";
import { FrontPageStyles } from "@opr-finance/theme-flex-online";
import { add, remove } from "@opr-finance/utils";
import { Scroll } from "@opr-finance/component-scroll";
import { Font } from "@opr-finance/component-fonts";
import { E_AllowedAccountStates } from "@opr-finance/feature-account";
import { smeDocumentActions } from "@opr-finance/feature-document";

import { selectOverdueDays, selectUnpaidAmount, selectNotPaidStatements } from "../../selectors";
import euroImage from "../../images/yritysluotto-flex-joustoluotto-nosta.svg";
import { FrontPageProps } from "./types";
import { messages } from "./messages";
import { AppState, E_Routes } from "../../types/general";
import { AccountDetailBlock } from "../../components/AccountDetailBlock/AccountDetailBlock";
import { NewsBlock } from "../../components/NewsBlock/NewsBlock";
import { WithdrawBlock } from "../../components/WithdrawBlock/WithdrawBlock";
import { CollectionBlock } from "../../components/CollectionBlock";
import { NotificationModal } from "../../components/NotificationModal";

type LocationState = {
    component: string;
};

export function FrontPage(props: FrontPageProps) {
    const dispatch = useDispatch();
    const location = useLocation<LocationState>();
    const { formatMessage: fm } = useIntl();
    const component = location.state && location.state.component;

    const documentInUse = process.env.REACT_APP_DISPLAY_DOCUMENTS === "1" ? true : false;
    const authenticated = useSelector((state: AppState) => state.session.authenticated);
    const accountState = useSelector((state: AppState) => state.account.accountState);
    const { defaultPromissoryNoteId, activeAccountId, token, gwUrl, mockApiCalls } = useSelector(
        (state: AppState) => state.document.config
    );

    const account = useSelector((state: AppState) => {
        return state.account.account;
    });

    const [modalOpen, toggleModalOpen] = useState(false);
    const handleModalButtonClick = () => {
        window.location.href = "/user";
    };
    const notPaid = useSelector(selectNotPaidStatements);

    const overdueDays = useSelector(selectOverdueDays);

    const unpaidAmount = useSelector(selectUnpaidAmount);

    const [applicationData, setApplicationData] = useState<{ withdrawnAmount: string }>({
        withdrawnAmount: "0",
    });

    const [validForms, setValidForms] = useState<string[]>(() => {
        const defaultValids: string[] = ["withdrawnForm"];
        return defaultValids;
    });

    function handleChange(isValid, formName, form) {
        if (isValid) {
            setValidForms(add(validForms, formName));
        } else {
            setValidForms(remove(validForms, formName, (a, b) => a === b));
        }

        setApplicationData({
            ...applicationData,
            ...form,
        });
    }
    const handleClick = () => {
        dispatch(
            smeDocumentActions.smeFetchDocumentTrigger({
                token,
                gwUrl,
                mockApiCalls,
                accountId: activeAccountId,
                documentId: defaultPromissoryNoteId,
            })
        );
    };

    if (!authenticated) {
        return <Redirect to={E_Routes.ROOT} />;
    }
    if (accountState === E_AllowedAccountStates.COLLECTION) {
        return (
            <StyledGrid styleConfig={{ root: FrontPageStyles.accountCollectionMainContainer() }}>
                <CollectionBlock
                    collectionStateHeading={fm(messages.collectionStateHeading)}
                    collectionStateText={fm(messages.collectionStateText)}
                    collectionStatePhoneText={fm(messages.collectionStatePhoneText)}
                    collectionStatePhoneNumber={fm(messages.collectionStatePhoneNumber)}
                    collectionStateEmailText={fm(messages.collectionStateEmailText)}
                    collectionStateEmailLinkLink={fm(messages.collectionStateEmailLinkLink)}
                    collectionStateEmailLinkText={fm(messages.collectionStateEmailLinkText)}
                    defaultPromissoryNoteId={defaultPromissoryNoteId}
                    handleClick={handleClick}
                    documentInUse={documentInUse}
                />
            </StyledGrid>
        );
    }

    const availableCreditLimit = account?.availableCreditLimit;
    const isIBANRegistered = account?.disbursementAccount?.externalAccountNumber ? true : false;
    //const isIBANRegistered = false;
    useEffect(() => {
        if (component && component === "withdraw") {
            window.scrollTo({ top: 500, behavior: "smooth" });
        }
    });

    useEffect(() => {
        if (!isIBANRegistered) {
            toggleModalOpen(true);
        } else return;
    }, []);

    return (
        <StyledGrid styleConfig={{ root: FrontPageStyles.frontPageRootStyles() }}>
            <StyledPageTitle
                title={fm(messages.frontPageTitle)}
                styleConfig={{
                    pageTitleContainer: props.styleConfig.titleBox,
                    pageTitleText: props.styleConfig.pageTitle,
                }}
            />
            <Scroll to="withdraw-section">
                <StyledGrid styleConfig={{ root: props.styleConfig.nostoContainer }}>
                    <Image
                        imageAlt="euro sign"
                        imageSrc={euroImage}
                        styleConfig={{ root: props.styleConfig.nostoImage }}></Image>
                    <Font styleConfig={{ root: props.styleConfig.nostoText }}>Tee nosto</Font>
                </StyledGrid>
            </Scroll>
            <StyledGrid styleConfig={{ root: props.styleConfig.mainContentContainer }}>
                <AccountDetailBlock />
                <NewsBlock />
                <WithdrawBlock
                    availableCreditLimit={availableCreditLimit}
                    overdueDays={overdueDays}
                    unpaidAmount={unpaidAmount}
                    accountState={account?.state}
                    blockedStatus={account?.blockedStatus ? account.blockedStatus : false}
                    isIbanMissing={!isIBANRegistered}
                    handleChange={handleChange}
                />
            </StyledGrid>
            <NotificationModal
                toggleModalOpen={toggleModalOpen}
                modalOpen={modalOpen}
                handleClick={handleModalButtonClick}
                modalTitle={fm(messages.noIbanModalTitle)}
                modalContent={fm(messages.noIbanModalText)}
                modalButtonText={fm(messages.noIbanModalButtonText)}
            />
        </StyledGrid>
    );
}
