import { createAction, ActionType } from "typesafe-actions";
import {
    E_LoginSessionActionConstants,
    T_LoginSessionPayload,
    T_LoginSessionTokenPayload,
    T_LoginSessionConfig,
} from "../types";

export const loginSessionActions = {
    loginSessionInitializer: createAction(
        E_LoginSessionActionConstants.LOGIN_SESSION_INIT
    )<T_LoginSessionConfig>(),
    loginSessionTrigger: createAction(
        E_LoginSessionActionConstants.LOGIN_SESSION_TRIGGER
    )<T_LoginSessionPayload>(),
    loginSessionTokenTrigger: createAction(
        E_LoginSessionActionConstants.LOGIN_SESSION_TOKEN_TRIGGER
    )(),
    loginSessionComplete: createAction(E_LoginSessionActionConstants.LOGIN_SESSION_COMPLETE)(),
    loginSessionTokenSuccess: createAction(
        E_LoginSessionActionConstants.LOGIN_SESSION_TOKEN_SUCCESS
    )<T_LoginSessionTokenPayload>(),
    loginSessionEnd: createAction(E_LoginSessionActionConstants.LOGIN_SESSION_END)(),
    loginSessionVerify: createAction(E_LoginSessionActionConstants.LOGIN_SESSION_TOKEN_VERIFY)(),
};

export type LoginSessionAction = ActionType<typeof loginSessionActions>;
