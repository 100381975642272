import { ReactNode } from "react";
import { SystemStyleObject } from "@styled-system/css";

export enum OrderEnum {
    LEFT_RIGHT = "row",
    RIGHT_LEFT = "row-reverse",
    UP_DOWN = "column",
    DOWN_UP = "column-reverse",
}

export type BaseStyleProps = {
    children: ReactNode;
    styleConfig?: {
        root: SystemStyleObject;
    };
};

export type ContainerProps = {
    children: ReactNode;
} & BaseStyleProps;

export type LabeledFieldProps = {
    styleConfig?: {
        container: SystemStyleObject;
        label: SystemStyleObject;
        field: SystemStyleObject;
        errorMsg?: SystemStyleObject;
    };
    label: ReactNode;
    field: string | ReactNode;
    errorMessage?: ReactNode;
    isValid?: boolean;
    order?: `${OrderEnum}`;
};
