import { ActionType, createAction } from "typesafe-actions";
import {
    ActiveAccountConstants,
    ActiveAccountPayloadSuccess,
    ActiveAccountPayload,
} from "../types/general";
import * as Leandev from "@opr-finance/api-definitions";

export const activeAccountActions = {
    selectFromAccounts: createAction(
        ActiveAccountConstants.ACTIVE_ACCOUNT_NUMBER_SELECT
    )<ActiveAccountPayload>(),
    selectFromAccountsSuccess: createAction(
        ActiveAccountConstants.ACTIVE_ACCOUNT_NUMBER_SELECTED
    )<ActiveAccountPayloadSuccess>(),
    selectFromAccountsError: createAction(
        ActiveAccountConstants.ACTIVE_ACCOUNT_NUMBER_NOT_SELECTED
    )(),
};

export type ActiveAccountAction = ActionType<typeof activeAccountActions>;
