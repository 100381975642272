import { ActionType, createAction } from "typesafe-actions";
import {
    ContentfulActionConstants,
    NoticesType,
    ContentfulParams,
    NoticesContentfulConfig,
} from "../types/contentful";

export const noticesActions = {
    fetchNoticesTrigger: createAction(ContentfulActionConstants.FETCH_NOTICES_TRIGGER)<
        ContentfulParams<NoticesContentfulConfig>
    >(),
    fetchNoticesSuccess: createAction(ContentfulActionConstants.FETCH_NOTICES_SUCCESS)<
        NoticesType[]
    >(),
    fetchNoticesError: createAction(ContentfulActionConstants.FETCH_NOTICES_ERROR)(),
};

export type NoticesAction = ActionType<typeof noticesActions>;
