import { format, parseISO } from "date-fns";
import * as VP from "@opr-finance/api-definitions";
import { currency, CurrencyFormat, CurrencyLocale } from "@opr-finance/component-currency";

import {
    E_SmeTransactionsItemConf,
    I_SmeFormattedTransactions,
    T_SmeTransactionsSuccessAction,
} from "../types";
import { T_SmeTransactionsResponse } from "../types/smeTransactions";
import { getDateFormat } from "../utils/dateFormat";

export const formatSmeTransactions = (
    transactions: T_SmeTransactionsResponse,
    currencyCountry: string
): T_SmeTransactionsSuccessAction => {
    const formatAmount = (amount: number) => {
        return currency({
            value: amount,
            locale: CurrencyLocale[currencyCountry],
            currency: CurrencyFormat[currencyCountry],
        });
    };

    const dateFormat = getDateFormat(currencyCountry);

    const formatted = (
        transactions: Array<VP.components["schemas"]["Transaction"]>
    ): Array<I_SmeFormattedTransactions> =>
        transactions.map(({ transactionDate, transactionType, transactionMsg, amount }) => {
            const formattedDate =
                typeof transactionDate !== "undefined"
                    ? format(parseISO(transactionDate), dateFormat)
                    : "";
            const formattedTitle = transactionMsg ? transactionMsg : "null";
            const formattedAmount = formatAmount(Number(amount));
            return {
                transactionType,
                transactionDate,
                formattedDate,
                formattedAmount,
                amount,
                formattedTitle,
            };
        });

    return {
        statementTransactions: formatted(transactions.statementTransactions),
    };
};
