import React from "react";
import { SystemStyleObject } from "@styled-system/css";

import { GridContainer, Grid, GridContent } from "@opr-finance/component-grid";
import { BoxContainer, Box } from "@opr-finance/component-box-container";
import { Font, Text } from "@opr-finance/component-fonts";

export type PageTitleProps = {
    title: string;
    isTitleCentered?: boolean;
};

export function PageTitle(props: PageTitleProps) {
    return (
        <GridContainer>
            <Grid width={"100%"}>
                <GridContent>
                    <BoxContainer>
                        <Text variant={props.isTitleCentered ? "pageTitleCentered" : "pageTitle"}>
                            {props.title}
                        </Text>
                    </BoxContainer>
                </GridContent>
            </Grid>
        </GridContainer>
    );
}

export type PageTitleContainerProps = {
    title: string;
    isTitleCentered: boolean;
    styleConfig: {
        box: SystemStyleObject;
        pageTitle: SystemStyleObject;
    };
};

export const PageTitleContainer = (props: PageTitleContainerProps) => (
    <GridContainer>
        <Grid width={"100%"}>
            <GridContent>
                <Box styleConfig={{ root: props.styleConfig.box }}>
                    <Font styleConfig={{ root: props.styleConfig.pageTitle }}>{props.title}</Font>
                </Box>
            </GridContent>
        </Grid>
    </GridContainer>
);
