import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "flex-online-fi-contact-page-title",
    },
    headingContact: {
        id: "flex-online-fi-contact-page-heading-boxLeft",
    },
    headingGeneral: {
        id: "flex-online-fi-contact-page-heading-boxRight",
    },
    emailLink: {
        id: "flex-online-fi-email-customerService-link",
    },
    emailLinkText: {
        id: "flex-online-fi-email-customerService-text",
    },
    emailText: {
        id: "flex-online-fi-text-email",
    },
    FAQLinkText: {
        id: "flex-online-fi-link-FAQ-text",
    },
    FAQLink: {
        id: "flex-online-fi-link-FAQ-link",
    },
    subheadingLeft1: {
        id: "flex-online-fi-contact-page-subheading-boxLeft-1",
    },
    subheadingLeft2: {
        id: "flex-online-fi-contact-page-subheading-boxLeft-2",
    },
    subheadingRight1: {
        id: "flex-online-fi-contact-page-subheading-boxRight-1",
    },
    subheadingRight2: {
        id: "flex-online-fi-contact-page-subheading-boxRight-2",
    },
    subheadingRight3: {
        id: "flex-online-fi-contact-page-subheading-boxRight-3",
    },
    textOpen: {
        id: "flex-online-fi-contact-page-text-open",
    },
    textOpeningHours: {
        id: "flex-online-fi-contact-page-text-openingHours",
    },
    textPhone: {
        id: "flex-online-fi-text-phone",
    },
    textPhoneNumber: {
        id: "flex-online-fi-phone-number",
    },
    textContent1: {
        id: "flex-online-fi-contact-page-text-content-1",
    },
    textContent2: {
        id: "flex-online-fi-text-contact-page-content-2",
    },
    textContent3: {
        id: "flex-online-fi-contact-page-text-content-3",
    },
    textContent2LinkText: {
        id: "flex-online-fi-contact-page-text-content-2-link-text",
    },
    textContent2Link: {
        id: "flex-online-fi-contact-page-text-content-2-link-link",
    },
    sveaPhone: {
        id: "flex-online-fi-contact-page-svea-phone",
    },
    sveaEmailLink: {
        id: "flex-online-fi-contact-page-svea-email-link",
    },
    sveaEmailText: {
        id: "flex-online-fi-contact-page-svea-email-text",
    },
});
