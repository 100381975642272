export enum E_LoginSessionActionConstants {
    LOGIN_SESSION_INIT = "LOGIN_SESSION/INIT",
    LOGIN_SESSION_TRIGGER = "LOGIN_SESSION/TRIGGER",
    LOGIN_SESSION_COMPLETE = "LOGIN_SESSION_COMPLETE",
    LOGIN_SESSION_TOKEN_TRIGGER = "LOGIN_SESSION/TOKEN/TRIGGER",
    LOGIN_SESSION_TOKEN_SUCCESS = "LOGIN_SESSION/TOKEN/SUCCESS",
    LOGIN_SESSION_TOKEN_VERIFY = "LOGIN_SESSION/TOKEN/VERIFY",
    LOGIN_SESSION_END = "LOGIN_SESSION/END",
}

export enum E_CID {
    YRITYSLUOTTO_FI = "finland-yritysluotto",
    FLEX_ONLINE_FI = "finland-flex-online",
    FLEX_ONLINE_SE = "sweden-flex-online",
    FLEX_ONLINE_NL = "netherlands-flex-online",
}

export type T_LoginSessionPayload = {
    bank?: string;
    cid?: string;
    lang?: string;
    method?: string;
    instance?: "flex-online-finland" | "flex-online-sweden" | "flex-online-netherlands";
};

export type T_LoginRoles = "customer" | "act-as-customer";

export type T_LoginSessionTokenPayload = {
    token: string;
    authenticated: boolean;
    role: T_LoginRoles;
    ssn: string;
    name?: string;
};

export type T_LoginSessionConfig = {
    cid: string;
    authUrl: string;
    verifyUrl: string;
    expiredUrl: string;
    errorUrl: string;
    lang: string;
    successUrl?: string;
    getSessionInfoUrl?: string;
    endSessionApiUrl?: string;
    logoutUrl?: string;
};

export type T_LoginSessionReducerState = {
    token: string;
    authenticated: boolean;
    role: T_LoginRoles;
    ssn: string;
    name?: string;
    config: T_LoginSessionConfig;
};

export type T_FeatureLoginSessionState = {
    session: T_LoginSessionReducerState;
};

export type T_SessionInfoAttrs = {
    fullname: string;
    ssn: string;
    birthdate: string;
    firstname: string;
    lastname: string;
    reference: string;
};

export type T_SessionInfoResult = {
    attrs: T_SessionInfoAttrs;
    name: string;
};
export type T_GetSessionInfoPayload = {
    url: string;
    id: string;
};
