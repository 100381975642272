import { AppLoader } from "@opr-finance/feature-loader";
import React from "react";

export const PrepairingPdfComponent = () => {
    return (
        <div
            style={{
                width: "100%",
                height: "95vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <AppLoader />
        </div>
    );
};
