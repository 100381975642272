import { takeEvery, put, call, take, takeLatest, race } from "redux-saga/effects";
import { errorActions } from "@opr-finance/feature-error";

import { AppActionConstants, appActions } from "../actions/actions";
import {
    getAccountData,
    getInvoiceData,
    setAccountConfig,
    setInvoiceConfig,
    setTransactionsConfig,
} from "./common.saga";
import { engagementActions } from "@opr-finance/feature-sme-customer";

export function* watchTopupPageTrigger() {
    yield takeLatest(AppActionConstants.TOPUP_PAGE_TRIGGER, handleTopupPageTrigger);
}

export function* handleTopupPageTrigger() {
    try {
        yield put(engagementActions.engagementTrigger());
        const { engagementSuccess, engagementError } = yield race({
            engagementSuccess: take(engagementActions.engagementSuccess),
            engagementError: take(engagementActions.engagementError),
        });

        if (engagementError) {
            console.log("error getting engagements");
            window.location.href = "/error";
        }
        yield call(setAccountConfig);
        yield call(getAccountData);
        yield call(setInvoiceConfig);
        yield call(setTransactionsConfig);
        yield call(getInvoiceData);
        // yield call(getTransactionsData)

        yield put(appActions.topupPageSuccess());
    } catch (e) {
        yield put(
            errorActions.errorTrigger({ message: "Topup page load failed" + e, url: "/error" })
        );
    }
}
