import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";
const logger = new ConsoleLogger({ level: LOG_LEVEL });
type T_SessionLogoutPayload = {
    url: string;
    token: string;
};
export async function sessionLogout(props: T_SessionLogoutPayload) {
    try {
        const result = await fetch(props.url, {
            method: "GET",
            headers: {
                Authorization: props.token,
            },
        });
        return result;
    } catch (error) {
        logger.log("Failed to logout", error);
        throw new Error("Logout failed");
    }
}
