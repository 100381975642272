import { createReducer } from "typesafe-actions";
import { produce } from "immer";
import { format, parseISO, getMonth, getYear } from "date-fns";
import { currency, CurrencyLocale, CurrencyFormat } from "@opr-finance/component-currency";
import { StatementsAction, statementsActions } from "../actions/statements";
import { StatementsReducerState, StatementsItemConf, T_VpStatementV2 } from "../types/statements";

export const initalState = {
    statements: [],
    formatted: [],
    config: {
        mockApiCalls: false,
        gwUrl: "",
        token: "",
        accountId: undefined,
    },
};

function filterPending(value: T_VpStatementV2) {
    return value.statementType !== "PENDING";
}

export const statementsReducer = createReducer<StatementsReducerState, StatementsAction>(
    initalState
)
    .handleAction(statementsActions.fetchStatementsInitializer, (state, action) => {
        return produce(state, (draftState) => {
            draftState.config.mockApiCalls = action.payload.mockApiCalls;
            draftState.config.gwUrl = action.payload.gwUrl;
            draftState.config.token = action.payload.token;
            draftState.config.accountId = action.payload.accountId;
        });
    })
    .handleAction(statementsActions.fetchStatementsSuccess, (state, action) => {
        return produce(state, (draftState) => {
            const statements = action.payload.filter(filterPending).map((item) => {
                const dueDate = item.dueDate
                    ? format(parseISO(item.dueDate), StatementsItemConf.DEFAULT_FORMAT_DATE)
                    : undefined;
                const amount = currency({
                    value: item.statementAmount,
                    locale: CurrencyLocale.Sweden,
                    currency: CurrencyFormat.Sweden,
                });
                const fee = currency({
                    value: item.statementFee,
                    locale: CurrencyLocale.Sweden,
                    currency: CurrencyFormat.Sweden,
                });

                let title = `Everyday+`;

                if (dueDate && typeof item.createdDate !== "undefined") {
                    const month = getMonth(parseISO(item.createdDate)) + 1;
                    const year = getYear(parseISO(item.createdDate)) % 2000;
                    title = `Everyday+ ${month}/${year}`;
                }

                return {
                    ...item,
                    dueDate,
                    amount,
                    fee,
                    title,
                };
            });
            draftState.statements = action.payload;
            draftState.formatted = statements;
        });
    });
