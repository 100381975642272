import * as VP from "@opr-finance/api-definitions";

export enum LoginActionConstants {
    LOGIN_INITIALIZER = "LOGIN/LOGIN_INITIALIZER",
    LOGIN_CHECK_STATUS = "LOGIN/CHECK_STATUS",
    LOGIN_CHECK_STATUS_SUCCESS = "LOGIN/CHECK_STATUS_SUCCESS",
    LOGIN_START = "LOGIN/START",
    LOGIN_START_SUCCESS = "LOGIN/START_SUCCESS",
    LOGIN_COMPLETE = "LOGIN/COMPLETE",
    LOGIN_COMPLETE_SUCCESS = "LOGIN/COMPLETE_SUCCESS",
    LOGOUT = "LOGIN/LOGOUT",
    VALIDATE_TOKEN = "LOGIN/VALIDATE_TOKEN",
    UPDATE_TOKEN = "LOGIN/UPDATE_TOKEN",
    UPDATE_IS_VALIDATING_TOKEN = "LOGIN/UPDATE_IS_VALIDATING_TOKEN",
    UPDATE_IS_VALID_TOKEN = "LOGIN/UPDATE_IS_VALID_TOKEN",
    UPDATE_IS_INITIALIZED = "LOGIN/UPDATE_IS_INITIALIZED",
}

export enum CustomerActionConstants {
    CUSTOMER_INITIALIZER = "CUSTOMER/CUSTOMER_INITIALIZER",
    FETCH_CUSTOMER = "CUSTOMER/FETCH_CUSTOMER",
    FETCH_CUSTOMER_TRIGGER = "CUSTOMER/FETCH_CUSTOMER_TRIGGER",
    FETCH_CUSTOMER_SUCCESS = "CUSTOMER/FETCH_CUSTOMER_SUCCESS",
    SELECT_ACCOUNT = "CUSTOMER/SELECT_ACCOUNT",
    SET_REVOLVING_ACCOUNT = "CUSTOMER/SET_REVOLVING_ACCOUNT",
}

export enum ActiveAccountConstants {
    ACTIVE_ACCOUNT_NUMBER_SELECT = "ACCOUNT/ACTIVE_ACCOUNT_NUMBER_SELECT",
    ACTIVE_ACCOUNT_NUMBER_SELECTED = "ACCOUNT/ACTIVE_ACCOUNT_NUMBER_SELECTED",
    ACTIVE_ACCOUNT_NUMBER_NOT_SELECTED = "ACCOUNT/ACTIVE_ACCOUNT_NUMBER_NOT_SELECTED",
}
export interface LoginReducerState {
    token: string | undefined;
    isInitialized: boolean;
    isValidating: boolean;
    isValidToken: boolean;
    loginUrl: string;
    successUrl: string;
    errorUrl: string;
    noLoanUrl: string;
    expiredTokenUrl: string;
    mockApiCalls: boolean;
    gwUrl: string;
}

export type T_VpUserInformation = VP.components["schemas"]["CustomerInformationV4"];
export type T_VpAccountInfo = VP.components["schemas"]["AccountInfo"];
export type T_VpRevolvingAccount = VP.components["schemas"]["RevolvingAccountV2"];
export type T_VpRegularLoanAccount = VP.components["schemas"]["RegularLoanAccount"];
export type T_VpAccount = T_VpRevolvingAccount | T_VpRegularLoanAccount;
export type T_VpAuthenticationAccessToken = VP.components["schemas"]["AuthenticationAccessToken"];

export interface CustomerReducerState {
    user: T_VpUserInformation | null;
    account: T_VpAccountInfo | null;
    revolvingAccount: T_VpRevolvingAccount | null;
    productType: "DEPOSIT" | "REGULAR_LOAN" | "CREDIT_LOAN" | null;
}
export type ActiveAccount = {
    accountId: string | undefined;
    accountNumber: string | undefined;
};
export interface ActiveAccountNumberState {
    accountInfo: ActiveAccount;
    account: T_VpAccount | null;
}

export type FeatureLoginState = {
    login: LoginReducerState;
    customer: CustomerReducerState;
    activeAccount: ActiveAccountNumberState;
};

export type ActiveAccountPayloadSuccess = {
    activeAccount: ActiveAccount;
    account: T_VpAccount;
};

export type ActiveAccountPayload = {
    accountList: T_VpAccountInfo[];
    productType: string;
};
