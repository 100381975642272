import { T_SessionInfoResult } from "../types";
import { T_GetSessionInfoPayload, T_SessionInfoAttrs } from "../types/types";

export const getSessionInfo = async (
    props: T_GetSessionInfoPayload
): Promise<T_SessionInfoAttrs> => {
    const { url, id } = props;
    const result: Promise<T_SessionInfoResult> = await fetch(url, {
        method: "GET",
        headers: {
            authorization: id,
        },
    }).then((response) => response.json());

    return (await result).attrs;
};
