import styled from "styled-components";
import { E_Fonts, E_Colors } from "@opr-finance/theme-flex-online";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #f6fafc;
    padding: 0;
    margin: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
`;

export const Title = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 17px 14px 11px 17px;
    width: 100%;
    border-top: 1px solid ${E_Colors.PRIMARY};
    border-bottom: 1px solid ${E_Colors.PRIMARY};
`;

export const Links = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const LinksTitle = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
`;

export const MorePageLink = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    padding: 0 0 0 37px;
    margin-bottom: 20px;
`;

export const MorePageIcon = styled.div`
    display: flex;
    flex-direction: row;
    width: 24px;
    height: 24px;
    padding: 0;
    margin: 0 16px 0 0;
`;

export const MorePageTitleText = styled.div`
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: flex-start;
`;

export const MorePageCloseText = styled.div`
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: flex-end;
    padding: 0 16px 0 0;
`;

export const MobileNavigationIconLink = styled.a`
    display: flex;
    flex-direction: column;
    padding: 8px;
    width: 100%;
    cursor: pointer;
`;

export const RestContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid #1b456d;
    padding: 0;
    margin: 0;
    padding: 8px 0 0 8px;
`;
