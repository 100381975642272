import { T_LoginSessionReducerState } from "..";

export async function verifyToken(data: T_LoginSessionReducerState) {
    const url = data.config.verifyUrl;
    const method = "GET";
    const token = data.token;
    const response = await fetch(url, {
        method,
        headers: { authorization: token, "content-type": "application/json" },
    });
    return response.status;
}
