import { defineMessages } from "react-intl";

export const messages = defineMessages({
    pageTitle: {
        id: "flex-online-fi-error-page-title",
    },
    pageInfo1: {
        id: "flex-online-fi-error-page-info-1",
    },
    pageInfo2: {
        id: "flex-online-fi-error-page-info-2",
    },
    LinkText1: {
        id: "flex-online-fi-error-page-link-1-text",
    },
    Link1: {
        id: "flex-online-fi-error-page-link-1-link",
    },
    LinkText2: {
        id: "flex-online-fi-error-page-link-2-text",
    },
    Link2: {
        id: "flex-online-fi-error-page-link-2-link",
    },
});
