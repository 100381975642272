import { SystemStyleObject } from "@styled-system/css";

import { E_Fonts } from "../general";

export function notFoundPageRootStyles(): SystemStyleObject {
    return {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    };
}
