import { ActionType, createAction } from "typesafe-actions";
import {
    DocumentActionConstants,
    DocumentProps,
    DocumentInitializerType,
    FetchDocumentParams,
    PromissoryNoteIdInitializer,
    PromissoryNoteIdInitialized,
} from "../types/document";

export const documentActions = {
    fetchPromissoryNoteIdInitializer: createAction(
        DocumentActionConstants.PROMISSORY_NOTE_ID_INITIALIZER
    )<PromissoryNoteIdInitializer>(),
    fetchPromissoryNoteIdInitialized: createAction(
        DocumentActionConstants.PROMISSORY_NOTE_ID_INITIALIZED
    )<PromissoryNoteIdInitialized>(),
    fetchDocumentInitializer: createAction(
        DocumentActionConstants.FETCH_DOCUMENT_INITIALIZER
    )<DocumentInitializerType>(),
    fetchDocumentTrigger: createAction(
        DocumentActionConstants.FETCH_DOCUMENT_TRIGGER
    )<FetchDocumentParams>(),
    fetchDocumentSuccess: createAction(
        DocumentActionConstants.FETCH_DOCUMENT_SUCCESS
    )<DocumentProps>(),
    fetchDocumentError: createAction(DocumentActionConstants.FETCH_DOCUMENT_ERROR)<DocumentProps>(),
};

export type DocumentAction = ActionType<typeof documentActions>;
