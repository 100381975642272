import { contentfulClient } from "./contentfulClient";
import {
    TranslationV2Type,
    ContentfulParams,
    TranslationContentfulConfig,
} from "../types/contentful";

export const getTranslation = async (
    data: ContentfulParams<TranslationContentfulConfig>
): Promise<TranslationV2Type[]> =>
    (await contentfulClient(data.clientParams)
        .getEntries(data.contentParams)
        .then((response) => response.items)) as TranslationV2Type[];
