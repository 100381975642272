import { ActionType, createAction } from "typesafe-actions";
import { LoginActionConstants } from "../types/general";

export type LoginStartPayload = {
    method: string;
};

export type LoginInitializerPayload = {
    mockApiCalls: boolean;
    loginUrl: string;
    successUrl: string;
    errorUrl: string;
    noLoanUrl: string;
    expiredTokenUrl: string;
    gwUrl: string;
};

export const loginActions = {
    loginInitializer: createAction(
        LoginActionConstants.LOGIN_INITIALIZER
    )<LoginInitializerPayload>(),
    loginStart: createAction(LoginActionConstants.LOGIN_START)<LoginStartPayload>(),
    loginSuccess: createAction(LoginActionConstants.LOGIN_START_SUCCESS)(),
    loginComplete: createAction(LoginActionConstants.LOGIN_COMPLETE)(),
    loginCompleteSuccess: createAction(LoginActionConstants.LOGIN_COMPLETE_SUCCESS)(),
    loginCheckStatus: createAction(LoginActionConstants.LOGIN_CHECK_STATUS)(),
    loginCheckStatusSuccess: createAction(LoginActionConstants.LOGIN_CHECK_STATUS_SUCCESS)(),
    logout: createAction(LoginActionConstants.LOGOUT)(),
    validateToken: createAction(LoginActionConstants.VALIDATE_TOKEN)(),
    updateToken: createAction(LoginActionConstants.UPDATE_TOKEN)<string>(),
    updateIsValidatingToken: createAction(
        LoginActionConstants.UPDATE_IS_VALIDATING_TOKEN
    )<boolean>(),
    updateIsValidToken: createAction(LoginActionConstants.UPDATE_IS_VALID_TOKEN)<boolean>(),
    updateIsInitialized: createAction(LoginActionConstants.UPDATE_IS_INITIALIZED)<boolean>(),
};

export type LoginAction = ActionType<typeof loginActions>;
