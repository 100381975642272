import { put, takeLatest } from "redux-saga/effects";
import { AppActionConstants, appActions } from "../actions/actions";
import { errorActions } from "@opr-finance/feature-error";
import { engagementActions } from "@opr-finance/feature-sme-customer";

export function* watchContactPageTrigger() {
    yield takeLatest(AppActionConstants.CONTACT_PAGE_TRIGGER, handleContactPageTrigger);
}

export function* handleContactPageTrigger() {
    try {
        yield put(engagementActions.engagementTrigger());
        yield put(appActions.contactPageSuccess());
    } catch (e) {
        yield put(
            errorActions.errorTrigger({ message: "contact page load failed" + e, url: "/error" })
        );
    }
}
