export { theme } from "./_old/theme";
export {
    bodyTitle,
    box,
    contentBox,
    headerContainer,
    headerContent,
    headerTitle,
    largeButton,
    loginButtonTextStyle,
    pageTitle,
    body,
    bodyLabel,
    bodyWarning,
    button,
    checkbox,
    checkboxDisabled,
    checkboxText,
    select,
    textField,
    buttonText,
    formError,
    link,
    headerLink,
    inlineBox,
    sideTitle,
    sideLink,
    sideBox,
    breadcrumbTitle,
    breadcrumbBox,
    buttonCancel,
    buttonInfo,
    buttonSubmit,
    buttonSubmitDisabled,
    buttonLogout,
    buttonLoginAgain,
    mobilePageTitle,
    userInfoContent,
    userInfoTitle,
    textItem,
    secondaryButton,
    secondaryButtonText,
} from "./_old/styles";

//! NOTE: MEW VERSION

export * as FontsStyles from "./styles/fonts.styles";
export * as ContactPageStyles from "./styles/contactPage.styles";
export * as LayoutStyles from "./styles/layout.styles";
export * as HeaderStyles from "./styles/header.styles";
export * as ButtonStyles from "./styles/button.styles";
export * as PageTitleStyles from "./styles/pageTitle.styles";
export * as StartPageStyles from "./styles/startPage.styles";
export * as FooterStyles from "./styles/footer.styles";
export * as NewsStyles from "./styles/news.styles";

export * as ErrorPageStyles from "./styles/errorPage.styles";
export * as ExpiredPageStyles from "./styles/expiredPage.styles";
export * as FrontPageStyles from "./styles/frontPage.styles";
export * as LoanPageStyles from "./styles/loanPage.styles";
export * as LogoutPageStyles from "./styles/logoutPage.styles";
export * as NoLoanPageStyles from "./styles/noLoanPage.styles";
export * as NotFoundPageStyles from "./styles/notFoundPage.styles";
export * as TopupPageStyles from "./styles/topupPage.styles";
export * as UserPageStyles from "./styles/userPage.styles";
export * as ModalStyles from "./styles/modal.styles";
export * as AccountListPageStyles from "./styles/accountListPage.styles";
export * as MobileNavStyles from "./styles/mobileNav.styles";
export * as NoTopUpPageStyles from "./styles/noTopUpPage.styles";
export * as LoginPageStyles from "./styles/loginPage.styles";
export * as TableStyles from "./styles/tableStyles.styles";
export * as ApplicationPageStyles from "./styles/applicationPage.styles";
export * as NlStartPageStyles from "./styles/nlStartPage";
export { E_Fonts, E_Colors } from "./general";
export type { T_StartPageBankProperties } from "./general";
