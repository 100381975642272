import { takeEvery, put, call, select } from "redux-saga/effects";

import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";

import { loginVerifyToken } from "../api/login";
import { getToken } from "../api/login";
import { ActionType } from "typesafe-actions";
import { loginActions } from "../actions/login";
import { customerActions } from "../actions/customer";
import {
    FeatureLoginState,
    LoginReducerState,
    LoginActionConstants,
    T_VpAuthenticationAccessToken,
} from "../types/general";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export function* watchStartLogin(): Generator {
    yield takeEvery(LoginActionConstants.LOGIN_START, handleStartLogin);
}

export function* handleStartLogin(action: ActionType<typeof loginActions.loginStart>): Generator {
    const config = (yield select((state: FeatureLoginState) => {
        return state.login;
    })) as LoginReducerState;

    logger.log("start login!", action);
    let rest = "";
    if (action.payload.method) {
        rest = `&acrValues=urn:signicat:oidc:method:${action.payload.method}`;
    }
    const url = config.loginUrl + rest;
    logger.log("got redirect url", url);
    window.location.href = url;
}

export function* watchCompleteLogin(): Generator {
    logger.log("complete login watcher?");
    yield takeEvery(LoginActionConstants.LOGIN_COMPLETE, handleCompleteLogin);
}

export function* handleCompleteLogin(): Generator {
    try {
        logger.log("complete login handler");

        const config = (yield select((state: FeatureLoginState) => {
            return state.login;
        })) as LoginReducerState;

        const query = new URLSearchParams(window.location.search);

        if (query.has("accessToken")) {
            const accessToken = decodeURIComponent(query.get("accessToken") as string);

            logger.log("access token", accessToken);

            localStorage.setItem("token", accessToken);

            logger.log("redirect to success url", config.successUrl);

            window.location.href = config.successUrl;
        } else {
            const authorizationCode = query.get("authorizationCode") as string;

            logger.log("got code", authorizationCode);

            const response = (yield call(getToken, {
                authorizationCode,
                mockApiCalls: config.mockApiCalls,
                gwUrl: config.gwUrl,
            })) as T_VpAuthenticationAccessToken;

            logger.log("got response from complete login", response);

            if (!response) {
                return (window.location.href = config.errorUrl);
            }

            const { accessToken } = response;

            logger.log("access token", accessToken);

            localStorage.setItem("token", accessToken);

            logger.log("redirect to success url", config.successUrl);

            window.location.href = config.successUrl;
        }
    } catch (e) {
        logger.log("login failed!", e);
    }
}

export function* watchCheckLoginStatus(): Generator {
    yield takeEvery(LoginActionConstants.LOGIN_CHECK_STATUS, handleCheckLoginStatus);
}

export function* handleCheckLoginStatus(): Generator {
    try {
        const config = (yield select((state: FeatureLoginState) => {
            return state.login;
        })) as LoginReducerState;

        yield put(loginActions.updateIsValidatingToken(true));

        const token: string | null = localStorage.getItem("token");

        logger.log("got token", token);

        if (!token) {
            logger.log("no token found?", token);
            window.location.href = config.errorUrl;
            return;
        }

        const isValidTokenResult = yield call(loginVerifyToken, {
            mockApiCalls: config.mockApiCalls,
            token,
            gwUrl: config.gwUrl,
        });

        logger.log("validation result", isValidTokenResult);

        const isValidToken = isValidTokenResult as boolean;

        yield put(loginActions.updateIsValidToken(isValidToken));
        yield put(loginActions.updateToken(token));

        if (isValidToken) {
            yield put(loginActions.updateIsValidatingToken(false));
            yield put(customerActions.fetchCustomerTrigger());
        } else {
            yield put(loginActions.updateToken(""));
            window.location.href = config.expiredTokenUrl;
        }
    } catch (e) {
        logger.log("validation failed", e);

        yield put(loginActions.updateToken(""));
        yield put(loginActions.updateIsValidToken(false));
    }
}

export function* watchLogout(): Generator {
    yield takeEvery(LoginActionConstants.LOGOUT, handleLogout);
}

export function* handleLogout(): Generator {
    try {
        logger.log("HANDLE LOGOUT!");

        yield put(loginActions.updateIsValidatingToken(true));

        const token: string | null = localStorage.getItem("token");

        logger.log("got token for logout", token);

        if (!token) {
            window.location.href = "/";
            return;
        }

        localStorage.removeItem("token");
    } catch (e) {
        logger.log("validation failed", e);

        yield put(loginActions.updateToken(""));
        yield put(loginActions.updateIsValidToken(false));
    }
}
